import {yupResolver} from '@hookform/resolvers/yup'
import {Button} from '@progress/kendo-react-buttons'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {HookFormInput} from '../../../components/ReactHookForm/HookFormInput'
import {FileModel} from '../../../models/SopModel'
import {IdTextTuple} from '../../../models/IdTextTuple'
import {HookFormDatePicker} from '../../../components/ReactHookForm/HookFormDatePicker'
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload'
import {useIntl} from 'react-intl'

interface FileProps {
  mode: string
  title: string
  documentFolderId: string
  dataId: string
  data?: FileModel
  onClose: () => void
  onSuccess: (data: any, responseData: any) => void
}

const fileStatuses = [
  'UploadFailed',
  'Initial',
  'Selected',
  'Uploading',
  'Uploaded',
  'RemoveFailed',
  'Removing',
]

export default function FileForm(props: FileProps) {
  const intl = useIntl()
  const {mode, title, documentFolderId, dataId, onClose, onSuccess, ...others} = props
  const [formState, setFormState] = useState('init')
  const [errorMessage, setErrorMessage] = useState('')

  const [files, setFiles] = useState<Array<UploadFileInfo>>([])
  const [events, setEvents] = useState<Array<any>>([])
  const [filePreviews, setFilePreviews] = useState<{[key: string]: string}>({})
  const [affectedFiles, setAffectedFiles] = useState<Array<UploadFileInfo>>([])

  const validationSchema = Yup.object().shape({
    documentName: Yup.string().required('Name is required'),
  })

  const today = new Date()

  // Tambahkan 1 tahun ke tanggal saat ini
  const oneYearFromNow = new Date(today)
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

  console.log(oneYearFromNow)

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<FileModel>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (mode === 'edit') {
      loadData()
    } else {
      reset({
        documentId: '',
        documentFolderId: '',
        subscriptionId: '',
        documentName: '',
        documentNumber: '',
        revision: 0,
        documentTypeID: '',
        validFrom: new Date(),
        validUntil: oneYearFromNow,
        status: '',
        createdBy: '',
        lastModifiedBy: '',
        fileUrl: '',
      })
      setFormState('')
    }
  }, [mode, setValue])

  function loadData() {
    console.log('loadData().. dataId = ' + dataId)
    setFormState('loading')
    let url = `api/Document/${dataId}?id=${dataId}`

    axios
      .get(url)
      .then((response) => {
        //const data = response.data
        console.log('Response Data:', response.data)
        reset({
          documentId: response.data.documentId,
          subscriptionId: response.data.subscriptionId,
          documentFolderId: response.data.documentFolderId,
          documentName: response.data.documentName,
          documentNumber: response.data.documentNumber,
          revision: response.data.revision,
          documentTypeID: response.data.documentTypeID,
          validFrom: response.data.validFrom,
          validUntil: response.data.validUntil,
          status: response.data.status,
          createdBy: response.data.createdBy,
          createdDate: response.data.createdDate,
          lastModifiedBy: response.data.lastModifiedBy,
          lastModifiedDate: response.data.lastModifiedDate,
          filePath: response.data.filePath,
          fileUrl: response.data.fileUrl,
        })
        setFormState('')
      })
      .catch((error) => {
        console.log(error)
        setErrorMessage('Failed to load data')
        setFormState('error')
      })
  }

  const onSubmit = handleSubmit((data) => {
    // if (files.length === 0) {
    //   setErrorMessage('File belum diunggah!')
    //   return
    // }

    console.log('onSubmit() data = ', data)
    let url = 'api/Document/'
    const params = new URLSearchParams(window.location.search)
    const folderId = params.get('folderId')
    console.log(folderId)

    if (mode === 'add') {
      url = url + 'Add'
      data.documentFolderId = folderId ? folderId : null
      data.documentId = ''
    } else {
      url = url + 'Update'
      data.documentFolderId = documentFolderId
      data.documentId = dataId
    }

    axios
      .post(url, data)
      .then(async (response) => {
        console.log('response >>', response)
        console.log('response.data >>', response.data) // Tambahkan baris ini
        if (response.data && response.data.success) {
          setFormState('submitted')

          if (mode === 'add') {
            const documentId = response.data.data?.documentId
            if (documentId) {
              // Upload image after item creation
              await uploadDoc(documentId, files[0])
              console.log('File uploaded:', files[0].name)
            } else {
              console.error(' ID is undefined')
              // Handle the case where itemId is undefined
            }
          }
          if (onSuccess) onSuccess(data, response.data.data)
          onClose()
        } else {
          setFormState('')
          setErrorMessage(response.data.errorMessage)
          console.log(response.data.errorMessage)
        }
      })
      .catch((error) => {
        console.log(error)
        setFormState('')
        setErrorMessage(error.response.data)
      })
  })

  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState)
    setEvents([...events, `File selected: ${event.affectedFiles[0].name}`])
    setAffectedFiles(event.affectedFiles)

    // Ambil nama file yang ditambahkan
    const fileName = event.affectedFiles[0].name

    // Set nilai field documentName dengan nama file yang ditambahkan
    setValue('documentName', fileName)
  }

  const onRemove = (event: UploadOnRemoveEvent) => {
    let newFilePreviews = {...filePreviews}
    event.affectedFiles.forEach((file) => {
      delete newFilePreviews[file.uid]
    })

    setFiles(event.newState)
    setEvents([...events, `File removed: ${event.affectedFiles[0].name}`])
    setFilePreviews(newFilePreviews)
  }

  const onProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState)
    setEvents([...events, `On Progress: ${event.affectedFiles[0].progress} %`])
  }

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    const file = event.affectedFiles[0]
    setFiles(event.newState)
    setEvents([...events, `File '${file.name}' status changed to: ${fileStatuses[file.status]}`])
  }

  const onSaveRequest = (files: UploadFileInfo[]): Promise<{uid: string}> => {
    const currentFile = files[0] as UploadFileInfo
    const uid = currentFile.uid

    const saveRequestPromise = new Promise<{uid: string}>(async (resolve, reject) => {
      if (currentFile.validationErrors && currentFile.validationErrors.length > 0) {
        reject({uid: uid})
      } else {
        const formData = new FormData()
        formData.append('file', currentFile.getRawFile!())
        const docId = getValues('documentId')

        try {
          const response = await axios.post('/api/Document/Upload?documentId=' + docId, formData)

          resolve({uid: uid})
          // onSuccess(currentFile.name, response.data)
        } catch (err) {
          reject({uid: uid})
        }
      }
    })

    return saveRequestPromise
  }

  const uploadDoc = async (documentId: string, file: UploadFileInfo) => {
    const formData = new FormData()
    formData.append('file', file.getRawFile!())

    try {
      const response = await axios.post(`/api/Document/Upload?documentId=${documentId}`, formData)

      setValue('fileUrl', response.data.fileUrl)
      // Handle the response as needed
      // onSuccess(file.name, response.data)
    } catch (err) {
      // Handle the error as needed
      console.error('Image upload error:', err)
    }
  }

  return (
    <Dialog title={title} onClose={onClose} width={500}>
      <form id='myForm' onSubmit={onSubmit}>
        <div className='d-flex flex-row gap-8 pb-3'>
          <div className='col-12'>
            <div className='k-form k-form-horizontal'>
              {errorMessage && (
                <div className={'k-messagebox k-messagebox-error'}>{errorMessage}</div>
              )}
              {mode !== 'edit' && (
                <Upload
                  className='mb-2'
                  autoUpload={false}
                  restrictions={{
                    allowedExtensions: ['.pdf', '.xlsx', '.doc', '.docx', '.ppt', '.pptx'],
                    maxFileSize: 10000000,
                  }}
                  batch={false}
                  multiple={false}
                  files={files}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  withCredentials={false}
                  saveUrl={onSaveRequest}
                  onStatusChange={onStatusChange}
                  onProgress={onProgress}
                />
              )}
              <div className='row'>
                <div className='col-12'>
                  <HookFormInput
                    name='documentNumber'
                    control={control}
                    label={intl.formatMessage({id: 'TEXT.DOCNUMBER'})}
                    hideAsField={mode === 'edit'}
                  />
                </div>
                <div className='col-12'>
                  <HookFormInput
                    name='documentName'
                    control={control}
                    label={intl.formatMessage({id: 'TEXT.DOCNAME'})}
                  />
                </div>
                <div className='col-12'>
                  <HookFormInput
                    name='status'
                    control={control}
                    label={'Status'}
                    hideAsField={mode === 'edit'}
                  />
                </div>
                {mode !== 'edit' && (
                  <>
                    <div className='col-12'>
                      <HookFormDatePicker
                        name='validFrom'
                        control={control}
                        label={intl.formatMessage({id: 'TEXT.VALIDFROM'})}
                        format={'dd/MM/yyyy'}
                      />
                    </div>
                    <div className='col-12'>
                      <HookFormDatePicker
                        name='validUntil'
                        control={control}
                        label={intl.formatMessage({id: 'TEXT.VALIDTO'})}
                        format={'dd/MM/yyyy'}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <DialogActionsBar layout={'end'}>
        {formState !== 'error' && (
          <Button
            themeColor={'primary'}
            type={'submit'}
            form={'myForm'}
            style={{minWidth: 100}}
            onSubmit={onSubmit}
          >
            OK
          </Button>
        )}
        <Button onClick={onClose} style={{minWidth: 80}}>
          {intl.formatMessage({id: 'BTN.CANCEL'})}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
