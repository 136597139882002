import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";

import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider } from './app/modules/auth'

import { msalConfig } from "./authConfig";
import { MsalProvider } from '@azure/msal-react'
import setupAxios from './app/setup/axios/SetupAxios'


// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'

import './AppStyles.scss'

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'

import './app.css'

/** MSAL for sign on with AAD */
export const msalInstance = new PublicClientApplication(msalConfig);
// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
   msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
   }
});

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
   createRoot(container).render(
      <MsalProvider instance={msalInstance}>
         <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
               <AuthProvider>
                  <AppRoutes />
               </AuthProvider>
            </MetronicI18nProvider>
            <ReactQueryDevtools initialIsOpen={false} />
         </QueryClientProvider>
      </MsalProvider>
   )
}
