import {Route, Routes} from 'react-router-dom'
import ObservationInspectionMain from './ObservationInspectionMain'
import ObservationListPage from './ObservationListPage'
import FormDataPage from './FormDataPage'

export default function ObservationNewPage() {
  return (
    <Routes>
      <Route index element={<ObservationListPage />} />
      <Route path=':hseFormId/*' element={<FormDataPage />} />
    </Routes>
  )
}
