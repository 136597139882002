import {DataResult, State, toDataSourceRequestString} from '@progress/kendo-data-query'
import {formatDate} from '@telerik/kendo-intl'
import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Item1} from '../../../../_metronic/partials/content/activity/Item1'
import {ServiceResult} from '../../../classes/ServiceResult'

interface GetObservationGridDataParam {
  dataState: State
  searchText: string | null
}

// Get Grid Data..
const getObservationGridData = async (params: GetObservationGridDataParam) => {
  //console.log(`Call getVoucherGridData().. skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `api/HseForm/GridData?${queryString}`
  const data = {
    searchText: params.searchText,
  }
  console.log(`getIncidentGridData() queryString=${queryString}, searchText=${data.searchText}`)
  const response = await axios.get<DataResult>(url, {params: data})
  console.log('response received')
  return response.data
}

function useGetObservationGridData(params: GetObservationGridDataParam) {
  console.log(`useGetIncidentGridData() skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(['privilages', params], () => getObservationGridData(params), {
    // enabled: false
  })
}

export {useGetObservationGridData}
