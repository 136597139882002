import {Button} from '@progress/kendo-react-buttons'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import React, {useEffect, useState} from 'react'
import {HookFormNumericTextBox} from '../../../../../components/ReactHookForm/HookFormNumericTextBox'
import {useTranslation} from 'react-i18next'
import {IncidentModel, InvestigationModel, PersonalModel} from '../../../../../models/IncidentModel'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'
import axios from 'axios'
import * as Yup from 'yup'
import {HookFormInput} from '../../../../../components/ReactHookForm/HookFormInput'
import {useParams} from 'react-router-dom'

interface PopupImpactedPersonProps {
  mode: string
  title: string
  dataId: string
  incidentId: string
  onClose: () => void
  onSuccess: (data: any, responseData: any) => void
}

export default function PopupIncidentLossesNew(props: PopupImpactedPersonProps) {
  const {mode, title, dataId, onClose, onSuccess, ...others} = props
  const {t} = useTranslation('translation')
  const [errorMessage, setErrorMessage] = useState('')
  const {incidentId} = useParams()
  const [Ivestigation, setIvestigation] = useState<any>()
  const updateValidationSchema = Yup.object().shape({})

  const insertValidationSchema = Yup.object().shape({})

  const [incidentLossCount, setIncidentLossCount] = useState<number>(0)

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<InvestigationModel>({
    // resolver: yupResolver(
    //   mode === "add" ? insertValidationSchema : updateValidationSchema
    // ),
  })

  // function loadData(data: any) {
  //   console.log('data loadData= ', data)
  //   let url = 'api/Incident/' + incidentId
  //   axios
  //     .get(url)
  //     .then((response) => {
  //       console.log('TESTTT', response.data.investigation)
  //       setIvestigation(response.data.investigation)
  //       const investigationData = response.data.investigation

  //       setIncidentLossCount(response.data.investigation.incidentLosess.length)
  //       reset({
  //         incidentId: response.data.investigation.incidentId,
  //         priority: response.data.investigation.priority,
  //         causeOfIncident: response.data.investigation.causeOfIncident,
  //         status: response.data.investigation.status,
  //         createdBy: response.data.investigation.createdBy,
  //         createdDate: response.data.investigation.createdDate,
  //         lastModifiedBy: response.data.investigation.lastModifiedBy,
  //         lastModifiedDate: response.data.investigation.lastModifiedDate,
  //         approvedBy: response.data.investigation.approvedBy,
  //         approvedDate: response.data.investigation.approvedDate,
  //         rejectReason: response.data.investigation.rejectReason,
  //         incidentLosess: investigationData.incidentLosess.map((loss: any) => ({
  //           id: loss.id,
  //           incidentId: loss.incidentId,
  //           typeOfLoss: loss.typeOfLoss,
  //           description: loss.description,
  //           costOfLoss: loss.costOfLoss,
  //         })),

  //         actionPlans: response.data.investigation.actionPlans.map((plan: any) => ({
  //           id: plan.id,
  //           incidentId: plan.incidentId,
  //           description: plan.description,
  //           isCompleted: plan.isCompleted,
  //           notes: plan.notes,
  //         })),
  //       })
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setErrorMessage('Failed to load data')
  //     })
  // }

  console.log('LOSSES', Ivestigation)

  const onSubmit = handleSubmit((data: any) => {
    console.log('onSubmit> ', data)
    console.log('data AddImpactedPerson > props ', props)

    data.incidentId = incidentId
    let url = '/api/Incident/Investigation/Loss/Add'

    axios
      .post(url, data)
      .then((response) => {
        console.log('response.data= ', response.data)

        if (response.data.success) {
          if (onSuccess) {
            onSuccess(data, response.data.result)
            onClose()
            // let message = t("Data Has Been Created");
            // toast.success(message);
          }
        } else {
          setErrorMessage(response.data.errorMessage)
        }
      })
      .catch((error) => {
        console.log(error)

        setErrorMessage(t('FailedToSubmitToServer'))
      })
  })

  // useEffect(() => {
  //   console.log('propsPerson = ', props)

  //   // if (props.mode === 'edit') {
  //   loadData(props.dataId)
  //   // } else {
  //   // setFormState('')
  //   // }
  // }, [])

  return (
    <Dialog title={title} onClose={onClose} width='500px' height='auto'>
      <div className=''>
        <form id='formIncidentLosses' onSubmit={onSubmit}>
          <div className='k-form k-form-horizontal'>
            {errorMessage && (
              <div className={'k-messagebox k-messagebox-error'}>{errorMessage}</div>
            )}

            {/* <HookFormInput
              name={`incidentLosess[0].incidentId`}
              control={control}
              label={'typeOfLoss'}
              initialValue={incidentId}
            /> */}
            <HookFormInput name={`typeOfLoss`} control={control} label={'Type of Losses'} />
            <HookFormInput name={`description`} control={control} label={'Description'} />
            <HookFormNumericTextBox
              name={`costOfLoss`}
              control={control}
              label={'Cost of Losses'}
            />
          </div>
        </form>
      </div>
      <DialogActionsBar layout={'end'}>
        <Button
          className='btn-primary'
          type={'submit'}
          form={'formIncidentLosses'}
          style={{minWidth: 100}}
        >
          OK
        </Button>
        {/* )} */}
        <Button className='btn-primary' onClick={onClose} style={{minWidth: 80}}>
          {t('Cancel')}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
