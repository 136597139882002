import {DataResult, State, toDataSourceRequestString} from '@progress/kendo-data-query'
import axios from 'axios'
import {useQuery} from 'react-query'

interface GetHseFormGridDataParam {
  dataState: State
  searchText: string | null
}

const getHseFormGridData = async (params: GetHseFormGridDataParam) => {
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `/api/HseForm/GridData?${queryString}`
  const data = {
    searchText: params.searchText,
  }
  const response = await axios.get<DataResult>(url, {params: data})
  return response.data
}

function useGetHseFormGridData(params: GetHseFormGridDataParam) {
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(['roles', params], () => getHseFormGridData(params), {
    // enabled: false
  })
}

export {useGetHseFormGridData}
