import { Route, Routes } from "react-router-dom";
import { useParams } from "react-router-dom";
import RoleListPage from "./RoleListPage";
import { useGetRoleData } from "./RoleApi";
import RoleCreatePage from "./RoleCreatePage";
import RoleDetailPage from "./RoleDetailPage";


export default function RolesPage() {

   return (
      <Routes>
      <Route path=':roleId/*' element={<RoleDetailPage  />} />
      <Route path='new' element={<RoleCreatePage />} />
      <Route index element={<RoleListPage />} />
      </Routes>
   )
}