import {Button} from '@progress/kendo-react-buttons'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {PageActionSection, PageTitle} from '../../../../../_metronic/layout/core'
import {useGetIncidentData} from '../IncidentApi'
import {loadMessages, LocalizationProvider} from '@progress/kendo-react-intl'
import ProgressForm from './ProgressForm'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

export default function InvestigationDetailPage() {
  const intl = useIntl()
  const {t} = useTranslation('translation')
  let {incidentId} = useParams()
  const navigate = useNavigate()

  const {data} = useGetIncidentData(incidentId ?? '')

  function onBackClicked() {
    navigate('/incident-report', {replace: true})
  }

  return (
    <>
      <PageTitle>{`Progress`}</PageTitle>
      <PageActionSection>
        <div className='d-flex'>
          <Button className='btn-primary-jotun-yellow' size='large' onClick={onBackClicked}>
            {intl.formatMessage({id: 'BTN.BACK'})}
          </Button>
        </div>
      </PageActionSection>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body pt-9 pb-0 d-flex flex-column'>
          <ProgressForm mode='view' data={data} dataId={undefined} />
        </div>
      </div>
    </>
  )
}
