import {yupResolver} from '@hookform/resolvers/yup'
import {Button} from '@progress/kendo-react-buttons'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {HookFormInput} from '../../../components/ReactHookForm/HookFormInput'
import {FolderModel} from '../../../models/SopModel'
import {IdTextTuple} from '../../../models/IdTextTuple'
import {useIntl} from 'react-intl'

interface FolderProps {
  mode: string
  title: string

  parentFolderId: string
  dataId: string

  onClose: () => void
  onSuccess: (data: any, responseData: any) => void
}

export default function FolderForm(props: FolderProps) {
  const intl = useIntl()
  const {mode, title, parentFolderId, dataId, onClose, onSuccess, ...others} = props
  const [formState, setFormState] = useState('init')
  const [errorMessage, setErrorMessage] = useState('')

  const validationSchema = Yup.object().shape({
    folderName: Yup.string().required('Label is required'),
  })

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<FolderModel>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (mode === 'edit') {
      loadData()
    } else {
      reset({
        id: '',
        subscriptionId: '',
        folderName: '',
        parentFolderId: '',
        isDeleted: false,
        createdBy: '',
        lastModifiedBy: '',
      })
      setFormState('')
    }
  }, [mode])

  function loadData() {
    console.log('loadData().. dataId = ' + dataId)
    setFormState('loading')
    let url = `api/Document/Folder/${dataId}?id=${dataId}`
    axios
      .get(url)
      .then((response) => {
        //const data = response.data
        console.log('Response Data:', response.data)
        reset({
          id: response.data.id,
          subscriptionId: response.data.subscriptionId,
          folderName: response.data.folderName,
          createdBy: response.data.createdBy,
          createdDate: response.data.createdDate,
          isDeleted: response.data.isDeleted,
          lastModifiedBy: response.data.lastModifiedBy,
          lastModifiedDate: response.data.lastModifiedDate,
          parentFolderId: response.data.parentFolderId,
        })
        setFormState('')
      })
      .catch((error) => {
        console.log(error)
        setErrorMessage('Failed to load data')
        setFormState('error')
      })
  }

  const onSubmit = handleSubmit((data) => {
    console.log('saveeeeeee')

    console.log('onSubmit() data = ', data)
    let url = '/api/Document/Folder/'
    const params = new URLSearchParams(window.location.search)
    const folderId = params.get('folderId')
    console.log(folderId)

    if (mode === 'add') {
      url = url + 'Add'
      data.parentFolderId = folderId ? folderId : null
      data.id = ''
    } else {
      url = url + 'Update'

      data.parentFolderId = parentFolderId
      data.id = dataId
    }

    axios
      .post(url, data)
      .then((response) => {
        console.log('response >>', response)
        if (response.data.success) {
          setFormState('submitted')
          if (onSuccess) onSuccess(data, response.data.data)
          onClose()
        } else {
          setFormState('')
          setErrorMessage(response.data.errorMessage)
          console.log(response.data.errorMessage)
        }
      })
      .catch((error) => {
        console.log(error)
        setFormState('')
        setErrorMessage(error.response.data)
      })
  })

  return (
    <Dialog title={title} onClose={onClose}>
      <form id='myForm' onSubmit={onSubmit}>
        <div className='w-100 h-100 d-flex flex-row gap-8 pb-3'>
          <div className={'w-400px h-100'}>
            <div className='w-100 k-form k-form-horizontal'>
              {errorMessage && (
                <div className={'k-messagebox k-messagebox-error'}>{errorMessage}</div>
              )}
              <div className='row'>
                <HookFormInput
                  name='folderName'
                  control={control}
                  label={intl.formatMessage({id: 'TEXT.FOLDERNAME'})}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <DialogActionsBar layout={'end'}>
        {formState !== 'error' && (
          <Button
            themeColor={'primary'}
            type={'submit'}
            form={'myForm'}
            style={{minWidth: 100}}
            onSubmit={onSubmit}
          >
            OK
            {/*{formState === 'submitting' ? `${t('Saving')}...` : 'OK'}*/}
          </Button>
        )}
        <Button onClick={onClose} style={{minWidth: 80}}>
          Cancel
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
