import {yupResolver} from '@hookform/resolvers/yup'
import {Button} from '@progress/kendo-react-buttons'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {HookFormInput} from '../../../components/ReactHookForm/HookFormInput'
import {HseFormModel} from '../../../models/HSEFormModel'
import {HookFormTextArea} from '../../../components/ReactHookForm/HookFormTextArea'

interface HseFormProps {
  mode: string
  title: string
  dataId: string
  onClose: () => void
  onSuccess: (data: any, responseData: any) => void
}

export default function HseForm(props: HseFormProps) {
  const {mode, title, dataId, onClose, onSuccess, ...others} = props
  const [formState, setFormState] = useState('init')
  const [errorMessage, setErrorMessage] = useState('')
  const initialFormData: any = {
    hseFormId: '',
    hseFormName: '',
    hseFormCode: '',
    description: '',
    sections: [],
    items: [],
  }

  const validationSchema = Yup.object().shape({
    hseFormCode: Yup.string().required('HSE Code is required'),
    hseFormName: Yup.string().required('HSE Name is required'),
  })

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<HseFormModel>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (mode === 'edit') {
      loadData()
    } else {
      reset({
        hseFormId: '',
        hseFormName: '',
        hseFormCode: '',
      })
      setFormState('')
    }
  }, [mode])

  function loadData() {
    console.log('loadData().. dataId = ' + dataId)
    setFormState('loading')
    let url = `api/HSEForm/${dataId}`
    axios
      .get(url)
      .then((response) => {
        //const data = response.data
        console.log('Response Data:', response.data)
        reset({
          hseFormId: response.data.hseFormId,
          hseFormCode: response.data.hseFormCode,
          hseFormName: response.data.hseFormName,
          description: response.data.description,
        })
        setFormState('')
      })
      .catch((error) => {
        console.log(error)
        setErrorMessage('Failed to load data')
        setFormState('error')
      })
  }

  const onSubmit = handleSubmit((data) => {
    console.log('onSubmit() data = ', data)
    let url = 'api/HSEForm/'
    if (mode === 'add') {
      url = url + 'add'
      data.hseFormId = ''
    } else {
      url = url + 'update'
      data.hseFormId = dataId
    }
    axios
      .post(url, data)
      .then((response) => {
        console.log('response >>', response)
        if (response.data.success) {
          setFormState('submitted')
          if (onSuccess) onSuccess(data, response.data.data)
        } else {
          setFormState('')
          setErrorMessage(response.data.errorMessage)
          console.log(response.data.errorMessage)
        }
      })
      .catch((error) => {
        console.log(error)
        setFormState('')
        setErrorMessage(error.response.data)
      })
  })

  const labelWidth = '120px'

  return (
    <Dialog title={title} onClose={onClose}>
      <div className='w-100 h-100 d-flex flex-row gap-8 pb-3'>
        <div className='w-400px h-100'>
          <form id='myForm' onSubmit={onSubmit}>
            <div className='w-100 k-form k-form-horizontal'>
              {errorMessage && (
                <div className={'k-messagebox k-messagebox-error'}>{errorMessage}</div>
              )}
              <div className='row'>
                <div className='col-12 w-100'>
                  <HookFormInput name='hseFormCode' control={control} label={'Form Code'} />
                  <HookFormInput name='hseFormName' control={control} label={'Form Name'} />
                  <HookFormTextArea
                    name='description'
                    control={control}
                    label={'Description'}
                    rows={3}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <DialogActionsBar layout={'end'}>
        {formState !== 'error' && (
          <Button
            themeColor={'primary'}
            className='btn-primary '
            type={'submit'}
            form={'myForm'}
            style={{minWidth: 100}}
            //disabled={formState !== ''}
            onSubmit={onSubmit}
          >
            Save
            {/*{formState === 'submitting' ? `${t('Saving')}...` : 'OK'}*/}
          </Button>
        )}
        <Button onClick={onClose} style={{minWidth: 80}}>
          Cancel
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
