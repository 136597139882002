import {Link, Outlet, useNavigate} from 'react-router-dom'
import {Content} from '../../../../../_metronic/layout/components/Content'
import {Toolbar} from '../../../../../_metronic/layout/components/toolbar/Toolbar'
import clsx from 'clsx'
import {PageActionSection, PageTitle, useLayout} from '../../../../../_metronic/layout/core'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridHeaderCellProps,
} from '@progress/kendo-react-grid'
import GridLoadingPanel from '../../../../components/Grid/GridLoadingPanel'
import {useQueryClient} from 'react-query'
import {useGetRoleGridData} from './RoleApi'
import {useState} from 'react'
import {State} from '@progress/kendo-data-query'
import {Button} from '@progress/kendo-react-buttons'
import {SearchTextBox} from '../../../../components/Filters/SearchTextBox'
import {useTranslation} from 'react-i18next'
import {ClickableGridCell} from '../../../../components/Grid/ClickableGridCell'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faFilePen, faPencil, faTrash} from '@fortawesome/pro-light-svg-icons'
import {CenteredColumnHeader} from '../../../../components/Grid/CenteredColumnHeader'
import DeleteDataDialog from '../../../../components/Dialog/DeleteDataDialog'
import RoleForm from './RoleForm'
import toast from 'react-hot-toast'

export interface RoleListProps {
  roleId: string | undefined
  roleName: string | undefined
}

export default function RoleList() {
  const {classes} = useLayout()
  const queryClient = useQueryClient()
  const {t} = useTranslation('translation')
  const navigate = useNavigate()

  const [searchText, setSearchText] = useState<string>('')
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  })

  const initialEditState = {
    visible: false,
    mode: '',
    title: '',
    dataId: '',
  }

  const initialDeleteState = {
    visible: false,
    title: '',
    url: '',
  }

  const [editState, setEditState] = useState(initialEditState)
  const [deleteState, setDeleteState] = useState(initialDeleteState)

  const {status, data, isFetching, isLoading, isFetched, refetch} = useGetRoleGridData({
    dataState: dataState,
    searchText: searchText,
  })

  function onSearchTextChange(value: string) {
    setSearchText(value)
  }

  function onGridStateChange(e: GridDataStateChangeEvent) {
    console.log(`onGridStateChange() skip: ${e.dataState.skip}`)
    setDataState(e.dataState)
  }

  {
    /* Action Edit end Delete ===================*/
  }
  const actionCell = (props: GridCellProps) => {
    var roleId = props.dataItem.roleId != undefined ? props.dataItem.roleId : ''
    return (
      <td>
        <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
          <span className='cursor-pointer' title={t('Edit')}>
            <Link to={roleId} className='clickable-grid-cell text-hover-primary'>
              <FontAwesomeIcon icon={faPencil} size='lg' />
            </Link>
          </span>
          <span
            className='cursor-pointer'
            onClick={() => hadleDeleteButtonClick(props.dataItem)}
            title={t('Delete')}
          >
            <FontAwesomeIcon icon={faTrash} size='lg' />
          </span>
        </div>
      </td>
    )
  }

  {
    /* Function Action Add ========================= */
  }
  function addData() {
    navigate('/role/new')
  }

  {
    /* Function Action Delete ========================= */
  }
  function hadleDeleteButtonClick(data: any) {
    setDeleteState({
      visible: true,
      title: data.roleName,
      url: 'api/Role/Delete?Id=' + data.roleId,
    })
  }
  function handleFormClose() {
    setDeleteState({...deleteState, visible: false})
  }

  function handleFormDeleteSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    refetch()
  }

  return (
    <>
      <PageTitle>Roles</PageTitle>
      <PageActionSection>
        <div className='d-flex'>
          {/** */}
          <Button className='btn-primary-jotun-yellow' size='large' onClick={addData}>
            {t('Add')} {t('Role')}
          </Button>
        </div>
      </PageActionSection>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body d-flex flex-column'>
          <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
            {/* <!-- left aligned controllers --> */}
            <div className='d-flex align-items-center mb-3'>
              <SearchTextBox onChange={onSearchTextChange} value={searchText}></SearchTextBox>
            </div>
            {/* <!-- right aligned controllers --> */}
            <div className='d-flex align-items-center mb-3'></div>
          </div>
          <div id='gridContainer' className='w-100 flex-grow-1 overflow-hidden h-400px'>
            <Grid
              filterable={false}
              sortable={true}
              pageable={true}
              scrollable='scrollable'
              {...dataState}
              data={data}
              onDataStateChange={onGridStateChange}
              className='h-100'
            >
              <GridColumn field='roleName' title='Role Name' />
              <GridColumn field='description' title='Descrption' />
              <GridColumn
                field='roleId'
                title='Action'
                cell={actionCell}
                className='text-center'
                headerCell={CenteredColumnHeader}
              />
            </Grid>
            {(isLoading || isFetching) && <GridLoadingPanel />}
            {deleteState.visible && (
              <DeleteDataDialog
                title={deleteState.title}
                url={deleteState.url}
                onClose={handleFormClose}
                onSuccess={handleFormDeleteSuccess}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
