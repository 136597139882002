import {Route, Routes} from 'react-router-dom'
import IncidentReportMainPage from './IncidentReportMain'
import IncidentCreatePage from './pelaporan-awal/PelaporanAwalCreatePage'
import IncidentDetailPage from './pelaporan-awal/PelaporanAwalDetailPage'
import InvestigationDetailPage from './investigation/InvestigationDetailPage'
import ProgressDetailPage from './progress/ProgressDetailPage'
import FinishDetailPage from './finish/FinishDetailPage'

export default function IncidentReportPage() {
  return (
    <Routes>
      <Route path='/pelaporan-awal/:incidentId/*' element={<IncidentDetailPage />} />
      <Route path='/investigation/:incidentId/*' element={<InvestigationDetailPage />} />
      <Route path='/progress/:incidentId/*' element={<ProgressDetailPage />} />
      <Route path='/final/:incidentId/*' element={<FinishDetailPage />} />
      <Route path='new' element={<IncidentCreatePage />} />
      <Route index element={<IncidentReportMainPage />} />
    </Routes>
  )
}
