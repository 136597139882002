import { DataResult, State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { formatDate } from '@telerik/kendo-intl'
import axios from "axios";
import { useQuery } from "react-query";
import { PrivilageModel } from "../../../models/PrivilageModel";

interface GetPrivilageGridDataParam {
   dataState: State,
   // fromDate: Date | null,
   // toDate: Date | null,
   // status: string | null,
   searchText: string | null
}

const getPrivilageGridData = async (params: GetPrivilageGridDataParam) => {
   //console.log(`Call getVoucherGridData().. skip=${params.dataState.skip}`)
   let queryString = toDataSourceRequestString(params.dataState)
   let url = `/api/privilage/gridData?${queryString}`
   const data = {
      //  fromDate: params.fromDate ? formatDate(params.fromDate, 'yyyy-MM-dd') : '',
      //  toDate: params.toDate ? formatDate(params.toDate, 'yyyy-MM-dd') : '',
      //  status: params.status,
      searchText: params.searchText
   };
   console.log(`getPrivilageGridData() queryString=${queryString}, searchText=${data.searchText}`)
   const response = await axios.get<DataResult>(url, { params: data })
   console.log('response received')
   return response.data
}

function useGetPrivilageGridData(params: GetPrivilageGridDataParam) {
   console.log(`useGetPrivilageGridData() skip=${params.dataState.skip}`)
   let queryString = toDataSourceRequestString(params.dataState)
   return useQuery<DataResult, Error>(['privilages', params],
      () => getPrivilageGridData(params),
      {
         // enabled: false
      }
   )
}

const getPrivilageData = async (id: string) => {
   const url = `/api/privilage/${id}`;
   const response = await axios.get<PrivilageModel>(url);
   var data = response.data
   return data;
}

const useGetPrivilageData = (id: string) => {
   return useQuery<PrivilageModel, Error>(
      ['privilage', id],
      async () => {
         return await getPrivilageData(id)
      },
      {
         onSuccess: (data: PrivilageModel) => {
            // queryClient.setQueryData(['voucher', data.discount_id], data)
            // queryClient.invalidateQueries(['vouchers'])
         }
      })
}
export { useGetPrivilageGridData, useGetPrivilageData }