/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useState } from 'react'
import { KTSVG } from '../../../helpers'
import { CreateAppModal } from '../../../partials'
import { useLayout, usePageData } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'

const Toolbar1 = () => {
   const { classes } = useLayout()
   // const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
   const { pageActionSection } = usePageData()

   return (
      <>
         <div className='toolbar py-5 py-lg-5 h-85px' id='kt_toolbar'>
            {/* begin::Container */}
            <div
               id='kt_toolbar_container'
               className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
            >
               <DefaultTitle />

               {/* begin::Actions */}
               <div className='d-flex align-items-center py-1'>
                  {pageActionSection}
               </div>
               {/* end::Actions */}
            </div>
            {/* end::Container */}
         </div>
         {/* <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} /> */}

      </>
   )
}

export { Toolbar1 }
