/* eslint-disable jsx-a11y/alt-text */
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  ChartSeriesLabels,
  ChartAxisDefaults,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts'
import {State} from '@progress/kendo-data-query'
import CSS from 'csstype'
import {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns'
import axios from 'axios'
import moment from 'moment'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  Calendar,
  CalendarChangeEvent,
  DatePicker,
  DateTimePicker,
} from '@progress/kendo-react-dateinputs'
import {DropDownListWithRemoteData} from '../../components/Dropdowns/DropDownListWithRemoteData'
import {
  faBuildingMagnifyingGlass,
  faCalendarLinesPen,
  faCoconut,
  faEye,
  faHelmetSafety,
  faPersonFallingBurst,
} from '@fortawesome/pro-regular-svg-icons'
import {faCoconut as faCoconutSolid} from '@fortawesome/pro-solid-svg-icons'
import {faCoconut as faCoconutLight} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Popup, PopupCloseEvent, PopupProps} from '@progress/kendo-react-popup'

export default function DashboardHome() {
  const [companyId, setCompanyId] = useState<string | null>('')
  const [varietyId, setVarietyId] = useState<string | null>('')
  const [estateId, setEstateId] = useState<string | null>('')
  const [searchText, setSearchText] = useState<string>('')
  const [date, setDate] = useState(new Date())
  const [monthSelectedDate, setMonthSelectedDate] = useState(new Date())
  const [yearSelectedDate, setYearSelectedDate] = useState(new Date())

  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  })
  const ref = useRef()
  const handleChange = (e: CalendarChangeEvent<any>) => {
    console.log(e)
  }

  const handleExited = (e: PopupCloseEvent, popUpProps: PopupProps) => {
    console.log(e)
    // console.log(popUpProps);
    // console.log(calProps);
  }

  const intl = useIntl()

  const obserInspect = [
    {category: 'Observation', value: 300},
    {category: 'Inspection', value: 200},
  ]

  const totalValue = obserInspect.reduce((sum, item) => sum + item.value, 0)

  const conditionInPercentage = obserInspect.map((item) => ({
    category: item.category,
    value: item.value,
    percentage: (item.value / totalValue) * 100,
  }))

  const varietyNonHybrid = [
    {category: 'Observation', value: 40},
    {category: 'Inspection', value: 30},
  ]

  const varietyNonHybridPerDate = [
    {
      incident: 2,
      date: '1',
    },
    {
      incident: 3,
      date: '2',
    },
    {
      incident: 2,
      date: '3',
    },
    {
      incident: 5,
      date: '4',
    },
    {
      incident: 3,
      date: '5',
    },
    {
      incident: 4,
      date: '6',
    },
    {
      incident: 1,
      date: '7',
    },
  ]

  const Series = [
    {
      incident: 32,
      date: 'Jan',
    },
    {
      incident: 23,
      date: 'Feb',
    },
    {
      incident: 27,
      date: 'Mar',
    },
    {
      incident: 35,
      date: 'Apr',
    },
    {
      incident: 21,
      date: 'May',
    },
  ]

  const seriesLabels = {
    visible: true,
    padding: 0,
    font: '10px Arial, sans-serif',
  }

  const labels: CSS.Properties = {
    width: '60px',
    textAlign: 'right',
    marginRight: '5px',
  }

  const border: CSS.Properties = {
    backgroundColor: '#E6E6E6',
    border: '2px solid #B3B3B3',
    borderRadius: '5px',
  }

  const icon: CSS.Properties = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#002664',
    justifyContent: 'center',
    paddingLeft: '5px',
    paddingTop: '5px',
    textAlign: 'center',
  }

  const number: CSS.Properties = {
    fontSize: '2rem',
    textAlign: 'center',
    fontWeight: 'bold',
    font: 'sans-serif',
  }

  const desc: CSS.Properties = {
    textAlign: 'center',
    fontSize: '1rem',
    font: 'sans-serif',
  }

  const labelDesc: CSS.Properties = {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    font: 'sans-serif',
    color: '#ffffff',
  }

  const {currentUser} = useAuth()
  const stringObj = JSON.stringify(currentUser)
  const arryObj = JSON.parse(stringObj)

  async function onFilterCompanyIdChange(value: string | null) {
    console.log('onFilterSiteIdChange() value = ' + value)
    setCompanyId(value)
    setDataState({...dataState, skip: 0})
  }
  async function onFilterVarietyChange(value: string | null) {
    console.log('onFilterSiteIdChange() value = ' + value)
    setVarietyId(value)
    setDataState({...dataState, skip: 0})
  }
  async function onFilterEstateIdChangeTM(value: string | null) {
    console.log('onFilterSiteIdChange() value = ' + value)
    setEstateId(value)
    setDataState({...dataState, skip: 0})
  }
  async function onFilterEstateIdChangeYTD(value: string | null) {
    console.log('onFilterSiteIdChange() value = ' + value)
    setEstateId(value)
    setDataState({...dataState, skip: 0})
  }

  const currentDate = new Date()
  currentDate.setDate(1)
  const defaultEndValue = new Date()

  const handleCloseCalendar = () => {
    // Your logic to close the calendar
    console.log('Closing the calendar')
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <PageTitle>Home Dashboard</PageTitle>
        </div>
      </div>
      <div className='card '>
        <div className='card-body'>
          <h3 className='mb-4'>Month to Date</h3>
          <hr className='mb-4' />
          <div className='row col-xl-12  mb-5 mx-0'>
            <div className='col-lg-4 col-sm-12'>
              <div className='row'>
                <span className='col-3 '>Period :</span>
                <DatePicker
                  className='col-sm-12 col-lg-5 g-0 me-3'
                  value={monthSelectedDate}
                  format={'MMMM / yyyy'}
                  popup={(popUpProps) => (
                    <Popup {...popUpProps} onClose={(e) => handleExited(e, popUpProps)}>
                      <Calendar
                        // {...calProps}
                        onChange={(e) => {
                          setMonthSelectedDate(e.value)
                          handleChange(e)
                        }}
                        bottomView='year'
                        topView='year'
                      />
                    </Popup>
                  )}
                />
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-8'>
            <div className='col-xl-3'>
              <div className='card shadow'>
                <div className='row m-2'>
                  <div className='col-4'>
                    <div style={icon}>
                      {/* <img src={toAbsoluteUrl('/media/logos/Palm.svg')} className='h-50px' /> */}
                      <FontAwesomeIcon icon={faCalendarLinesPen} size='lg' />
                    </div>
                  </div>
                  <div className='col-8' style={{color: 'orange'}}>
                    <div style={number}>
                      <span>13</span>
                    </div>
                    <div style={desc}>Agenda</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card shadow'>
                <div className='row m-2'>
                  <div className='col-4'>
                    <div style={icon}>
                      {/* <img
                        src={toAbsoluteUrl('/media/icons/totalCoconut.svg')}
                        className='h-55px'
                      /> */}
                      <FontAwesomeIcon icon={faHelmetSafety} size='lg' />
                    </div>
                  </div>
                  <div className='col-8' style={{color: 'green'}}>
                    <div style={number}>{25}</div>
                    <div style={desc}>SOP</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-3'>
              <div className='card shadow'>
                <div className='row m-2'>
                  <div className='col-xl-3 col-sm-4'>
                    <div style={icon}>
                      {/* <img src={toAbsoluteUrl('/media/logos/circle-xmark.svg')} className='h-50px' /> */}
                      <FontAwesomeIcon icon={faBuildingMagnifyingGlass} size='lg' />
                    </div>
                  </div>
                  <div className='col-xl-9 col-sm-8' style={{color: 'blue'}}>
                    <div style={number}>{70}</div>
                    <div style={desc}>Observation & Inspection</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card shadow'>
                <div className='row m-2'>
                  <div className='col-4'>
                    <div style={icon}>
                      {/* <img src={toAbsoluteUrl('/media/logos/circle-xmark.svg')} className='h-50px' /> */}
                      <FontAwesomeIcon icon={faPersonFallingBurst} size='lg' />
                    </div>
                  </div>
                  <div className='col-8' style={{color: 'brown'}}>
                    <div style={number}>{20}</div>
                    <div style={desc}>Incident Report</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row g-5 g-xl-8 mt-0'>
            <div className='col-xl-6'>
              <div className='card shadow'>
                <div className='row m-2'>
                  <div className='col-12'>
                    <Chart
                      style={{
                        height: 300,
                      }}
                    >
                      <ChartTitle text='Observation & Inspection' />
                      <ChartLegend position='bottom' />
                      <ChartSeries>
                        <ChartSeriesItem
                          type='pie'
                          data={varietyNonHybrid}
                          field='value'
                          categoryField='category'
                          labels={{
                            visible: true,
                            content: (e) => `${e.category} - ${e.value}`,
                          }}
                        />
                      </ChartSeries>
                    </Chart>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card shadow'>
                <div className='row m-2'>
                  <div className='col-12'>
                    <Chart
                      style={{
                        height: 300,
                      }}
                    >
                      <ChartTitle text='Daily Incident Chart' />
                      <ChartLegend position='bottom' />
                      <ChartSeries>
                        <ChartSeriesItem
                          name='Incident'
                          type='column'
                          categoryField='date'
                          data={varietyNonHybridPerDate}
                          field='incident'
                          color={'#F0AB00'}
                          labels={seriesLabels}
                          stack={{group: 'a'}}
                        >
                          <ChartSeriesLabels position={'top'} />
                        </ChartSeriesItem>
                      </ChartSeries>
                    </Chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card mt-5'>
        <div className='card-body'>
          <h3>Year to Date</h3>
          <hr className='mb-4' />
          <div className='row col-xl-12 mx-0'>
            <div className='col-lg-4 col-sm-12'>
              <div className='row'>
                <span className='col-3 '>Period :</span>
                <DatePicker
                  className='col-sm-12 col-lg-5 g-0 me-3'
                  value={yearSelectedDate}
                  format={'yyyy'}
                  popup={(popUpProps) => (
                    <Popup {...popUpProps} onClose={(e) => handleExited(e, popUpProps)}>
                      <Calendar
                        // {...calProps}
                        onChange={(e) => {
                          setYearSelectedDate(e.value)
                          handleChange(e)
                        }}
                        bottomView='decade'
                        topView='decade'
                      />
                    </Popup>
                  )}
                />
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-8 mt-0'>
            <div className='col-xl-6'>
              <div className='card shadow'>
                <div className='row m-2'>
                  <div className='col-12'>
                    <Chart
                      style={{
                        height: 350,
                      }}
                    >
                      <ChartTitle text='Total Incident' />
                      <ChartLegend position='bottom' orientation='horizontal' />
                      <ChartSeries>
                        <ChartSeriesItem
                          name='Incident'
                          type='column'
                          categoryField='date'
                          data={Series}
                          field='incident'
                          color={'#F0AB00'}
                          labels={seriesLabels}
                        >
                          <ChartSeriesLabels position={'top'} />
                        </ChartSeriesItem>
                      </ChartSeries>
                    </Chart>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card shadow'>
                <div className='row m-2'>
                  <div className='col=12'>
                    {/* <Chart
                  style={{
                    height: 350,
                  }}
                >
                  <ChartTitle text='Tree Condition' />
                  <ChartLegend position='bottom' orientation='horizontal' />
                  <ChartSeries>
                    <ChartSeriesItem
                      name='Normal'
                      type='line'
                      categoryField='date'
                      data={Series}
                      field='normal'
                      color={'#329E36'}
                      labels={seriesLabels}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type1'
                      type='line'
                      categoryField='date'
                      data={Series}
                      field='type1'
                      color={'#0d6efd'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type2'
                      type='line'
                      categoryField='date'
                      data={Series}
                      field='type2'
                      color={'#6f42c1'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type3'
                      type='line'
                      categoryField='date'
                      data={Series}
                      field='type3'
                      color={'#F0AB00'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart> */}
                    <Chart
                      style={{
                        height: 350,
                      }}
                    >
                      <ChartTitle text='Total Observation & Inspection' />
                      <ChartLegend position='bottom' />
                      <ChartSeries>
                        <ChartSeriesItem
                          type='pie'
                          data={conditionInPercentage}
                          field='percentage'
                          categoryField='category'
                          labels={{
                            visible: true,
                            content: (e) => `${e.category} ${e.value}%`,
                          }}
                        />
                      </ChartSeries>
                    </Chart>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='col-xl-6'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col-12'>
                <Chart>
                  <ChartTitle text='Tree Condition' />
                  <ChartLegend position='bottom' />
                  <ChartSeries>
                    <ChartSeriesItem
                      type='pie'
                      data={conditionInPercentage}
                      field='percentage'
                      categoryField='category'
                      labels={{
                        visible: true,
                        content: (e) => `${e.category} ${e.value}%`,
                      }}
                    />
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-6'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col=12'>
                <Chart
                  style={{
                    height: 350,
                  }}
                >
                  <ChartTitle text='Sample Chart 2' />
                  <ChartLegend position='bottom' orientation='horizontal' />
                  <ChartCategoryAxis></ChartCategoryAxis>
                  <ChartSeries>
                    <ChartSeriesItem
                      name='Type1'
                      type='bar'
                      categoryField='date'
                      data={Series}
                      field='type1'
                      color={'#0d6efd'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type2'
                      type='bar'
                      categoryField='date'
                      data={Series}
                      field='type2'
                      color={'#6f42c1'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
