import {FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import RolesPage from '../pages/administration/userManagement/roles/RolesPage'
import PrivilageListPage from '../pages/administration/privilage/PrivilageListPage'
import UserPage from '../pages/administration/userManagement/users/UserPage'
import PermissionPage from '../pages/administration/userManagement/permissions/PermissionPage'
import DashboardHome from '../pages/dashboard/Home'
import DashboardHomeNew from '../pages/dashboard/HomeNew'
import SopPage from '../pages/operation/sop/SopPage'
import HazardPage from '../pages/operation/hazard/HazardPage'
import PermitPage from '../pages/operation/permit/PermitPage'
import ObservationInspectionMngPage from '../pages/administration/observationInspectionMnj/ObservationInspectionMngPage'
import HseOptionPage from '../pages/administration/hse-option/HseOptionPage'
import ObservationInspectionPage from '../pages/safety/obesrvation/ObservationInspectionPage'
import SopNewPage from '../pages/operation/sop-new/SopNewPage'
import FileViewer from '../pages/operation/sop-new/FileViewer'
import IncidentReportPage from '../pages/safety/incident-report/IncidentReportPage'
import ObservationPageNew from '../pages/safety/obesrvation/ObservationPageNew'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}

        <Route path='dashboardold' element={<DashboardHome />} />
        <Route path='dashboard' element={<DashboardHomeNew />} />
        <Route
          path='user/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        <Route
          path='role/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='permission/*'
          element={
            <SuspensedView>
              <PermissionPage />
            </SuspensedView>
          }
        />

        <Route
          path='privilage'
          element={
            <SuspensedView>
              <PrivilageListPage />
            </SuspensedView>
          }
        />
        <Route
          path='sopOld/*'
          element={
            <SuspensedView>
              <SopPage />
            </SuspensedView>
          }
        />
        <Route
          path='sop/*'
          element={
            <SuspensedView>
              <SopNewPage />
            </SuspensedView>
          }
        />

        <Route
          path='hazard/*'
          element={
            <SuspensedView>
              <HazardPage />
            </SuspensedView>
          }
        />
        <Route
          path='observ-inspect/*'
          element={
            <SuspensedView>
              <ObservationInspectionPage />
            </SuspensedView>
          }
        />
        <Route
          path='work-inspection/*'
          element={
            <SuspensedView>
              <ObservationPageNew />
            </SuspensedView>
          }
        />
        <Route
          path='incident-report/*'
          element={
            <SuspensedView>
              <IncidentReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='permittowork/*'
          element={
            <SuspensedView>
              <PermitPage />
            </SuspensedView>
          }
        />
        <Route
          path='inspectiontemplate/*'
          element={
            <SuspensedView>
              <ObservationInspectionMngPage />
            </SuspensedView>
          }
        />
        <Route
          path='hse-option/*'
          element={
            <SuspensedView>
              <HseOptionPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
