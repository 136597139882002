import {PageActionSection, PageTitle} from '../../../../../_metronic/layout/core'
import RoleForm from './RoleForm'

export default function SiteCreatePage() {
  return (
    <>
      <PageTitle>Add Role</PageTitle>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body pt-9 pb-0 d-flex flex-column'>
          <RoleForm mode='create' dataId='' />
        </div>
      </div>
    </>
  )
}
