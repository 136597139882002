import {Button} from '@progress/kendo-react-buttons'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {PageActionSection, PageTitle} from '../../../../../_metronic/layout/core'
import {useGetPermissionData} from './PermissionApi'
import PermissionForm from './PermissionForm'

export default function PermissionDetailPage() {
  const {t} = useTranslation('translation')
  let {permissionId} = useParams()
  const navigate = useNavigate()

  const {data} = useGetPermissionData(permissionId ?? '')

  function onBackClicked() {
    navigate('/permission', {replace: true})
  }

  return (
    <>
      <PageTitle>{data?.permissionName}</PageTitle>
      <PageActionSection>
        <div className='d-flex'>
          <Button className='btn-primary-jotun-yellow' size='large' onClick={onBackClicked}>
            {t('Back')}
          </Button>
        </div>
      </PageActionSection>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body pt-9 pb-0 d-flex flex-column'>
          <PermissionForm mode='view' data={data} dataId='' />
        </div>
      </div>
    </>
  )
}
