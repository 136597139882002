import {State} from '@progress/kendo-data-query'
import {useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridHeaderCellProps,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'
import GridLoadingPanel from '../../../components/Grid/GridLoadingPanel'
import {Button} from '@progress/kendo-react-buttons'
import {CenteredColumnHeader} from '../../../components/Grid/CenteredColumnHeader'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLayerPlus, faPencil, faPlus, faTrash} from '@fortawesome/pro-regular-svg-icons'
import toast from 'react-hot-toast'

import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog'
import axios from 'axios'
import {useLayout} from '../../../../_metronic/layout/core/LayoutProvider'
import {PageActionSection, PageTitle} from '../../../../_metronic/layout/core'
import {useGetHseFormGridData} from './ObservationInspectionApi'
import {HseFormItemModel, HseFormModel, HseFormSectionModel} from '../../../models/HSEFormModel'
import HseForm from './HSEForm'
import HseFormSection from './section/HSEFormSection'
import HseFormItem from './item/HseFormItem'

export default function ObservationInspectionPage() {
  const queryClient = useQueryClient()
  // const { windowSize } = useLayout();
  const [gridHeight, setGridHeight] = useState<number>(0)
  const [searchText, setSearchText] = useState<string>('')
  const [dataState, setDataState] = useState<State>({
    take: 100,
    skip: 0,
  })
  const [skip, setSkip] = useState<number>(0)
  const buttonSize = 'medium'
  const pageSize = 50

  const initialEditState = {
    visible: false,
    mode: '',
    title: '',
    dataId: '',
  }

  const initialDeleteState = {
    visible: false,
    title: '',
    id: '',
  }

  const [editState, setEditState] = useState(initialEditState)
  const [deleteState, setDeleteState] = useState(initialDeleteState)

  const {status, data, isFetching, isLoading, isFetched, refetch} = useGetHseFormGridData({
    dataState: dataState,
    searchText: searchText,
  })

  function onGridStateChange(e: GridDataStateChangeEvent) {
    console.log(`onGridStateChange() skip: ${e.dataState.skip}`)
    setDataState(e.dataState)
  }

  function onBtnAddClick(data: any) {
    setEditState({
      visible: true,
      mode: 'add',
      title: 'Add HSE Form',
      dataId: '',
    })
  }

  function handleEditButtonClick(data: HseFormModel) {
    console.log('hadleEditButtonClick(): ' + JSON.stringify(data))
    setEditState({
      visible: true,
      mode: 'edit',
      title: 'Edit HSE Form',
      dataId: data.hseFormId,
    })
  }

  function handleAddFormClose() {
    setEditState({...editState, visible: false})
  }

  function handleFormSuccess(data: any, responseData: HseFormModel) {
    console.log('handleFormSuccess() data = ', data, 'responseData = ', responseData)
    let message = ''
    if (editState.mode === 'add') {
      message = responseData.hseFormName + ' has been added'
    } else {
      message = responseData.hseFormName + ' has been updated'
    }
    toast.success(message)
    refetch()
    setEditState({...editState, visible: false})
  }

  function handleDeleteButtonClick(data: HseFormModel) {
    setDeleteState({
      visible: true,
      title: data.hseFormName ?? '',
      id: data.hseFormId,
    })
  }

  function handleDeleteFormClose() {
    setDeleteState({...deleteState, visible: false})
  }

  function handleDeleteConfirmed() {
    setDeleteState({...deleteState, visible: false})
    const deletePromise = new Promise<HseFormModel>((resolve, reject) => {
      let id = deleteState.id
      let url = '/api/HseForm/Delete?id=' + id
      axios
        .post(url, data)
        .then((response) => {
          console.log('response >>', response)
          if (response.data.success) {
            refetch()
            resolve(response.data.data)
          } else {
            reject(response.data.errorMessage)
            console.log(response.data.errorMessage)
          }
        })
        .catch((error) => {
          console.log(error)
          reject(error.response.data)
        })
    })
    toast.promise(
      deletePromise,
      {
        loading: 'Deleting..',
        success: (data) => `Successfully delete ${data.hseFormName}`,
        error: (err) => `${err.toString()}`,
      },
      {
        style: {
          minWidth: '300px',
        },
        loading: {
          duration: 60000,
        },
        success: {
          duration: 5000,
          // icon: '🔥',
        },
        error: {
          duration: 5000,
          // icon: '🔥',
        },
      }
    )
  }

  const actionCell = (props: GridCellProps) => {
    return (
      <td>
        <Button
          size={buttonSize}
          fillMode={'flat'}
          style={{width: '40px'}}
          title='Add Section'
          onClick={() => handleAddSectionButtonClick(props.dataItem)}
        >
          <FontAwesomeIcon icon={faLayerPlus} size='lg' />
        </Button>
        <Button
          size={buttonSize}
          fillMode={'flat'}
          style={{width: '40px'}}
          title='Edit'
          onClick={() => handleEditButtonClick(props.dataItem)}
        >
          <FontAwesomeIcon icon={faPencil} size='lg' />
        </Button>
        <Button
          size={buttonSize}
          fillMode={'flat'}
          style={{width: '40px'}}
          title='Delete'
          onClick={() => handleDeleteButtonClick(props.dataItem)}
        >
          <FontAwesomeIcon icon={faTrash} size='lg' />
        </Button>
      </td>
    )
  }

  const [hseFormData, setHseFormData] = useState<any[]>()
  const [sectionData, setSectionData] = useState<any[]>()
  const [sectionDataWithDetail, setSectionDataWithDetail] = useState<any[]>()
  const [itemData, setItemData] = useState<any[]>()

  useEffect(() => {
    setHseFormData(data?.data)
  }, [data])

  const expandChangeSection = (event: GridExpandChangeEvent) => {
    let hseFormId = event.dataItem.hseFormId

    data?.data.map((item: any) => {
      item.expanded = false
      item.details = []
      if (item.hseFormId === event.dataItem.hseFormId) {
        item.expanded = !event.dataItem.expanded
      }
      return item
    })

    let url = `api/HseForm/item/GridData?formId=` + hseFormId
    axios
      .get(url)
      .then((response) => {
        let dt: any = hseFormData?.slice()
        let index = dt.findIndex((d: any) => d.hseFormId === hseFormId)
        dt[index].details = response.data
        setHseFormData(dt)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const DetailComponentSection = (props: GridDetailRowProps) => {
    setSectionData(props.dataItem.details.data)

    if (sectionData) {
      const item = sectionData.filter((item) => item.isSection === false)
      console.log(item)

      const section = sectionData.filter((item) => item.isSection === true)
      return (
        <>
          {/* <Grid
            data={item}
            // detail={DetailComponentItem}
            // expandField='expanded'
            // onExpandChange={expandChangeItem}
            className='mb-2'
          >
            <GridColumn field='number' title='Number' width='100px' />
            <GridColumn field='label' title='Item Label' />
            <GridColumn field='inputType' title='Input Type' width='100px' />
            <GridColumn field='passedValue' title='Pass Value' width='100px' />
            <GridColumn
              field='isMandatory'
              title='Mandatory'
              cell={mandatoryValueCell}
              width='100px'
            />
            <GridColumn field='needApprovalValue' title='Need Approval' width='200px' />
            <GridColumn
              field='hse_form_section_id'
              title={'Action'}
              width='200px'
              cell={actionCellSection}
              className='text-center'
              headerCell={CenteredColumnHeader}
            />
          </Grid> */}

          <Grid
            id='section'
            data={section}
            detail={DetailComponentItem}
            expandField='expanded'
            onExpandChange={expandChangeItem}
          >
            <GridColumn field='label' title='Section Label' />
            <GridColumn field='description' title='Section Description' />
            <GridColumn
              field='hse_form_section_id'
              title={'Action'}
              width='200px'
              cell={actionCellSection}
              className='text-center'
              headerCell={CenteredColumnHeader}
            />
          </Grid>
        </>
      )
    }
    return (
      <div style={{height: '50px', width: '100%'}}>
        <div style={{position: 'absolute', width: '100%'}}>
          <div className='k-loading-image' />
        </div>
      </div>
    )
  }

  const actionCellSection = (props: GridCellProps) => {
    if (!props.dataItem.isSection) {
      // Menyembunyikan tombol tambah jika item bukan bagian
      return (
        <td>
          <Button
            size={buttonSize}
            fillMode={'flat'}
            style={{width: '40px'}}
            title='Edit'
            onClick={() => handleEditItemButtonClick(props.dataItem)}
          >
            <FontAwesomeIcon icon={faPencil} size='lg' />
          </Button>
          <Button
            size={buttonSize}
            fillMode={'flat'}
            style={{width: '40px'}}
            title='Delete'
            onClick={() => handleDeleteItemButtonClick(props.dataItem)}
          >
            <FontAwesomeIcon icon={faTrash} size='lg' />
          </Button>
        </td>
      )
    } else {
      // Tombol tambah untuk item yang merupakan bagian
      return (
        <td>
          <Button
            size={buttonSize}
            fillMode={'flat'}
            style={{width: '40px'}}
            title='Add Section'
            onClick={() => handleAddItemButtonClick(props.dataItem)}
          >
            <FontAwesomeIcon icon={faLayerPlus} size='lg' />
          </Button>
          <Button
            size={buttonSize}
            fillMode={'flat'}
            style={{width: '40px'}}
            title='Edit'
            onClick={() => handleEditItemButtonClick(props.dataItem)}
          >
            <FontAwesomeIcon icon={faPencil} size='lg' />
          </Button>
          <Button
            size={buttonSize}
            fillMode={'flat'}
            style={{width: '40px'}}
            title='Delete'
            onClick={() => handleDeleteSectionButtonClick(props.dataItem)}
          >
            <FontAwesomeIcon icon={faTrash} size='lg' />
          </Button>
        </td>
      )
    }
  }

  const initialEditSectionState = {
    visible: false,
    mode: '',
    title: '',
    hseFormId: '',
    dataId: '',
  }

  const [editSectionState, setEditSectionState] = useState(initialEditSectionState)
  const [deleteSectionState, setDeleteSectionState] = useState(initialDeleteState)

  function handleAddSectionButtonClick(data: any) {
    setEditSectionState({
      visible: true,
      mode: 'add',
      title: 'Add Section',
      hseFormId: data.hseFormId,
      dataId: '',
    })
  }

  function handleEditSectionButtonClick(data: HseFormSectionModel) {
    setEditSectionState({
      visible: true,
      mode: 'edit',
      title: 'Edit Section',
      hseFormId: data.hseFormId,
      dataId: data.hseFormItemId,
    })
  }

  function handleSectionFormClose() {
    setEditSectionState({...editSectionState, visible: false})
  }

  function handleSectionFormSuccess(data: any, responseData: HseFormSectionModel) {
    let message = ''
    if (editState.mode === 'add') {
      message = responseData.label + ' has been added'
    } else {
      message = responseData.label + ' has been updated'
    }
    toast.success(message)
    refetch()
    setEditSectionState({...editSectionState, visible: false})
  }

  function handleDeleteSectionButtonClick(data: HseFormSectionModel) {
    setDeleteSectionState({
      visible: true,
      title: data.label ?? '',
      id: data.hseFormItemId,
    })
  }

  function handleDeleteSectionFormClose() {
    setDeleteSectionState({...deleteSectionState, visible: false})
  }

  function handleDeleteSectionConfirmed() {
    setDeleteSectionState({...deleteSectionState, visible: false})
    const deletePromise = new Promise<HseFormSectionModel>((resolve, reject) => {
      let id = deleteSectionState.id
      let url = '/api/HseForm/Section/Delete/' + id
      axios
        .post(url, data)
        .then((response) => {
          console.log('response >>', response)
          if (response.data.success) {
            refetch()
            resolve(response.data.data)
          } else {
            reject(response.data.errorMessage)
            console.log(response.data.errorMessage)
          }
        })
        .catch((error) => {
          console.log(error)
          reject(error.response.data)
        })
    })
    toast.promise(
      deletePromise,
      {
        loading: 'Deleting..',
        success: (data) => `Successfully delete ${data.label}`,
        error: (err) => `${err.toString()}`,
      },
      {
        style: {
          minWidth: '300px',
        },
        loading: {
          duration: 60000,
        },
        success: {
          duration: 5000,
          // icon: '🔥',
        },
        error: {
          duration: 5000,
          // icon: '🔥',
        },
      }
    )
  }

  const expandChangeItem = (event: GridExpandChangeEvent) => {
    if (!event.dataItem.isSection) {
      // Jika bukan bagian, maka keluar dari fungsi
      return // Fungsi berhenti di sini
    }
    let hseFormId = event.dataItem.hseFormId
    let hseFormItemId = event.dataItem.hseFormItemId
    sectionData?.map((item: any) => {
      item.expanded = false
      item.details = []
      if (item.hseFormItemId === event.dataItem.hseFormItemId) {
        item.expanded = !event.dataItem.expanded
      }
      return item
    })

    let url = `api/HseForm/Item/GridData?formId=` + hseFormId + `&sectionId=` + hseFormItemId
    axios
      .get(url)
      .then((response) => {
        let dt: any = sectionData?.slice()
        let index = dt.findIndex((d: any) => d.hseFormItemId === hseFormItemId)
        dt[index].details = response.data
        setSectionData(dt)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const mandatoryValueCell = (props: GridCellProps) => {
    return <td>{props.dataItem.is_mandatory ? 'YES' : 'NO'}</td>
  }

  const DetailComponentItem = (props: GridDetailRowProps) => {
    setItemData(props.dataItem.details.data)
    if (itemData) {
      return (
        <Grid data={itemData}>
          {/* <GridColumn field='number' title='Number' width='100px' /> */}
          <GridColumn field='label' title='Item Label' />
          <GridColumn field='inputType' title='Input Type' width='100px' />
          <GridColumn field='passedValue' title='Pass Value' width='100px' />
          <GridColumn
            field='isMandatory'
            title='Mandatory'
            cell={mandatoryValueCell}
            width='100px'
          />
          <GridColumn field='needApprovalValue' title='Need Approval' width='200px' />
          <GridColumn
            field='hseFormItemId'
            title={'Action'}
            width='100px'
            cell={actionCellItem}
            className='text-center'
            headerCell={CenteredColumnHeader}
          />
        </Grid>
      )
    }
    return (
      <div style={{height: '50px', width: '100%'}}>
        <div style={{position: 'absolute', width: '100%'}}>
          <div className='k-loading-image' />
        </div>
      </div>
    )
  }

  const initialEditItemState = {
    visible: false,
    mode: '',
    title: '',
    hseFormId: '',
    hseFormSectionId: '',
    dataId: '',
    hseFormItemCode: '',
  }

  const [editItemState, setEditItemState] = useState(initialEditItemState)
  const [deleteItemState, setDeleteItemState] = useState(initialDeleteState)

  function handleAddItemButtonClick(data: any) {
    setEditItemState({
      visible: true,
      mode: 'add',
      title: 'Add Item',
      hseFormId: data.hseFormId,
      hseFormSectionId: data.hseFormItemId,
      dataId: '',
      hseFormItemCode: '',
    })
  }

  function handleEditItemButtonClick(data: HseFormItemModel) {
    if (data.isSection) {
      setEditItemState({
        visible: true,
        mode: 'edit',
        title: 'Edit Section',
        hseFormId: data.hseFormId,
        hseFormSectionId: data.hseFormItemCode,
        dataId: data.hseFormItemId,
        hseFormItemCode: data.hseFormItemCode,
      })
    } else {
      setEditItemState({
        visible: true,
        mode: 'edit',
        title: 'Edit Item',
        hseFormId: data.hseFormId,
        hseFormSectionId: data.hseFormItemCode,
        dataId: data.hseFormItemId,
        hseFormItemCode: data.hseFormItemCode,
      })
    }
  }

  function handleItemFormClose() {
    setEditItemState({...editItemState, visible: false})
  }

  function handleItemFormSuccess(data: any, responseData: HseFormItemModel) {
    let message = ''
    if (editState.mode === 'add') {
      message = responseData.label + ' has been added'
    } else {
      message = responseData.label + ' has been updated'
    }
    toast.success(message)
    refetch()
    setEditItemState({...editItemState, visible: false})
  }

  function handleDeleteItemButtonClick(data: HseFormItemModel) {
    setDeleteItemState({
      visible: true,
      title: data.label ?? '',
      id: data.hseFormItemId,
    })
  }

  function handleDeleteItemFormClose() {
    setDeleteItemState({...deleteItemState, visible: false})
  }

  function handleDeleteItemConfirmed() {
    setDeleteItemState({...deleteItemState, visible: false})
    const deletePromise = new Promise<HseFormItemModel>((resolve, reject) => {
      let id = deleteItemState.id
      let url = '/api/HseForm/Item/Delete/' + id
      axios
        .post(url, data)
        .then((response) => {
          console.log('response >>', response)
          if (response.data.success) {
            refetch()
            resolve(response.data.data)
          } else {
            reject(response.data.errorMessage)
            console.log(response.data.errorMessage)
          }
        })
        .catch((error) => {
          console.log(error)
          reject(error.response.data)
        })
    })
    toast.promise(
      deletePromise,
      {
        loading: 'Deleting..',
        success: (data) => `Successfully delete ${data.label}`,
        error: (err) => `${err.toString()}`,
      },
      {
        style: {
          minWidth: '300px',
        },
        loading: {
          duration: 60000,
        },
        success: {
          duration: 5000,
          // icon: '🔥',
        },
        error: {
          duration: 5000,
          // icon: '🔥',
        },
      }
    )
  }

  const actionCellItem = (props: GridCellProps) => {
    return (
      <td>
        <Button
          size={buttonSize}
          fillMode={'flat'}
          style={{width: '40px'}}
          title='Edit'
          onClick={() => handleEditItemButtonClick(props.dataItem)}
        >
          <FontAwesomeIcon icon={faPencil} size='lg' />
        </Button>
        <Button
          size={buttonSize}
          fillMode={'flat'}
          style={{width: '40px'}}
          title='Delete'
          onClick={() => handleDeleteItemButtonClick(props.dataItem)}
        >
          <FontAwesomeIcon icon={faTrash} size='lg' />
        </Button>
      </td>
    )
  }

  return (
    <>
      <PageTitle>Inspection Form</PageTitle>
      <PageActionSection>
        <div className='d-flex'>
          <Button className='btn-primary-jotun-yellow' size='large' onClick={onBtnAddClick}>
            Add
          </Button>
        </div>
      </PageActionSection>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body d-flex flex-column'>
          <Grid
            filterable={false}
            sortable={true}
            pageable={true}
            scrollable='scrollable'
            {...dataState}
            data={hseFormData}
            skip={dataState.skip}
            take={dataState.take}
            total={data?.total}
            onDataStateChange={onGridStateChange}
            className='h-100'
            detail={DetailComponentSection}
            expandField='expanded'
            onExpandChange={expandChangeSection}
            style={{height: `${gridHeight - 32}px`}}
          >
            <GridColumn field='hseFormCode' title='Inspection Code' sortable={true} />
            <GridColumn field='hseFormName' title='Inspection Name' />
            <GridColumn field='description' title='Description' />
            <GridColumn
              field='hse_form_id'
              title='Action'
              width='200px'
              cell={actionCell}
              className='text-center'
              headerCell={CenteredColumnHeader}
            />
          </Grid>

          {(isLoading || isFetching) && <GridLoadingPanel />}
          {editState.visible && (
            <HseForm
              mode={editState.mode}
              title={editState.title}
              dataId={editState.dataId}
              onClose={handleAddFormClose}
              onSuccess={handleFormSuccess}
            />
          )}
          {deleteState.visible && (
            <ConfirmationDialog
              title='Delete'
              message={"Remove '" + deleteState.title + "' from HSE Form?"}
              confirmationType='yes-no'
              onClose={handleDeleteFormClose}
              onConfirmed={handleDeleteConfirmed}
            />
          )}
          {editSectionState.visible && (
            <HseFormSection
              mode={editSectionState.mode}
              title={editSectionState.title}
              hseFormId={editSectionState.hseFormId}
              dataId={editSectionState.dataId}
              onClose={handleSectionFormClose}
              onSuccess={handleSectionFormSuccess}
            />
          )}
          {deleteSectionState.visible && (
            <ConfirmationDialog
              title='Delete'
              message={"Remove '" + deleteSectionState.title + "' from Section?"}
              confirmationType='yes-no'
              onClose={handleDeleteSectionFormClose}
              onConfirmed={handleDeleteSectionConfirmed}
            />
          )}
          {editItemState.visible && (
            <HseFormItem
              mode={editItemState.mode}
              title={editItemState.title}
              hseFormId={editItemState.hseFormId}
              hseFormSectionId={editItemState.hseFormSectionId}
              hseFormItemCode={editItemState.hseFormItemCode}
              dataId={editItemState.dataId}
              onClose={handleItemFormClose}
              onSuccess={handleItemFormSuccess}
            />
          )}
          {deleteItemState.visible && (
            <ConfirmationDialog
              title='Delete'
              message={"Remove '" + deleteItemState.title + "' from Item?"}
              confirmationType='yes-no'
              onClose={handleDeleteItemFormClose}
              onConfirmed={handleDeleteItemConfirmed}
            />
          )}
        </div>
      </div>
    </>
  )
}
