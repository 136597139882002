import {DataResult, State, toDataSourceRequestString} from '@progress/kendo-data-query'
import {formatDate} from '@telerik/kendo-intl'
import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {PermissionModel} from '../../../../models/PermissionModel'

interface GetPermissionGridDataParam {
  dataState: State
  searchText: string | null
}

const getPermissionGridData = async (params: GetPermissionGridDataParam) => {
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `/api/Permission/GridData?${queryString}`
  console.log('Data Grid :' + queryString)
  const data = {
    searchText: params.searchText,
  }
  console.log(`getRoleGridData() queryString=${queryString}, searchText=${data.searchText}`)
  const response = await axios.get<DataResult>(url, {params: data})
  console.log('response received')
  return response.data
}

function useGetPermissionGridData(params: GetPermissionGridDataParam) {
  console.log(`useGetRoleGridData() skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(['roles', params], () => getPermissionGridData(params), {
    // enabled: false
  })
}

// Get Detail data =======================
const getPermissionData = async (id: string) => {
  const url = `/api/Permission/${id}`
  const response = await axios.get<PermissionModel>(url)
  var data = response.data
  return data
}

const useGetPermissionData = (id: string) => {
  return useQuery<PermissionModel, Error>(
    ['privilage', id],
    async () => {
      return await getPermissionData(id)
    },
    {
      onSuccess: (data: PermissionModel) => {
        //console.log("Test3 :" + data)
      },
    }
  )
}

// Add Data ====================================
const callAddPermission = async (data: PermissionModel) => {
  const url = 'api/Permission/add'
  console.log('URL: ', url)
  const response = await axios.post<ServiceResult<PermissionModel>>(url, data)
  console.log('Response: ', response)
  return response.data
}

const useAddPermission = () => {
  const queryClient = useQueryClient()
  return useMutation((data: PermissionModel) => callAddPermission(data), {
    onSuccess: (response: ServiceResult<PermissionModel>) => {
      queryClient.invalidateQueries(['Permissions'])
    },
  })
}

// Update Data ====================================
const callUpdatePermission = async (data: PermissionModel) => {
  console.log('callUpdatePermission()..')
  const url = 'api/Permission/update'
  const response = await axios.post<ServiceResult<PermissionModel>>(url, data)
  return response.data
}

const useUpdatePermission = () => {
  const queryClient = useQueryClient()
  return useMutation((data: PermissionModel) => callUpdatePermission(data), {
    onSuccess: (response: ServiceResult<PermissionModel>) => {
      queryClient.invalidateQueries(['Permissions'])
    },
  })
}

export {useGetPermissionGridData, useGetPermissionData, useAddPermission, useUpdatePermission}
