import {Route, Routes} from 'react-router-dom'
import SopMainPage from './SopMainPage'
import {SopCreatePage} from './SopCreatePage'

export default function SopPage() {
  return (
    <Routes>
      {/* <Route path=':companyId/*' element={<CompanyDetailPage  />} /> */}
      <Route path='new' element={<SopCreatePage />} />
      <Route index element={<SopMainPage />} />
    </Routes>
  )
}
