import {Button} from '@progress/kendo-react-buttons'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import React, {useEffect, useState} from 'react'
import {HookFormNumericTextBox} from '../../../../../components/ReactHookForm/HookFormNumericTextBox'
import {useTranslation} from 'react-i18next'
import {
  AttachmentModel,
  IncidentModel,
  InvestigationModel,
  PersonalModel,
} from '../../../../../models/IncidentModel'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'
import axios from 'axios'
import * as Yup from 'yup'
import {HookFormInput} from '../../../../../components/ReactHookForm/HookFormInput'
import {useParams} from 'react-router-dom'
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload'

interface PopupImpactedPersonProps {
  title: string
  dataId: string
  incidentId: string
  onClose: () => void
  onSuccess: (data: any, responseData: any) => void
}

const fileStatuses = [
  'UploadFailed',
  'Initial',
  'Selected',
  'Uploading',
  'Uploaded',
  'RemoveFailed',
  'Removing',
]

export default function PopupAttachment(props: PopupImpactedPersonProps) {
  const {title, dataId, onClose, onSuccess, ...others} = props
  const {t} = useTranslation('translation')
  const [errorMessage, setErrorMessage] = useState('')
  const {incidentId} = useParams()

  const updateValidationSchema = Yup.object().shape({})

  const insertValidationSchema = Yup.object().shape({})

  const [files, setFiles] = useState<Array<UploadFileInfo>>([])
  const [events, setEvents] = useState<Array<any>>([])
  const [filePreviews, setFilePreviews] = useState<{[key: string]: string}>({})
  const [affectedFiles, setAffectedFiles] = useState<Array<UploadFileInfo>>([])

  const [isFileSelected, setIsFileSelected] = useState(false)

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<IncidentModel>({
    // resolver: yupResolver(
    //   mode === "add" ? insertValidationSchema : updateValidationSchema
    // ),
  })
  const {
    register: registerAtt,
    setValue: setValueAtt,
    handleSubmit: handleSubmitAtt,
    control: controlAtt,
    getValues: getValuesAtt,
    formState: {errors: err},
  } = useForm<AttachmentModel>({
    // resolver: yupResolver(
    //   mode === "add" ? insertValidationSchema : updateValidationSchema
    // ),
  })

  console.log('id att ', dataId)

  const onSubmit = handleSubmit((data: any) => {
    let url = '/api/Incident/AcctionPlan/Attachment/Add'
    console.log('Attach', data)

    data.incidentId = incidentId
    data.itemId = dataId

    if (isFileSelected) {
      axios
        .post(url, data)
        .then(async (response) => {
          console.log('response.data= ', response.data)
          const attachmentId = response.data.data.id
          console.log('atttachID =>', attachmentId)

          if (attachmentId) {
            // Upload image after item creation
            await uploadImage(attachmentId, files[0])
          } else {
            console.error('Item ID is undefined')
            // Handle the case where itemId is undefined
          }
          if (response.data.success) {
            if (onSuccess) {
              onSuccess(data, response.data.result)
              // let message = t("Data Has Been Created");
              // toast.success(message);
            }
          } else {
            setErrorMessage(response.data.errorMessage)
          }
        })
        .catch((error) => {
          console.log(error)

          setErrorMessage(t('FailedToSubmitToServer'))
        })
    } else {
      setErrorMessage(t('File cannot be empty'))
    }
  })

  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState)
    setEvents([...events, `File selected: ${event.affectedFiles[0].name}`])
    setAffectedFiles(event.affectedFiles)
    setIsFileSelected(true)

    event.affectedFiles.forEach((file: UploadFileInfo) => {
      const reader = new FileReader()

      reader.onloadend = (ev: any) => {
        setFilePreviews((prevState) => ({
          ...prevState,
          [file.name]: ev.target.result,
        }))
      }

      if (file && file.getRawFile) {
        reader.readAsDataURL(file.getRawFile())
      }
    })
  }

  const onRemove = (event: UploadOnRemoveEvent) => {
    let newFilePreviews = {...filePreviews}
    event.affectedFiles.forEach((file) => {
      delete newFilePreviews[file.uid]
    })

    setFiles(event.newState)
    setEvents([...events, `File removed: ${event.affectedFiles[0].name}`])
    setFilePreviews(newFilePreviews)

    if (event.newState.length === 0) {
      setIsFileSelected(false) // Set isFileSelected to false when all files are removed
    }
  }

  const onProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState)
    setEvents([...events, `On Progress: ${event.affectedFiles[0].progress} %`])
  }

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    const file = event.affectedFiles[0]
    setFiles(event.newState)
    setEvents([...events, `File '${file.name}' status changed to: ${fileStatuses[file.status]}`])
  }

  const onSaveRequest = (files: UploadFileInfo[]): Promise<{uid: string}> => {
    const currentFile = files[0] as UploadFileInfo
    const uid = currentFile.uid

    const saveRequestPromise = new Promise<{uid: string}>(async (resolve, reject) => {
      if (currentFile.validationErrors && currentFile.validationErrors.length > 0) {
        reject({uid: uid})
      } else {
        const formData = new FormData()
        formData.append('file', currentFile.getRawFile!())
        const attachId = getValuesAtt('id')

        try {
          const response = await axios.post(
            '/api/Incident/Report/Attachment/Upload?attachmentId=' + attachId,
            formData
          )

          resolve({uid: uid})
          // onSuccess(currentFile.name, response.data)
          // setDialogUpload(false)
          // refetch()
        } catch (err) {
          reject({uid: uid})
        }
      }
    })

    return saveRequestPromise
  }

  const uploadImage = async (attachmentId: string, file: UploadFileInfo) => {
    const formData = new FormData()
    formData.append('file', file.getRawFile!())

    try {
      const response = await axios.post(
        `/api/Incident/Report/Attachment/Upload?attachmentId=${attachmentId}`,
        formData
      )

      // Handle the response as needed
      // onSuccess(file.name, response.data)
    } catch (err) {
      // Handle the error as needed
      console.error('Image upload error:', err)
    }
  }

  return (
    <Dialog title={title} onClose={onClose} width='500px' height='auto'>
      <div className=''>
        <form id='formIncidentLosses' onSubmit={onSubmit}>
          <div className='k-form k-form-horizontal'>
            {errorMessage && (
              <div className={'k-messagebox k-messagebox-error'}>{errorMessage}</div>
            )}
            <Upload
              autoUpload={true}
              restrictions={{
                allowedExtensions: ['.jpg', '.jpeg', '.png'],
                maxFileSize: 10000000,
              }}
              multiple={false}
              batch={false}
              files={files}
              onAdd={onAdd}
              onRemove={onRemove}
              withCredentials={false}
              saveUrl={onSaveRequest}
              onStatusChange={onStatusChange}
              onProgress={onProgress}
            />
            <HookFormInput name={`description`} control={control} label={'Description'} />
          </div>
        </form>
      </div>
      <DialogActionsBar layout={'end'}>
        <Button
          className='btn-primary'
          type={'submit'}
          form={'formIncidentLosses'}
          style={{minWidth: 100}}
        >
          Upload
        </Button>
        {/* )} */}
        <Button className='btn-primary' onClick={onClose} style={{minWidth: 80}}>
          {t('Cancel')}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
