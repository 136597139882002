import { Link, Outlet } from "react-router-dom";
import { Content } from "../../../../_metronic/layout/components/Content";
import { Toolbar } from "../../../../_metronic/layout/components/toolbar/Toolbar";
import clsx from 'clsx'
import { PageActionSection, PageTitle, useLayout } from "../../../../_metronic/layout/core";
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid'
import GridLoadingPanel from "../../../components/Grid/GridLoadingPanel";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { State } from "@progress/kendo-data-query";
import { SearchTextBox } from "../../../components/Filters/SearchTextBox";
import { useTranslation } from "react-i18next";
import { ClickableGridCell } from "../../../components/Grid/ClickableGridCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFilePen, faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { CenteredColumnHeader } from "../../../components/Grid/CenteredColumnHeader";
import { useGetPrivilageGridData } from "./PrivilageApi";
import { Button } from "react-bootstrap";

export default function PrivilageListPage() {
   const { classes } = useLayout()
   const queryClient = useQueryClient()
   const { t } = useTranslation('translation')

   const [searchText, setSearchText] = useState<string>('')
   const [dataState, setDataState] = useState<State>({
      take: 10,
      skip: 0,
   })

   const { status, data, isFetching, isLoading, isFetched, refetch } = useGetPrivilageGridData({
      dataState: dataState,
      // fromDate: fromDate,
      // toDate: toDate,
      // status: filterStatus,
      searchText: searchText
   })

   function onSearchTextChange() {

   }

   function onGridStateChange(e: GridDataStateChangeEvent) {
      console.log(`onGridStateChange() skip: ${e.dataState.skip}`)
      //queryClient.invalidateQueries('vouchers')
      setDataState(e.dataState)
      //console.log('call refetch()..')
      //refetch()
   }

   function onNameClicked(dataItem: any) {
      console.log('Data item clicked: ' + dataItem.roleName);
   }

   const ClickableGridCell = (props: GridCellProps) => {
      return (
         <td className='py-5'>
            <Link to={props.dataItem.roleId} className='clickable-grid-cell text-hover-primary'>{props.dataItem.roleName}</Link>
            {/* <a className="clickable-grid-cell" href='#' onClick={() => onRoleNameClicked(props.dataItem)}>{props.dataItem.roleName}</a> */}
         </td>
      );
   }

   const actionCell = (props: GridCellProps) => {
      return (
         <td>
            <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
               <span
                  className='cursor-pointer'
                  // onClick={() => displayDetail(props.dataItem.discount_id)}
                  title={t('Edit')}
               >
                  <FontAwesomeIcon icon={faPencil} size='lg' />
               </span>
               <span
                  className='cursor-pointer'
                  // onClick={() => downloadDeliveryReceipt(props.dataItem)}
                  title={t('Delete')}
               >
                  <FontAwesomeIcon icon={faTrash} size='lg' />
               </span>
            </div>
         </td>
      )
   }

   return (
      <>
         <PageTitle>Privilages</PageTitle>
         {/* <PageActionSection>
            <div className="d-flex">
               <Button>Create</Button>
            </div>
         </PageActionSection> */}
         <div className="card mb-5 flex-column-fluid">
            <div className="card-body d-flex flex-column">
               <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
                  {/* <!-- left aligned controllers --> */}
                  <div className='d-flex align-items-center mb-3'>
                     <SearchTextBox onChange={onSearchTextChange} value={searchText}></SearchTextBox>
                  </div>
                  {/* <!-- right aligned controllers --> */}
                  <div className='d-flex align-items-center mb-3'>
                  </div>
               </div>
               <div id='gridContainer' className='w-100 flex-grow-1 overflow-hidden h-400px'>
                  <Grid
                     filterable={false}
                     sortable={true}
                     pageable={true}
                     scrollable='scrollable'
                     {...dataState}
                     data={data}
                     onDataStateChange={onGridStateChange}
                     // style={{ height: `${gridHeight}px` }}
                     //style={{ height: '100%' }}
                     className='h-100'
                  >
                     {/* <GridColumn field='discount_code' title={t('DiscountCode')} headerCell={CenteredColumHeader} width='200px' locked={true} /> */}
                     <GridColumn field='privilageCode' title='Privilage Code' width='300px' />
                     <GridColumn field='privilageName' title='Privilage Name' width='300px' />
                     <GridColumn field='description' title='Descrption' />
                     {/* <GridColumn
                        field='roleId'
                        title={t('Menu')}
                        width='100px'
                        cell={actionCell}
                        className='text-center'
                        headerCell={CenteredColumnHeader}
                        locked={false}
                     /> */}
                  </Grid>
                  {(isLoading || isFetching) && (
                     <GridLoadingPanel />
                  )}
               </div>
            </div>
         </div>
      </>
   )
}