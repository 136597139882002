import {PageActionSection, PageTitle, useLayout} from '../../../../_metronic/layout/core'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridHeaderCellProps,
} from '@progress/kendo-react-grid'
import {faEye, faFilePen, faPencil, faTrash} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from '@progress/kendo-react-buttons'
import {CenteredColumnHeader} from '../../../components/Grid/CenteredColumnHeader'
import DeleteDataDialog from '../../../components/Dialog/DeleteDataDialog'
import GridLoadingPanel from '../../../components/Grid/GridLoadingPanel'
import {SearchTextBox} from '../../../components/Filters/SearchTextBox'
import {useQueryClient} from 'react-query'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {State} from '@progress/kendo-data-query'
import {useGetIncidentGridData} from './IncidentApi'

import toast from 'react-hot-toast'
import {Link, useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import IncidentReportList from './pelaporan-awal/PelaporanAwalList'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import PelaporanAwalList from './pelaporan-awal/PelaporanAwalList'
import InvestigasiList from './investigation/InvestigationList'
import ProgressList from './progress/ProgressList'
import FinishList from './finish/FinishList'

export default function IncidentReportMain() {
  const intl = useIntl()

  const {t} = useTranslation('translation')
  const navigate = useNavigate()
  const [selected, setSelected] = useState(0)

  function addData() {
    navigate('/incident-report/new')
  }

  const handleSelect = (e: any) => {
    setSelected(e.selected)
  }

  return (
    <>
      <PageTitle>Incident Report</PageTitle>
      {/* <PageActionSection>
        <div className='d-flex'>
      
          <Button className='btn-primary-jotun-yellow' size='large' onClick={addData}>
            {t('Add')} {t('Incident')}
          </Button>
        </div>
      </PageActionSection> */}
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body d-flex flex-column'>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title={intl.formatMessage({id: 'Pelaporan Awal'})}>
              <PelaporanAwalList />
            </TabStripTab>
            <TabStripTab title={intl.formatMessage({id: 'Investigasi & Tindak Lanjut'})}>
              <InvestigasiList />
            </TabStripTab>
            <TabStripTab title={intl.formatMessage({id: 'Progress'})}>
              <ProgressList />
            </TabStripTab>
            <TabStripTab title={intl.formatMessage({id: 'Finish'})}>
              <FinishList />
            </TabStripTab>
          </TabStrip>
        </div>
      </div>
    </>
  )
}
