/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import {Button} from '@progress/kendo-react-buttons'
import toast from 'react-hot-toast'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {MyFormState} from '../../../../classes/MyFormState'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {HookFormInput} from '../../../../components/ReactHookForm/HookFormInput'
import {HookFormTextArea} from '../../../../components/ReactHookForm/HookFormTextArea'
import {
  ActionPlansModel,
  AttachmentModel,
  IncidentLossesModel,
  IncidentModel,
  InvestigationModel,
  PersonalModel,
  ProgressModel,
} from '../../../../models/IncidentModel'
import {
  useAddIncident,
  useGetIncidentData,
  useGetIncidentDataProgress,
  useSaveInvestigation,
  useSaveProgress,
  useUpdateIncident,
} from '../IncidentApi'
import {useNavigate, useParams} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import {HookFormDatePicker} from '../../../../components/ReactHookForm/HookFormDatePicker'
import {HookFormSwitch} from '../../../../components/ReactHookForm/HookFormSwitch'
import {HookFormUploadImage} from '../../../../components/ReactHookForm/HookFormUploadImage'
import {HookFormDropDownList} from '../../../../components/ReactHookForm/HookFormDropDownList'
import {HookFormNumericTextBox} from '../../../../components/ReactHookForm/HookFormNumericTextBox'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload'
import DeleteDataDialog from '../../../../components/Dialog/DeleteDataDialog'
import {Dialog} from '@progress/kendo-react-dialogs'
import axios from 'axios'
import {loadMessages, LocalizationProvider} from '@progress/kendo-react-intl'

import {useIntl} from 'react-intl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
  faImageLandscape,
  faImageSlash,
  faTrash,
  faUpload,
} from '@fortawesome/pro-regular-svg-icons'
import {Grid, GridCellProps, GridColumn} from '@progress/kendo-react-grid'
import {auto} from '@popperjs/core'
// import PopupIncidentLosses from './popup/PopupIncidentLosses'
import SubmitApproveRejectDataDialog from '../../../../components/Dialog/SubmitApproveDataDialog'
import {HookFormComboBoxWithRemoteData} from '../../../../components/ReactHookForm/HookFormComboBoxRemoteData'
import PopupAttachment from '../pelaporan-awal/popup/PopupAtachment'
import PopupAttachmentActionPlan from './popup/PopupAttachmentActionPlan'
import RejectDataDialog from '../../../../components/Dialog/RejectDataDialog'
import {Input} from '@progress/kendo-react-inputs'
import {CustomHeaderColumn} from '../../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn'
import {CenteredColumnHeader} from '../../../../components/Grid/CenteredColumnHeader'

export interface EstateFormProps {
  mode: 'view' | 'create' | 'delete'
  data?: ProgressModel
  dataId: string | number | undefined
}

export default function PelaporanForm(props: EstateFormProps) {
  const intl = useIntl()
  const {t} = useTranslation('translation')
  // const [formState, setFormState] = useState<MyFormState>('view')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  let {incidentId} = useParams()
  const {data, refetch} = useGetIncidentData(incidentId ?? '')
  // const {data, refetch} = useGetIncidentDataProgress(incidentId ?? '')

  const [dataInves, setDataInves] = useState<any[]>([])

  const [numForms, setNumForms] = useState(0)
  const [viewDialogVisible, setViewDialogVisible] = useState(false)
  const [viewImageUrl, setViewImageUrl] = useState('')
  const [dialogUpload, setDialogUpload] = useState(false)
  const [attId, setAttId] = useState('')
  const [listImages, setListImages] = useState([])
  const validationSchema = Yup.object().shape({})

  const [selected, setSelected] = useState(0)
  const handleSelect = (e: any) => {
    setSelected(e.selected)
  }

  const [files, setFiles] = useState<Array<UploadFileInfo>>([])
  const [events, setEvents] = useState<Array<any>>([])
  const [filePreviews, setFilePreviews] = useState<{[key: string]: string}>({})
  const [affectedFiles, setAffectedFiles] = useState<Array<UploadFileInfo>>([])

  const initialSubmitState = {
    visible: false,
    title: '',
    url: '',
  }
  const initialRejectState = {
    id: '',
    visible: false,
    title: '',
    url: '',
  }
  const [submitState, setSubmitState] = useState(initialSubmitState)
  const [rejectState, setRejectState] = useState(initialRejectState)

  const initialEditIncidentLosses = {
    visible: false,
    mode: '',
    title: '',
    dataId: '',
    incidentId: '',
  }
  const initialAddAttachment = {
    visible: false,
    title: '',
    dataId: '',
    incidentId: '',
  }

  const [editIncidentLosses, setEditIncidentLosses] = useState(initialEditIncidentLosses)
  const [addAttach, setAddAttach] = useState(initialAddAttachment)

  const initialDeleteState = {
    visible: false,
    title: '',
    url: '',
  }

  const fileStatuses = [
    'UploadFailed',
    'Initial',
    'Selected',
    'Uploading',
    'Uploaded',
    'RemoveFailed',
    'Removing',
  ]

  const defaultIncidentLosses: IncidentLossesModel = {
    id: '',
    incidentId: '',
    typeOfLoss: '',
    description: '',
    costOfLoss: 0,
  }
  const defaultActionPlans: ActionPlansModel = {
    id: '',
    incidentId: '',
    description: '',
    isCompleted: false,
    notes: '',
    progressPhotos: [
      {
        id: '',
        incidentId: '',
        attachmentType: '',
        itemId: '',
        description: '',
        imagePath: '',
        seqId: '',
        image: '',
        imageUrl: '',
      },
    ],
  }

  const defaultAttachmentData: AttachmentModel = {
    id: '',
    incidentId: ' ',
    attachmentType: ' ',
    description: ' ',
    imagePath: ' ',
    seqId: 0,
    image: ' ',
    imageUrl: ' ',
  }
  const [incidentLosses, setIncidentLosses] = useState<IncidentLossesModel[]>([
    defaultIncidentLosses,
  ])
  const [action, setAction] = useState<ActionPlansModel[]>([defaultActionPlans])
  const [actionId, setActionId] = useState('')

  const [attachmentData, setAttachmentData] = useState<AttachmentModel[]>([defaultAttachmentData])

  const [deleteState, setDeleteState] = useState(initialDeleteState)
  const [visibleReport, setVisibleReport] = useState(false)

  function createNewPersonalData(): PersonalModel {
    return {
      id: '',
      incidentId: '',
      name: '',
      gender: '',
      companyName: '',
      position: '',
      age: 0,
      address: '',
      estimatedLoss: 0,
    }
  }
  function createNewIncidentLossData(): IncidentLossesModel {
    return {
      id: '',
      incidentId: '',
      typeOfLoss: '',
      description: '',
      costOfLoss: 0,
    }
  }
  function createNewActionPlansData(): ActionPlansModel {
    return {
      id: '',
      incidentId: '',
      description: '',
      isCompleted: false,
      notes: '',
      progressPhotos: [
        {
          id: '',
          incidentId: '',
          attachmentType: '',
          itemId: '',
          description: '',
          imagePath: '',
          seqId: '',
          image: '',
          imageUrl: '',
        },
      ],
    }
  }

  function createNewAttachmentData(): AttachmentModel {
    return {
      id: '',
      incidentId: ' ',
      attachmentType: ' ',
      description: ' ',
      imagePath: ' ',
      seqId: 0,
      image: ' ',
      imageUrl: ' ',
    }
  }

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<ProgressModel>({
    resolver: yupResolver(validationSchema),
  })

  const saveInvestigation = useSaveProgress()
  // const updateIncident = useUpdateIncident()

  useEffect(() => {
    getInvestigationData()
    getListImages()

    affectedFiles
      .filter((file: UploadFileInfo) => !file.validationErrors)
      .forEach((file: UploadFileInfo) => {
        const reader = new FileReader()

        reader.onloadend = (ev: any) => {
          setFilePreviews({
            ...filePreviews,
            [file.uid]: ev.target.result,
          })
        }
        if (file && file.getRawFile) {
          reader.readAsDataURL(file.getRawFile())
        }
      })

    if (props.mode === 'create') {
      // setFormState('editing')
    } else {
      reset(props.data)
      if (props.data) {
        setAction(props.data?.actionPlans ?? [])
      }
    }
  }, [props.mode, props.data])

  const canEdit = useMemo(() => {
    return true
  }, [])

  function onHideReport() {
    setVisibleReport(!visibleReport)
  }

  function onEditClicked() {
    // setErrorMessage('')
    // setFormState('editing')
  }

  const getInvestigationData = async () => {
    const url = `api/Incident/Investigation/ActionPlan/GridData?incidentId=${incidentId}`
    try {
      const res = await axios.get(url)

      console.log('DATA INVES', res.data.data)
      setDataInves(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  function onCancelClicked() {
    if (props.mode === 'create') {
      navigate('/incident-report', {replace: true})
    } else {
      // setFormState('view')
    }
  }

  function onSaveProgressClicked() {
    console.log('onSaveClicked()..')

    handleSubmit(onSubmit)()
    refetch()
  }

  console.log('PREVIEW', filePreviews)

  const onSubmit = (dataProgress: ProgressModel) => {
    console.log('onSubmit() data progress = ', dataProgress)
    dataProgress.incidentId = incidentId

    saveInvestigation.mutate(dataProgress, {
      onSuccess: (response: ServiceResult<ProgressModel>) => {
        if (response.success) {
          let result = response.data!
          console.log('Add Estate success')
          let message = t('Add Data Successful')
          toast.success(message)
          navigate(`/incident-report/progress/${incidentId}`)
          refetch()
          // history.push(`/partner/${result.driverId}`)
        } else {
          console.log('Add Estate failed: ' + response.errorMessage)
          toast.error(response.errorMessage ?? 'Error')
        }
        // setFormState('view')
        // redirect to view route
      },
      onError: (data) => {
        console.log('Save data error')
        let message = 'Save data failed. Please check your network connection.'
        toast.error(message)
        // setFormState('editing')
        //setFormState('view')
      },
    })
  }

  function handleAddAttachmentClicked(data: any) {
    setAddAttach({
      visible: true,
      title: 'Attachment',
      dataId: data,
      incidentId: data.incidentId,
    })
  }

  function handleAddAttachmentSuccess() {
    let message = t('Add Attachment Data Successful')
    toast.success(message)
    setAddAttach({...addAttach, visible: false})
    refetch()
  }

  function handleFormClose() {
    setDeleteState({...deleteState, visible: false})
    setEditIncidentLosses({...editIncidentLosses, visible: false})
    setSubmitState({...submitState, visible: false})
    setAddAttach({...addAttach, visible: false})
    setRejectState({...rejectState, visible: false})
  }
  function handleFormDeleteSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    refetch()
  }

  function handleFormSubmitSuccess() {
    let message = t('Change Status Data Successful')
    toast.success(message)
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    refetch()
  }

  function handleDeleteAtt(data: any) {
    const attId = data.id
    console.log('ID FOTO', attId)

    setDeleteState({
      visible: true,
      title: 'Delete Attachment',
      url: '/api/Incident/Report/Attachment/Delete?attachmentId=' + attId,
    })
  }

  function onViewClicked(props: any) {
    setViewDialogVisible(true)
    setViewImageUrl(props)
    console.log(props)
  }

  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState)
    setEvents([...events, `File selected: ${event.affectedFiles[0].name}`])
    setAffectedFiles(event.affectedFiles)

    event.affectedFiles.forEach((file: UploadFileInfo) => {
      const reader = new FileReader()

      reader.onloadend = (ev: any) => {
        setFilePreviews((prevState) => ({
          ...prevState,
          [file.name]: ev.target.result,
        }))
      }

      if (file && file.getRawFile) {
        reader.readAsDataURL(file.getRawFile())
      }
    })
  }

  const onRemove = (event: UploadOnRemoveEvent) => {
    let newFilePreviews = {...filePreviews}
    event.affectedFiles.forEach((file) => {
      delete newFilePreviews[file.uid]
    })

    setFiles(event.newState)
    setEvents([...events, `File removed: ${event.affectedFiles[0].name}`])
    setFilePreviews(newFilePreviews)
  }

  const onProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState)
    setEvents([...events, `On Progress: ${event.affectedFiles[0].progress} %`])
  }

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    const file = event.affectedFiles[0]
    setFiles(event.newState)
    setEvents([...events, `File '${file.name}' status changed to: ${fileStatuses[file.status]}`])
  }

  const onSaveRequest = (files: UploadFileInfo[]): Promise<{uid: string}> => {
    const currentFile = files[0] as UploadFileInfo
    const uid = currentFile.uid

    // Find the corresponding attachmentId from attachmentData

    const attachmentId = attId
    console.log('attachmentId', attachmentId)

    if (!attachmentId) {
      return Promise.reject({uid: uid})
    }

    const saveRequestPromise = new Promise<{uid: string}>(async (resolve, reject) => {
      if (currentFile.validationErrors && currentFile.validationErrors.length > 0) {
        reject({uid: uid})
      } else {
        const formData = new FormData()
        formData.append('file', currentFile.getRawFile!())

        try {
          const response = await axios.post(
            '/api/Incident/AcctionPlan/Attachment/Upload?attachmentId=' + attachmentId,
            formData
          )

          resolve({uid: uid})
          // onSuccess(currentFile.name, response.data)
          setDialogUpload(false)
          refetch()
        } catch (err) {
          reject({uid: uid})
        }
      }
    })

    return saveRequestPromise
  }

  const labelWidth = '150px'
  // var isDisabled = formState !== 'editing'

  const actionCell = (props: GridCellProps) => {
    var incidentId = props.dataItem.id != undefined ? props.dataItem.id : ''
    return (
      <td>
        <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
          <span className='cursor-pointer' title={t('View')}>
            <FontAwesomeIcon
              icon={props.dataItem.imagePath !== null ? faImageLandscape : faImageSlash}
              size='lg'
              onClick={() => onViewClicked(props.dataItem.imageUrl)}
            />
          </span>
          <span
            className='cursor-pointer'
            onClick={() => handleDeleteAtt(props.dataItem)}
            title={t('Delete')}
          >
            <FontAwesomeIcon icon={faTrash} size='lg' />
          </span>
        </div>
      </td>
    )
  }

  const getListImages = async () => {
    try {
      const url = `/api/Incident/AcctionPlan/Attachment/List?incidentId=${incidentId}`
      const res = await axios.get(url)
      setListImages(res.data)
      console.log('dataa list imagess', res.data)
    } catch (error) {
      console.log(error)
    }
  }
  console.log('DATA LIST IMG', listImages)

  return (
    <>
      <hr />
      <div className='flex-column-fluid d-flex flex-column'>
        {/* toolbar */}

        <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between  mb-2 w-100'>
          <div className='d-flex align-items-center mt-3'>
            <h4>Progress</h4>
          </div>
          <div className='d-flex align-items-center mt-3'>
            <>
              <Button
                togglable={false}
                className='btn-primary-jotun-yellow w-80px'
                onClick={onSaveProgressClicked}
                // disabled={
                //   props.data?.progressStatus !== 'DRAFT' && props.data?.progressStatus !== 'NA'
                // }
              >
                {t('Save')}
              </Button>
            </>
          </div>
        </div>

        {/* {formState === 'saving' && (
            <>
              <Button type='button' togglable={false} className='btn-primary-jotun-yellow w-80px'>
                {t('Saving')}...
              </Button>
            </>
          )} */}
      </div>
      <div className='flex-column-fluid d-flex flex-column k-form k-form-horizontal'>
        {/* {formState === 'editing' && !!errorMessage && (
            <div className='alert alert-danger w-100' role='alert'>
              {errorMessage}
            </div>
          )} */}

        <form id='myForm' onSubmit={handleSubmit(onSubmit)}>
          <>
            <div className='card shadow m-3'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-12'>
                    <legend
                      className='page-title'
                      style={{
                        backgroundColor: '#1986c8',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      {'Action Plans'}
                    </legend>

                    <>
                      {dataInves && (
                        <>
                          {dataInves.map((act, index) => {
                            console.log(act, `ACTION ${index}`)

                            return (
                              <div key={index} className='pb-2'>
                                <div className='card shadow m-3'>
                                  <div className='card-body'>
                                    <div className='row'>
                                      <div className='w-100 d-flex flow-row flex-wrap gap-3'>
                                        <div className='col-8'>
                                          {/* <HookFormNumericTextBox
                                    name={`investigation.actionPlans[${index}].id`}
                                    control={control}
                                    label='id'
                                    labelWidth={labelWidth}
                                    // hideAsField
                                    // hidden
                                    // initialValue={incidentId}
                                  /> */}

                                          <HookFormInput
                                            name={`actionPlans[${index}].id`}
                                            control={control}
                                            label='Id'
                                            labelWidth={labelWidth}
                                            disabled
                                            hideAsField
                                            initialValue={act.id}
                                          />
                                          <HookFormInput
                                            name={`actionPlans[${index}].incidentId`}
                                            control={control}
                                            label='incidentId'
                                            labelWidth={labelWidth}
                                            disabled
                                            hideAsField
                                            initialValue={act.incidentId}
                                          />
                                          <HookFormInput
                                            name={`actionPlans[${index}].description`}
                                            control={control}
                                            label='Description'
                                            labelWidth={labelWidth}
                                            initialValue={act.description}
                                            disabled
                                          />

                                          <HookFormSwitch
                                            name={`actionPlans[${index}].isCompleted`}
                                            label='Complete?'
                                            control={control}
                                            labelWidth={labelWidth}
                                            initialValue={act.isCompleted}
                                          />

                                          <HookFormInput
                                            name={`actionPlans[${index}].notes`}
                                            control={control}
                                            label='Note'
                                            labelWidth={labelWidth}
                                            initialValue={act.notes}
                                          />
                                        </div>
                                        <div className='col-3'>
                                          {(data?.progressStatus === 'DRAFT' ||
                                            data?.progressStatus === 'NA') && (
                                            <Button
                                              type='button'
                                              className='mb-2'
                                              onClick={() => handleAddAttachmentClicked(act.id)}
                                            >
                                              Add Attachment
                                            </Button>
                                          )}
                                          <IncidentGrid
                                            incidentId={act.incidentId}
                                            act={act}
                                            actionCell={actionCell}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='w-100 d-flex flow-row flex-wrap'>
                                      {/* {loss.progressPhotos.map((foto, idx) => {
                                              console.log('descccc fotoo', foto.description)

                                              return (
                                                <div className='col-xl-4 col-md-6 col-sm-12 d-flex flex-column align-items-center'>
                                                  <img
                                                    className='mt-2 p-1'
                                                    src={foto.imageUrl}
                                                    width={255}
                                                    height={255}
                                                    alt=''
                                                  />
                                                  <br />
                                                  <HookFormTextArea
                                                    name={`progress.actionPlans[${index}].progressPhotos[${idx}.description]`}
                                                    control={control}
                                                    label='Description'
                                                  />{' '}
                                                </div>
                                              )
                                            })} */}
                                    </div>
                                  </div>
                                  {/* {index > 0 && (
                                          <Button
                                            togglable={false}
                                            type='button'
                                            className='btn btn-secondary btn-sm mt-3'
                                            onClick={() => onDeletePersonClicked(index)}
                                            // disabled={isDisabled}
                                          >
                                            {intl.formatMessage({id: 'TEXT.DELETE'})}
                                          </Button>
                                        )} */}
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                      {/* <Button
                              togglable={false}
                              type='button'
                              className='btn btn-primary btn-sm mt-3'
                              onClick={onAddActionPlansClicked}
                            >
                              {intl.formatMessage({id: 'TEXT.ADD'})}
                            </Button> */}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </>
        </form>
      </div>
      {deleteState.visible && (
        <DeleteDataDialog
          title={deleteState.title}
          url={deleteState.url}
          onClose={handleFormClose}
          onSuccess={handleFormDeleteSuccess}
        />
      )}
      {addAttach.visible && (
        <PopupAttachmentActionPlan
          title={addAttach.title}
          onClose={handleFormClose}
          onSuccess={handleAddAttachmentSuccess}
          dataId={addAttach.dataId}
          incidentId={addAttach.incidentId}
        />
      )}
      {viewDialogVisible && (
        <Dialog title='View Image' onClose={() => setViewDialogVisible(false)} height={500}>
          <img src={viewImageUrl} alt='' height={450} />
        </Dialog>
      )}
      {submitState.visible && (
        <SubmitApproveRejectDataDialog
          title={submitState.title}
          url={submitState.url}
          onClose={handleFormClose}
          onSuccess={handleFormSubmitSuccess}
        />
      )}

      {rejectState.visible && (
        <RejectDataDialog
          id={rejectState.id}
          title={rejectState.title}
          url={rejectState.url}
          onClose={handleFormClose}
          onSuccess={handleFormSubmitSuccess}
        />
      )}

      {dialogUpload && (
        <Dialog onClose={() => setDialogUpload(false)} title={'Upload'}>
          <Upload
            autoUpload={false}
            restrictions={{
              allowedExtensions: ['.jpg', '.jpeg', '.png'],
              maxFileSize: 1000000,
            }}
            multiple={true}
            batch={false}
            files={files}
            onAdd={onAdd}
            onRemove={onRemove}
            withCredentials={false}
            saveUrl={onSaveRequest}
            onStatusChange={onStatusChange}
            onProgress={onProgress}
          />
        </Dialog>
      )}

      {/* {editIncidentLosses.visible && (
          <PopupIncidentLosses
            title={editIncidentLosses.title}
            mode={editIncidentLosses.mode}
            dataId={editIncidentLosses.dataId}
            incidentId={editIncidentLosses.incidentId}
            onClose={handleFormClose}
            onSuccess={handleFormDeleteSuccess}
          />
        )} */}
    </>
  )
}

interface Act {
  id: number
  incidentId: number
  description: string
  isCompleted: boolean
  notes: string
  // Add other fields as necessary
}

interface IncidentGridProps {
  incidentId: number
  act: Act
  actionCell: any
}
const IncidentGrid: React.FC<IncidentGridProps> = ({incidentId, act, actionCell}) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/Incident/AcctionPlan/Attachment/List?incidentId=${incidentId}&actionPlanId=${act.id}`
        )
        setData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [incidentId, act.id, data])

  return (
    <Grid data={data}>
      <GridColumn field='description' title='Description' />
      <GridColumn
        field=''
        title='action'
        width={80}
        cell={actionCell}
        headerCell={CenteredColumnHeader}
      />
    </Grid>
  )
}
