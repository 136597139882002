import {DataResult, State, toDataSourceRequestString} from '@progress/kendo-data-query'
import {formatDate} from '@telerik/kendo-intl'
import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Item1} from '../../../../_metronic/partials/content/activity/Item1'
import {ServiceResult} from '../../../classes/ServiceResult'
import {IncidentModel, InvestigationModel, ProgressModel} from '../../../models/IncidentModel'
import {useParams} from 'react-router-dom'

interface GetIncidentGridDataParam {
  dataState: State
  searchText: string | null
}

// Get Grid Data..
const getIncidentGridData = async (params: GetIncidentGridDataParam) => {
  //console.log(`Call getVoucherGridData().. skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `api/Incident/GridData?${queryString}`
  const data = {
    searchText: params.searchText,
  }
  console.log(`getIncidentGridData() queryString=${queryString}, searchText=${data.searchText}`)
  const response = await axios.get<DataResult>(url, {params: data})
  console.log('response received')
  return response.data
}

function useGetIncidentGridData(params: GetIncidentGridDataParam) {
  console.log(`useGetIncidentGridData() skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(['privilages', params], () => getIncidentGridData(params), {
    // enabled: false
  })
}

// Get Grid Data..
const getLossGridData = async (params: GetIncidentGridDataParam) => {
  //console.log(`Call getVoucherGridData().. skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `/api/Incident/Investigation/Loss/GridData?${queryString}`
  const data = {
    searchText: params.searchText,
  }
  console.log(`getIncidentGridData() queryString=${queryString}, searchText=${data.searchText}`)
  const response = await axios.get<DataResult>(url, {params: data})
  console.log('response received')
  return response.data
}

function useGetLossGridData(params: GetIncidentGridDataParam) {
  console.log(`useGetIncidentGridData() skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(['privilages', params], () => getLossGridData(params), {
    // enabled: false
  })
}

const getInvestigationGridData = async (params: GetIncidentGridDataParam) => {
  //console.log(`Call getVoucherGridData().. skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `api/Incident/GridData?${queryString}`
  const data = {
    searchText: params.searchText,
  }
  console.log(`getIncidentGridData() queryString=${queryString}, searchText=${data.searchText}`)
  const response = await axios.get<DataResult>(url, {params: data})
  console.log('response received')
  let newData = []
  for (let item of response.data.data) {
    // Memeriksa apakah investigationStatus bukan 'NA'
    if (item.investigationStatus !== 'NA') {
      newData.push(item)
    }
  }

  // Mengembalikan DataResult dengan data yang sudah difilter
  const newDataResult: DataResult = {...response.data, data: newData}
  return newDataResult
}

function useGetInvestigationGridData(params: GetIncidentGridDataParam) {
  console.log(`useGetIncidentGridData() skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(
    ['privilages', params],
    () => getInvestigationGridData(params),
    {
      // enabled: false
    }
  )
}

// Get Detail data
const getIncidentData = async (id: string) => {
  const url = `/api/Incident/${id}`
  const response = await axios.get<IncidentModel>(url)
  var data = response.data
  return data
}

const useGetIncidentData = (id: string) => {
  return useQuery<IncidentModel, Error>(
    ['privilage', id],
    async () => {
      return await getIncidentData(id)
    },
    {
      onSuccess: (data: IncidentModel) => {},
    }
  )
}
// Get Detail data
const getIncidentDataInves = async (id: string) => {
  const url = `/api/Incident/${id}`
  const response = await axios.get<InvestigationModel>(url)
  var data = response.data
  return data
}

const useGetIncidentDataInves = (id: string) => {
  return useQuery<InvestigationModel, Error>(
    ['privilage', id],
    async () => {
      return await getIncidentDataInves(id)
    },
    {
      onSuccess: (data: InvestigationModel) => {},
    }
  )
}
const getIncidentDataProgress = async (id: string) => {
  const url = `/api/Incident/${id}`
  const response = await axios.get<ProgressModel>(url)
  var data = response.data
  return data
}

const useGetIncidentDataProgress = (id: string) => {
  return useQuery<ProgressModel, Error>(
    ['privilage', id],
    async () => {
      return await getIncidentDataProgress(id)
    },
    {
      onSuccess: (data: ProgressModel) => {},
    }
  )
}

// Add Data
const callAddIncident = async (data: IncidentModel) => {
  const url = '/api/Incident/Create'
  console.log('URL: ', url)
  const response = await axios.post<ServiceResult<IncidentModel>>(url, data)
  console.log('Response: ', response)
  return response.data
}

const useAddIncident = () => {
  const queryClient = useQueryClient()
  return useMutation((data: IncidentModel) => callAddIncident(data), {
    onSuccess: (response: ServiceResult<IncidentModel>) => {
      queryClient.invalidateQueries(['Incidents'])
    },
  })
}

const callSaveInvestigation = async (data: InvestigationModel) => {
  const url = '/api/Incident/Investigation/Save'
  console.log('URL: ', url)
  const response = await axios.post<ServiceResult<InvestigationModel>>(url, data)
  console.log('Response: ', response)
  return response.data
}

const useSaveInvestigation = () => {
  const queryClient = useQueryClient()
  return useMutation((data: InvestigationModel) => callSaveInvestigation(data), {
    onSuccess: (response: ServiceResult<InvestigationModel>) => {
      queryClient.invalidateQueries(['Incidents'])
    },
  })
}
const callSaveProgress = async (data: ProgressModel) => {
  const url = '/api/Incident/Progress/Save'
  console.log('URL: ', url)
  const response = await axios.post<ServiceResult<ProgressModel>>(url, data)
  console.log('Response: ', response)
  return response.data
}

const useSaveProgress = () => {
  const queryClient = useQueryClient()
  return useMutation((data: ProgressModel) => callSaveProgress(data), {
    onSuccess: (response: ServiceResult<ProgressModel>) => {
      queryClient.invalidateQueries(['Incidents'])
    },
  })
}

// Update Data
const callUpdateIncident = async (data: IncidentModel) => {
  console.log('callUpdateIncident()..')
  const url = 'api/Incident/update'
  const response = await axios.post<ServiceResult<IncidentModel>>(url, data)
  return response.data
}

const useUpdateIncident = () => {
  const queryClient = useQueryClient()
  return useMutation((data: IncidentModel) => callUpdateIncident(data), {
    onSuccess: (response: ServiceResult<IncidentModel>) => {
      queryClient.invalidateQueries(['Incidents'])
    },
  })
}

export {
  useGetIncidentGridData,
  useGetInvestigationGridData,
  useGetIncidentData,
  useAddIncident,
  useUpdateIncident,
  useSaveInvestigation,
  useGetIncidentDataInves,
  useGetIncidentDataProgress,
  useSaveProgress,
  useGetLossGridData,
}
