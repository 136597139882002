import {Route, Routes} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import UserCreatePage from './UserCreatePage'
import UserDetailPage from './UserDetailPage'
import UserListPage from './UserListPage'

export default function UsersPage() {
  const {currentUser} = useAuth()
  const stringObj = JSON.stringify(currentUser)
  const arryObj = JSON.parse(stringObj)
  console.log('UsersPage', arryObj)

  return (
    <Routes>
      <Route path=':userId/*' element={<UserDetailPage />} />
      <Route path='new' element={<UserCreatePage />} />
      <Route index element={<UserListPage />} />
    </Routes>
  )
}
