import {DataResult, State, toDataSourceRequestString} from '@progress/kendo-data-query'
import {formatDate} from '@telerik/kendo-intl'
import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Item1} from '../../../../../_metronic/partials/content/activity/Item1'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {UserModel} from '../../../../models/UserModel'

interface GetUserGridDataParam {
  dataState: State
  searchText: string | null
}

// Get Grid Data..
const getUserGridData = async (params: GetUserGridDataParam) => {
  //console.log(`Call getVoucherGridData().. skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `/api/user/GridData?${queryString}`

  const data = {
    searchText: params.searchText,
  }
  console.log(`getSiteGridData() queryString=${queryString}, searchText=${data.searchText}`)
  const response = await axios.get<DataResult>(url, {params: data})
  console.log('response received')
  console.log('Test :' + data)
  return response.data
}

function useGetUserGridData(params: GetUserGridDataParam) {
  console.log(`useGetUserGridData() skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(['privilages', params], () => getUserGridData(params), {
    // enabled: false
  })
}

// Get Detail data =======================
const getUserData = async (id: string) => {
  const url = `/api/User/${id}`
  const response = await axios.get<UserModel>(url)
  var data = response.data
  return data
}

const useGetUserData = (id: string) => {
  return useQuery<UserModel, Error>(
    ['privilage', id],
    async () => {
      return await getUserData(id)
    },
    {
      onSuccess: (data: UserModel) => {
        //console.log("Test3 :" + data)
      },
    }
  )
}

const getRoleData = async (roleId: string) => {
  const url = `/api/Role/${roleId}`
  const response = await axios.get(url)
  return response.data
}

const useGetRoleData = (roleId: string) => {
  return useQuery(['role', roleId], () => getRoleData(roleId))
}

// Add Data ====================================
const callAddUser = async (data: UserModel) => {
  const url = '/api/User/Add'
  console.log('URL: ', url)
  const response = await axios.post<ServiceResult<UserModel>>(url, data)
  console.log('Response: ', response)
  return response.data
}

const useAddUser = () => {
  const queryClient = useQueryClient()
  return useMutation((data: UserModel) => callAddUser(data), {
    onSuccess: (response: ServiceResult<UserModel>) => {
      queryClient.invalidateQueries(['users'])
    },
  })
}

// Update Data ====================================
const callUpdateUser = async (data: UserModel) => {
  console.log('callUpdateUser()..')
  const url = '/api/User/Update'
  const response = await axios.post<ServiceResult<UserModel>>(url, data)
  return response.data
}

const useUpdateUser = () => {
  const queryClient = useQueryClient()
  return useMutation((data: UserModel) => callUpdateUser(data), {
    onSuccess: (response: ServiceResult<UserModel>) => {
      queryClient.invalidateQueries(['users'])
    },
  })
}

export {useGetUserGridData, useGetUserData, useAddUser, useUpdateUser, useGetRoleData}
