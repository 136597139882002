import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import usePrivilege from './usePrivilege'

export function MenuInner() {
  const intl = useIntl()
  const {hasPrivilege: havePrivilege} = usePrivilege()
  console.log('havePrivilege', havePrivilege('dashboard'))
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.OPERATION'})}
        to='#'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/sop' title={intl.formatMessage({id: 'SUBMENU.SOP'})} hasBullet={true} />
        <MenuItem
          to='/hazard'
          title={intl.formatMessage({id: 'SUBMENU.HAZARD'})}
          hasBullet={true}
        />
        <MenuItem
          to='/permittowork'
          title={intl.formatMessage({id: 'SUBMENU.PERMIT'})}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.SAFETY'})}
        to='/safety'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        {/* PAGES */}

        <MenuItem
          to='/work-inspection'
          title={intl.formatMessage({id: 'SUBMENU.INSPECTION'})}
          hasBullet={true}
        />
        <MenuItem
          to='/incident-report'
          title={intl.formatMessage({id: 'SUBMENU.INCIDENT'})}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.RESOURCES'})}
        to='/safety'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        {/* PAGES */}
        <MenuItem to='#' title='APD Management' hasBullet={true} />
        <MenuItem to='#' title='Vendor Management' hasBullet={true} />
        <MenuItem to='#' title='Training Management' hasBullet={true} />
      </MenuInnerWithSub>{' '}
      <MenuItem title={intl.formatMessage({id: 'MENU.CALENDAR'})} to='#' />
      <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.ADMINISTRATION'})}
        to='/administration'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        {/* PAGES */}
        <MenuItem to='#' title='Company Profile' hasBullet={true} />
        <MenuItem to='#' title='Site Management' hasBullet={true} />
        <MenuItem to='#' title='Job Management' hasBullet={true} />
        <MenuInnerWithSub
          title='User Management'
          to='/pages/account'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          hasBullet={true}
          hasArrow={true}
        >
          <MenuItem to='/user' title='User' hasBullet={true} />
          <MenuItem to='/role' title='Role' hasBullet={true} />
          <MenuItem to='/permission' title='Permission' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem to='#' title='Document Management' hasBullet={true} />
        <MenuItem to='#' title='Hazard Identification Template Management' hasBullet={true} />
        <MenuItem to='#' title='Permit to Work Template Management' hasBullet={true} />
        <MenuItem to='/inspectiontemplate' title='Inspection Form Template' hasBullet={true} />
        <MenuItem to='#' title='Vendor Management' hasBullet={true} />
      </MenuInnerWithSub>
      {havePrivilege('dashboard') && <MenuItem title='Dashboard' to='/dashboard' />}
      {havePrivilege('map') && <MenuItem title='Map' to='/map' />}{' '}
      {havePrivilege('report') && <MenuItem title='Report' to='/report' />}
      {/* Administration */}
      {havePrivilege('administration') && (
        <MenuInnerWithSub
          title='Administration'
          to='/administration'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {/* PAGES */}
          {havePrivilege('account') && (
            <MenuInnerWithSub
              title='Account'
              to='/pages/account'
              hasArrow={true}
              hasBullet={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              {havePrivilege('user') && <MenuItem to='/user' title='Users' hasBullet={true} />}
              {havePrivilege('role') && <MenuItem to='/role' title='Roles' hasBullet={true} />}
              {havePrivilege('permission') && (
                <MenuItem to='/permission' title='Permissions' hasBullet={true} />
              )}
              {havePrivilege('site') && <MenuItem to='/site' title='Sites' hasBullet={true} />}
            </MenuInnerWithSub>
          )}
        </MenuInnerWithSub>
      )}
    </>
  )
}
