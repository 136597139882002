import { Configuration, PopupRequest } from "@azure/msal-browser";

const APP_URL = process.env.REACT_APP_APP_URL
const AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID!
const AAD_TENANT_ID = process.env.REACT_APP_AAD_TENANT_ID!

// ref: https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: AAD_CLIENT_ID,
        authority: "https://login.microsoftonline.com/" + AAD_TENANT_ID,
        redirectUri: APP_URL,
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    // scopes: [AAD_CLIENT_ID, "User.Read"]
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
