import {Button} from '@progress/kendo-react-buttons'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {PageActionSection, PageTitle} from '../../../../../_metronic/layout/core'
import {useGetUserData} from './UserApi'
import UserForm from './UserForm'

export default function UserDetailPage() {
  const {t} = useTranslation('translation')
  let {userId} = useParams()
  const navigate = useNavigate()

  const {data} = useGetUserData(userId ?? '')

  function onBackClicked() {
    navigate('/user', {replace: true})
  }

  return (
    <>
      <PageTitle>{data?.userName}</PageTitle>
      <PageActionSection>
        <div className='d-flex'>
          <Button className='btn-primary-jotun-yellow' size='large' onClick={onBackClicked}>
            {t('Back')}
          </Button>
        </div>
      </PageActionSection>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body pt-9 pb-0 d-flex flex-column'>
          <UserForm mode='view' data={data} dataId='' />
        </div>
      </div>
    </>
  )
}
