import { Route, Routes } from "react-router-dom";
import  PermissionListPage  from "./PermissionListPage"
import PermissionDetailPage from "./PermissionDetailPage";
import PermissionCreatePage from "./PermissionCreatePage";

export default function PermissionPage() {
    return (
        <Routes>
            <Route path=':permissionId/*' element={<PermissionDetailPage  />} />
            <Route path='new' element={<PermissionCreatePage />} />
            <Route index element = {< PermissionListPage />} />
        </Routes>
    )

}