import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SearchTextBoxProps {
   onChange: (value: string) => void,
   value: string,
   width?: string,
}

export function SearchTextBox(props: SearchTextBoxProps) {
   const [searchText, setSearchText] = useState('')
   const { t } = useTranslation('translation')

   useEffect(() => {
      setSearchText(props.value)
   },[props.value])

   const handleChange = (event: InputChangeEvent) => {
      setSearchText(event.value)
      //console.log('Changed: Value = ' + event.value)
   }

   const handleKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
         console.log('Enter key pressed!')
         triggerChange(searchText)
      }
   }

   const handleClearClick = () => {
      setSearchText('')
      console.log('Clear clicked')
      triggerChange('')
   }

   const handleSearchClick = () => {
      console.log('Search clicked')
      triggerChange(searchText)
   }

   function triggerChange(value: string) {
      if (props.onChange) props.onChange(value)
   }

   let width = props.width ?? '250px'

   return (
      <div className={`k-textbox k-grid-search d-flex flex-row`} style={{ width: width }}>
         <Input
            id='searchText'
            value={searchText}
            autoComplete='off'
            placeholder={t('Search') + '..'}
            title={t('Search') + '..'}
            className='k-input flex-grow-1'
            onChange={handleChange}
            onKeyPress={handleKeypress}
         />
         {searchText && (
            // <span className='k-input-icon clear-search w-30px'>
            //     <a className='fa fa-times' onClick={handleClearClick} href='#'></a>
            // </span>
            <Button fillMode={'flat'} className='w-24px' onClick={handleClearClick}>
               <FontAwesomeIcon icon={faTimes} />
            </Button>
         )}
         <Button fillMode={'flat'} className='w-24px' onClick={handleSearchClick}>
            <FontAwesomeIcon icon={faSearch} />
         </Button>
         {/* <span className='k-input-icon pe-auto w-30px'>
                <a className='fa fa-search' onClick={handleSearchClick} href='#'></a>
            </span> */}
      </div>
   )
}
