import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  ChartSeriesLabels,
  ChartAxisDefaults,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts'
import {State} from '@progress/kendo-data-query'
import CSS from 'csstype'
import {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns'
import axios from 'axios'
import moment from 'moment'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {DatePicker, DateTimePicker} from '@progress/kendo-react-dateinputs'
import {DropDownListWithRemoteData} from '../../components/Dropdowns/DropDownListWithRemoteData'

export default function DashboardHome() {
  const [companyId, setCompanyId] = useState<string | null>('')
  const [varietyId, setVarietyId] = useState<string | null>('')
  const [estateId, setEstateId] = useState<string | null>('')
  const [searchText, setSearchText] = useState<string>('')
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  })

  const intl = useIntl()
  const Series = [
    {
      seed: 500,
      nonSeed: 175,
      normal: 500,
      type1: 50,
      type2: 25,
      type3: 45,
      type4: 35,
      date: '1-Jan',
    },
    {
      seed: 350,
      nonSeed: 100,
      normal: 510,
      type1: 40,
      type2: 15,
      type3: 25,
      type4: 35,
      date: '2-Jan',
    },
    {
      seed: 450,
      nonSeed: 125,
      normal: 525,
      type1: 25,
      type2: 15,
      type3: 35,
      type4: 45,
      date: '3-Jan',
    },
    {
      seed: 550,
      nonSeed: 135,
      normal: 540,
      type1: 15,
      type2: 20,
      type3: 45,
      type4: 35,
      date: '4-Jan',
    },
  ]

  const seriesLabels = {
    visible: true,
    padding: 0,
    font: '10px Arial, sans-serif',
  }

  const labels: CSS.Properties = {
    width: '60px',
    textAlign: 'right',
    marginRight: '5px',
  }

  const border: CSS.Properties = {
    backgroundColor: '#E6E6E6',
    border: '2px solid #B3B3B3',
    borderRadius: '5px',
  }

  const icon: CSS.Properties = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#002664',
    justifyContent: 'center',
    paddingLeft: '5px',
    paddingTop: '5px',
    textAlign: 'center',
  }

  const number: CSS.Properties = {
    fontSize: '2rem',
    textAlign: 'center',
    fontWeight: 'bold',
    font: 'sans-serif',
  }

  const desc: CSS.Properties = {
    textAlign: 'center',
    fontSize: '1rem',
    font: 'sans-serif',
  }

  const labelDesc: CSS.Properties = {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    font: 'sans-serif',
    color: '#ffffff',
  }

  const {currentUser} = useAuth()
  const stringObj = JSON.stringify(currentUser)
  const arryObj = JSON.parse(stringObj)

  async function onFilterCompanyIdChange(value: string | null) {
    console.log('onFilterSiteIdChange() value = ' + value)
    setCompanyId(value)
    setDataState({...dataState, skip: 0})
  }
  async function onFilterVarietyChange(value: string | null) {
    console.log('onFilterSiteIdChange() value = ' + value)
    setVarietyId(value)
    setDataState({...dataState, skip: 0})
  }
  async function onFilterEstateIdChange(value: string | null) {
    console.log('onFilterSiteIdChange() value = ' + value)
    setEstateId(value)
    setDataState({...dataState, skip: 0})
  }

  const defaultStartValue = new Date()
  defaultStartValue.setMonth(defaultStartValue.getMonth() - 1)
  const defaultEndValue = new Date()

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <PageTitle>Home Dashboard</PageTitle>
        </div>

        <div className='row col-xl-12 justify-content-end text-lg-end text-sm-start text-white mb-5 mx-0'>
          <div className='col-lg-4 col-sm-12'>
            <div className='row'>
              <span className='col-3 '>Periode :</span>
              <DatePicker
                className='col-sm-12 col-lg-4 g-0 me-3'
                defaultValue={defaultStartValue}
                format={'dd/MMM/yyyy'}
              />

              <DatePicker
                className='col-sm-12 col-lg-4 g-0'
                defaultValue={defaultEndValue}
                format={'dd/MMM/yyyy'}
              />
            </div>
          </div>
          <div className='col-lg-3 col-sm-12 ms-xl-n20'>
            <div className='row'>
              <span className='col pl-3'>Company :</span>
              <DropDownListWithRemoteData
                className='col-sm-12 col-lg-6 g-0'
                onValueChange={onFilterCompanyIdChange}
                dataUrl='/api/Company/List'
                addAllAsDefault={true}
                value={companyId}
                autoSelect={true}
              />
            </div>
          </div>

          <div className='col-lg-2 col-sm-12 ms-xl-n8'>
            <div className='row'>
              <div className='col-sm-12 col-lg-6'>Estate :</div>
              <DropDownListWithRemoteData
                className='col-sm-12 col-lg-6 g-0'
                onValueChange={onFilterEstateIdChange}
                dataUrl='/api/Estate/List'
                addAllAsDefault={true}
                value={estateId}
                autoSelect={true}
              />
            </div>
          </div>

          <div className='col-lg-2 col-sm-12 ms-xl-n8'>
            <div className='row'>
              <span className='col-sm-12 col-lg-6'>Variety :</span>
              <DropDownListWithRemoteData
                className='col-sm-12 col-lg-6 g-0 '
                onValueChange={onFilterVarietyChange}
                dataUrl='/api/Variety/List'
                addAllAsDefault={true}
                value={varietyId}
                autoSelect={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col-6'>
                <div style={icon}>
                  <img src={toAbsoluteUrl('/media/logos/Palm.svg')} className='h-50px' />
                </div>
              </div>
              <div className='col-6' style={{color: 'green'}}>
                <div style={number}>
                  <span style={{color: 'green'}}>0</span>
                </div>
                <div style={desc}>Total Tree</div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col-6'>
                <div style={icon}>
                  <img src={toAbsoluteUrl('/media/logos/Seed-Palm.svg')} className='h-50px' />
                </div>
              </div>
              <div className='col-6' style={{color: 'orange'}}>
                <div style={number}>{0}</div>
                <div style={desc}>Total Seed</div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col-6'>
                <div style={icon}>
                  <img src={toAbsoluteUrl('/media/logos/circle-check.svg')} className='h-50px' />
                </div>
              </div>
              <div className='col-6' style={{color: 'blue'}}>
                <div style={number}>{0}</div>
                <div style={desc}>Total Active</div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col-6'>
                <div style={icon}>
                  <img src={toAbsoluteUrl('/media/logos/circle-xmark.svg')} className='h-50px' />
                </div>
              </div>
              <div className='col-6' style={{color: 'brown'}}>
                <div style={number}>{0}</div>
                <div style={desc}>Total Not Active</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row g-5 g-xl-8 mt-0'>
        <div className='col-xl-6'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col-12'>
                <Chart
                  style={{
                    height: 350,
                  }}
                >
                  <ChartTitle text='Tree Information' />
                  <ChartLegend position='bottom' orientation='horizontal' />
                  <ChartSeries>
                    <ChartSeriesItem
                      name='Mature'
                      type='column'
                      categoryField='date'
                      data={Series}
                      field='seed'
                      color={'#002664'}
                      labels={seriesLabels}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Immature'
                      type='column'
                      categoryField='date'
                      data={Series}
                      field='nonSeed'
                      color={'#F0AB00'}
                      labels={seriesLabels}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col=12'>
                <Chart
                  style={{
                    height: 350,
                  }}
                >
                  <ChartTitle text='Tree Condition' />
                  <ChartLegend position='bottom' orientation='horizontal' />
                  <ChartSeries>
                    <ChartSeriesItem
                      name='Normal'
                      type='line'
                      categoryField='date'
                      data={Series}
                      field='normal'
                      color={'#329E36'}
                      labels={seriesLabels}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type1'
                      type='line'
                      categoryField='date'
                      data={Series}
                      field='type1'
                      color={'#0d6efd'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type2'
                      type='line'
                      categoryField='date'
                      data={Series}
                      field='type2'
                      color={'#6f42c1'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type3'
                      type='line'
                      categoryField='date'
                      data={Series}
                      field='type3'
                      color={'#F0AB00'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-6'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col-12'>
                <Chart
                  style={{
                    height: 350,
                  }}
                >
                  <ChartTitle text='Sample Chart 1' />
                  <ChartLegend position='right' orientation='vertical' />
                  <ChartSeries>
                    <ChartSeriesItem
                      type='pie'
                      categoryField='date'
                      data={Series}
                      field='seed'
                      color={['#ff0000', '#00ff00', '#0000ff', '#ffff00']}
                      labels={seriesLabels}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-6'>
          <div className='card'>
            <div className='row m-2'>
              <div className='col=12'>
                <Chart
                  style={{
                    height: 350,
                  }}
                >
                  <ChartTitle text='Sample Chart 2' />
                  <ChartLegend position='bottom' orientation='horizontal' />
                  <ChartSeries>
                    <ChartSeriesItem
                      name='Type1'
                      type='bar'
                      categoryField='date'
                      data={Series}
                      field='type1'
                      color={'#0d6efd'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type2'
                      type='bar'
                      categoryField='date'
                      data={Series}
                      field='type2'
                      color={'#6f42c1'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                    <ChartSeriesItem
                      name='Type3'
                      type='bar'
                      categoryField='date'
                      data={Series}
                      field='type3'
                      color={'#F0AB00'}
                      labels={seriesLabels}
                      stack={{group: 'a'}}
                    >
                      <ChartSeriesLabels position={'top'} />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
