import {PageActionSection, PageTitle} from '../../../../../_metronic/layout/core'
import PelaporanAwalForm from './PelaporanAwalForm'

export default function PelaporanCreatePage() {
  return (
    <>
      <PageTitle>Add Incident</PageTitle>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body pt-9 pb-0 d-flex flex-column'>
          <PelaporanAwalForm mode='create' />
        </div>
      </div>
    </>
  )
}
