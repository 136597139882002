import {Controller, ControllerProps, FieldError, FieldPath, FieldValues} from 'react-hook-form'
import {Label, Error, Hint, FloatingLabel} from '@progress/kendo-react-labels'
import {
  Input,
  InputProps,
  NumericTextBox,
  NumericTextBoxProps,
  TextArea,
  TextAreaProps,
} from '@progress/kendo-react-inputs'
import {HookFormComponentProps, HookFormFieldProps} from './HookFormComponent'
import {formatNumber} from '@telerik/kendo-intl'

export const HookFormNumericTextBox = (props: HookFormFieldProps & NumericTextBoxProps) => {
  const componentProps: NumericTextBoxProps = props
  //console.log('props.value = ' + props.value)
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.initialValue}
      render={({field, fieldState, formState}) => {
        let {invalid, error} = fieldState
        let err = error as FieldError
        const errorId: string = invalid ? `${props.name}_error` : ''
        //console.log('field ' + field.name + ': ' + field.value)
        return (
          <div className={'k-form-field'}>
            <Label
              editorId={props.name}
              editorValid={!invalid}
              editorDisabled={false}
              optional={false}
              style={{width: props.labelWidth}}
            >
              {props.label}
            </Label>
            <div className='k-form-field-wrap'>
              {props.showAsLabel ? (
                <div
                  style={{
                    fontSize: '1rem',
                    marginTop: '5px',
                    marginLeft: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {field.value}
                </div>
              ) : (
                <>
                  <NumericTextBox
                    {...componentProps}
                    {...field}
                    label={undefined} // do not use component label
                    valid={!invalid}
                    width={props.componentWidth}
                    disabled={props.disabled}
                    onChange={(event) => {
                      field.onChange(event.value)
                      if (props.onChange) props.onChange(event)
                    }}
                    format={props.format}
                  />
                  {invalid && err.message && <Error id={errorId}>{err.message}</Error>}
                </>
              )}
            </div>
            {props.postfix && <span className='ms-10'>{props.postfix}</span>}
          </div>
        )
      }}
    />
  )
}
