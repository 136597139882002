/* eslint-disable jsx-a11y/alt-text */
import {Button} from '@progress/kendo-react-buttons'
import toast from 'react-hot-toast'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {MyFormState} from '../../../../classes/MyFormState'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {HookFormInput} from '../../../../components/ReactHookForm/HookFormInput'
import {HookFormTextArea} from '../../../../components/ReactHookForm/HookFormTextArea'
import {
  ActionPlansModel,
  AttachmentModel,
  IncidentLossesModel,
  IncidentModel,
  InvestigationModel,
  PersonalModel,
} from '../../../../models/IncidentModel'
import {
  useAddIncident,
  useGetIncidentData,
  useGetIncidentDataInves,
  useGetIncidentGridData,
  useSaveInvestigation,
  useUpdateIncident,
} from '../IncidentApi'
import {useNavigate, useParams} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import {HookFormDatePicker} from '../../../../components/ReactHookForm/HookFormDatePicker'
import {HookFormSwitch} from '../../../../components/ReactHookForm/HookFormSwitch'
import {HookFormUploadImage} from '../../../../components/ReactHookForm/HookFormUploadImage'
import {HookFormDropDownList} from '../../../../components/ReactHookForm/HookFormDropDownList'
import {HookFormNumericTextBox} from '../../../../components/ReactHookForm/HookFormNumericTextBox'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload'
import DeleteDataDialog from '../../../../components/Dialog/DeleteDataDialog'
import {Dialog} from '@progress/kendo-react-dialogs'
import axios from 'axios'
import {loadMessages, LocalizationProvider} from '@progress/kendo-react-intl'

import {useIntl} from 'react-intl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown, faCaretUp} from '@fortawesome/pro-light-svg-icons'
import {
  faChevronDown,
  faChevronUp,
  faImageLandscape,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import {State} from '@progress/kendo-data-query'
import {Grid, GridCellProps, GridColumn, GridDataStateChangeEvent} from '@progress/kendo-react-grid'
import {auto} from '@popperjs/core'
import PopupIncidentLosses from './popup/PopupIncidentLosses'
import SubmitApproveRejectDataDialog from '../../../../components/Dialog/SubmitApproveDataDialog'
import PopupActionPlans from './popup/PopupActionPlans'
import RejectDataDialog from '../../../../components/Dialog/RejectDataDialog'
import {HookFormComboBoxWithRemoteData} from '../../../../components/ReactHookForm/HookFormComboBoxRemoteData'
import InvestigationFormBottom from './InvestigationFormBottom'
import {TextArea} from '@progress/kendo-react-inputs'
import PopupIncidentLossesNew from './popup/PopupIncidentLossesNew'
import PopupActionPlansNew from './popup/PopupActionPlansNew'

export interface EstateFormProps {
  mode: 'view' | 'create' | 'delete'
  data?: IncidentModel
  dataId: string | number | undefined
  dataInves?: InvestigationModel
}

export default function PelaporanForm(props: EstateFormProps) {
  const intl = useIntl()
  const {t} = useTranslation('translation')
  const [formState, setFormState] = useState<MyFormState>('view')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const [searchText, setSearchText] = useState<string>('')
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  })
  let {incidentId} = useParams()
  const {data, refetch} = useGetIncidentData(incidentId ?? '')
  const {
    status,
    data: dataGridLoss,
    isFetching,
    isLoading,
    isFetched,
    refetch: refetchLoss,
  } = useGetIncidentGridData({
    dataState: dataState,
    searchText: searchText,
  })

  const [numForms, setNumForms] = useState(0)
  const [viewDialogVisible, setViewDialogVisible] = useState(false)
  const [viewImageUrl, setViewImageUrl] = useState('')

  const validationSchema = Yup.object().shape({})

  const [selected, setSelected] = useState(0)
  const handleSelect = (e: any) => {
    setSelected(e.selected)
  }

  const [files, setFiles] = useState<Array<UploadFileInfo>>([])
  const [events, setEvents] = useState<Array<any>>([])
  const [filePreviews, setFilePreviews] = useState<{[key: string]: string}>({})
  const [affectedFiles, setAffectedFiles] = useState<Array<UploadFileInfo>>([])

  const initialSubmitState = {
    visible: false,
    title: '',
    url: '',
  }
  const initialRejectState = {
    id: '',
    visible: false,
    title: '',
    url: '',
  }
  const [submitState, setSubmitState] = useState(initialSubmitState)
  const [rejectState, setRejectState] = useState(initialRejectState)

  const initialEditIncidentLosses = {
    visible: false,
    mode: '',
    title: '',
    dataId: '',
    incidentId: '',
  }
  const [editIncidentLosses, setEditIncidentLosses] = useState(initialEditIncidentLosses)
  const [editActionPlan, setEditActiionPlan] = useState(initialEditIncidentLosses)

  const initialDeleteState = {
    visible: false,
    title: '',
    url: '',
  }

  const fileStatuses = [
    'UploadFailed',
    'Initial',
    'Selected',
    'Uploading',
    'Uploaded',
    'RemoveFailed',
    'Removing',
  ]

  const defaultIncidentLosses: IncidentLossesModel = {
    id: '',
    incidentId: '',
    typeOfLoss: '',
    description: '',
    costOfLoss: 0,
  }
  const defaultActionPlans: ActionPlansModel = {
    id: '',
    incidentId: '',
    description: '',
    isCompleted: false,
    notes: '',
    progressPhotos: [
      {
        id: '',
        incidentId: '',
        attachmentType: '',
        itemId: '',
        description: '',
        imagePath: '',
        seqId: '',
        image: '',
        imageUrl: '',
      },
    ],
  }

  const defaultAttachmentData: AttachmentModel = {
    id: '',
    incidentId: ' ',
    attachmentType: ' ',
    description: ' ',
    imagePath: ' ',
    seqId: 0,
    image: ' ',
    imageUrl: ' ',
  }
  const [incidentLosses, setIncidentLosses] = useState<IncidentLossesModel[]>([
    defaultIncidentLosses,
  ])
  const [action, setAction] = useState<ActionPlansModel[]>([defaultActionPlans])

  const [attachmentData, setAttachmentData] = useState<AttachmentModel[]>([defaultAttachmentData])

  const [deleteState, setDeleteState] = useState(initialDeleteState)
  const [visibleReport, setVisibleReport] = useState(false)

  function createNewIncidentLossData(): IncidentLossesModel {
    return {
      id: '',
      incidentId: '',
      typeOfLoss: '',
      description: '',
      costOfLoss: 0,
    }
  }
  function createNewActionPlansData(): ActionPlansModel {
    return {
      id: '',
      incidentId: '',
      description: '',
      isCompleted: false,
      notes: '',
      progressPhotos: [
        {
          id: '',
          incidentId: '',
          attachmentType: '',
          itemId: '',
          description: '',
          imagePath: '',
          seqId: '',
          image: '',
          imageUrl: '',
        },
      ],
    }
  }

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<InvestigationModel>({
    resolver: yupResolver(validationSchema),
  })

  const saveInvestigation = useSaveInvestigation()
  // const updateIncident = useUpdateIncident()

  useEffect(() => {
    console.log('mode now :', props.mode)
    console.log('form now :', formState)

    affectedFiles
      .filter((file: UploadFileInfo) => !file.validationErrors)
      .forEach((file: UploadFileInfo) => {
        const reader = new FileReader()

        reader.onloadend = (ev: any) => {
          setFilePreviews({
            ...filePreviews,
            [file.uid]: ev.target.result,
          })
        }
        if (file && file.getRawFile) {
          reader.readAsDataURL(file.getRawFile())
        }
      })

    if (props.mode === 'create') {
      setFormState('editing')
    } else {
      reset(props.data)

      if (props.data?.investigation) {
        setAction(props.data?.investigation.actionPlans ?? [])
      }
      setAttachmentData(props.data?.incidentReportPhotos ?? [])
      if (props.data?.investigation) {
        setNumForms(props.data?.investigation.actionPlans.length ?? 1)
      }
    }
  }, [props.mode, props.data])

  const canEdit = useMemo(() => {
    return true
  }, [])

  function onSearchTextChange(value: string) {
    setSearchText(value)
  }
  function onGridStateChange(e: GridDataStateChangeEvent) {
    console.log(`onGridStateChange() skip: ${e.dataState.skip}`)
    setDataState(e.dataState)
  }

  function onHideReport() {
    setVisibleReport(!visibleReport)
  }

  function onEditClicked() {
    // setErrorMessage('')
    setFormState('editing')
  }

  function handleSubmitButtonClicked(data: any) {
    setSubmitState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Investigation/Submit?incidentId=' + data.id,
    })
  }
  function handleApproveButtonClicked(data: any) {
    setSubmitState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Investigation/Approve?incidentId=' + data.id,
    })
  }
  function handleRejectButtonClicked(data: any) {
    setRejectState({
      id: data.id,
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Investigation/Reject',
    })
  }

  function onCancelClicked() {
    if (props.mode === 'create') {
      navigate('/incident-report', {replace: true})
    } else {
      setFormState('view')
    }
  }

  function onAddActionPlansClicked() {
    const newData = createNewActionPlansData() // Fungsi untuk membuat data form baru kosong
    setAction([...action, newData])
    setNumForms(numForms + 1)
  }

  function onDeletePersonClicked(index: number) {
    const updatedPersonalData = [...action]
    updatedPersonalData.splice(index, 1)
    setAction(updatedPersonalData)
    setNumForms(numForms - 1)

    // Update the form data
    const updatedFormData = {...getValues()}
    updatedFormData.actionPlans.splice(index, 1)
    setValue('actionPlans', updatedFormData.actionPlans)
  }

  function handleAddIncidentLossesClick(data: any) {
    // console.log('data handleEditPersonVehicleClick: ', data)
    if (props.data?.investigationStatus !== 'DRAFT') {
      let message = `Tidak bisa diedit karena status sudah ${props.data?.investigationStatus}`
      console.log('status=>', props.data?.investigationStatus)

      toast.error(message)
      return false
    } else {
      // console.log('data', props)

      setEditIncidentLosses({
        visible: true,
        mode: 'add',
        title: 'Incident Losses',
        dataId: data,
        incidentId: data,
      })
    }
  }
  function handleAddActionPlanClick(data: any) {
    console.log('data handleEditPersonVehicleClick: ', data)
    // if (props.dataX.incident_report_status === "APPROVED") {
    //   setErrorMessageImpactedVehicle("Tidak bisa diedit karena status sudah APPROVED ");
    //   return false;
    // } else {
    setEditActiionPlan({
      visible: true,
      mode: 'add',
      title: 'Action Plan',
      dataId: data,
      incidentId: data,
    })
    // }
  }

  function onSaveClicked() {
    console.log('onSaveClicked()..')

    handleSubmit(onSubmit)()
    refetch()
  }

  const onSubmit = (data: InvestigationModel) => {
    console.log('onSubmit() data = ', data)
    data.incidentId = incidentId

    saveInvestigation.mutate(data, {
      onSuccess: (response: ServiceResult<InvestigationModel>) => {
        let result = response.data!
        console.log('Add Data success', result)
        let message = t('Add Data Successful')
        toast.success(message)
        // navigate(`/incident-report/investigation/${incidentId}`)
        refetch()
        // history.push(`/partner/${result.driverId}`)
        // } else {
        //   console.log('Add Estate failed: ' + response.errorMessage)
        //   toast.error(response.errorMessage ?? 'Error')
        //   setFormState('editing')
        // }
        setFormState('view')
        // redirect to view route
      },
      onError: (data) => {
        console.log('Add Estate error')
        let message = 'Add Estate failed. Please check your network connection.'
        toast.error(message)
        setFormState('editing')
        //setFormState('view')
      },
    })
  }

  function handleFormClose() {
    setDeleteState({...deleteState, visible: false})
    setEditIncidentLosses({...editIncidentLosses, visible: false})
    setEditActiionPlan({...editActionPlan, visible: false})
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
  }
  function handleFormDeleteSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    setEditIncidentLosses({...editIncidentLosses, visible: false})
    setEditActiionPlan({...editActionPlan, visible: false})
    refetch()
  }

  function handleFormSubmitSuccess() {
    let message = t('Change Status Data Successful')
    toast.success(message)
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    refetch()
  }

  function handleDeleteLoss(data: any) {
    setDeleteState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Investigation/Loss/Delete?incidentLossId=' + data.id,
    })
  }

  function handleDeleteAction(data: any) {
    setDeleteState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Investigation/ActionPlan/Delete?actionPlanId=' + data.id,
    })
  }

  function onViewClicked(props: any) {
    setViewDialogVisible(true)
    setViewImageUrl(props)
    console.log(props)
  }

  const labelWidth = '150px'
  // var isDisabled = formState !== 'editing'

  const CustomListItemUI = ({files}: {files: Array<UploadFileInfo>}) => {
    return (
      <ul>
        {files.map((file: UploadFileInfo, index: any) => (
          <>
            <>
              <li key={`${file.name}_${index}`}>
                <img
                  src={filePreviews[file.name]}
                  alt={file.name}
                  style={{width: 200, height: 100, marginBottom: '5px'}}
                />
                <span>{file.name}</span>

                <HookFormInput
                  name={`incidentReportPhotos[${index}].description`}
                  label='Description'
                  control={control}
                  width={labelWidth}
                />
              </li>
            </>
          </>
        ))}
      </ul>
    )
  }

  function getStatusClass(status: any) {
    let statusClass = 'badge'
    if (status === 'NA') {
      statusClass += ' bg-dark'
    } else if (status === 'APPROVED') {
      statusClass += ' bg-success'
    } else if (status === 'WAITING APPROVAL') {
      statusClass += 'badge badge-light-warning'
    } else if (status === 'REJECTED') {
      statusClass += 'badge badge-light-danger'
    } else if (status === 'DRAFT') {
      statusClass += ' badge-light-primary'
    }
    return statusClass
  }

  const actionCell = (props: GridCellProps) => {
    var incidentId = props.dataItem.id != undefined ? props.dataItem.id : ''
    return (
      <td>
        <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
          <span className='cursor-pointer' title={t('View')}>
            <div>
              <>
                <FontAwesomeIcon
                  icon={faImageLandscape}
                  size='lg'
                  onClick={() => onViewClicked(props.dataItem.imageUrl)}
                />
              </>
            </div>
          </span>
          {/* <span
            className='cursor-pointer'
            onClick={() => hadleDeleteButtonClick(props.dataItem)}
            title={t('Delete')}
          >
            <FontAwesomeIcon icon={faTrash} size='lg' />
          </span> */}
        </div>
      </td>
    )
  }

  const actionCellLoss = (props: GridCellProps) => {
    return (
      <td>
        <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
          <span className='cursor-pointer' title={t('View')}>
            <div>
              <>
                <FontAwesomeIcon
                  icon={faTrash}
                  size='lg'
                  onClick={() => handleDeleteLoss(props.dataItem)}
                />
              </>
            </div>
          </span>
        </div>
      </td>
    )
  }
  const actionCellAction = (props: GridCellProps) => {
    return (
      <td>
        <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
          <span className='cursor-pointer' title={t('View')}>
            <div>
              <span>
                <FontAwesomeIcon
                  icon={faTrash}
                  size='lg'
                  onClick={() => handleDeleteAction(props.dataItem)}
                />
              </span>
            </div>
          </span>
        </div>
      </td>
    )
  }

  return (
    <>
      <div className='flex-column-fluid d-flex flex-column'>
        {/* toolbar */}
        <div className='h-60px d-flex flex-row align-items-center gap-2 flex-shrink-0 border-bottom'>
          <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
            <div className='d-flex align-items-center mb-3 gap-2'>
              <Button
                togglable={false}
                className='btn-primary-jotun-yellow w-80px'
                onClick={onSaveClicked}
                disabled={props.data?.investigationStatus === 'WAITING APPROVAL'}
              >
                {t('Save')}
              </Button>
              <span
                className={`badge ${getStatusClass(props.data?.investigationStatus)}`}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h5
                  className={`${getStatusClass(props.data?.investigationStatus)}`}
                >{`${props.data?.investigationStatus}`}</h5>
              </span>
            </div>
            <div className='d-flex align-items-center mb-3 gap-2'>
              <>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleSubmitButtonClicked(props.data)}
                  disabled={props.data?.investigationStatus !== 'DRAFT'}
                >
                  {t('Submit')}
                </Button>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleApproveButtonClicked(props.data)}
                  disabled={props.data?.investigationStatus !== 'WAITING APPROVAL'}
                >
                  {t('Approve')}
                </Button>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleRejectButtonClicked(props.data)}
                  disabled={props.data?.investigationStatus !== 'WAITING APPROVAL'}
                >
                  {t('Reject')}
                </Button>
              </>
            </div>
          </div>

          {/* {formState === 'saving' && (
            <>
              <Button type='button' togglable={false} className='btn-primary-jotun-yellow w-80px'>
                {t('Saving')}...
              </Button>
            </>
          )} */}
        </div>
        <div className='flex-column-fluid d-flex flex-column k-form k-form-horizontal'>
          {/* {formState === 'editing' && !!errorMessage && (
            <div className='alert alert-danger w-100' role='alert'>
              {errorMessage}
            </div>
          )} */}

          <h4>
            Laporan Awal{' '}
            <FontAwesomeIcon
              icon={visibleReport ? faChevronUp : faChevronDown}
              className='ms-2 mt-2 cursor-pointer'
              onClick={onHideReport}
            />
          </h4>

          {visibleReport && (
            <>
              <div className='card shadow m-3 '>
                <div className='card-body '>
                  <div className='row'>
                    <div className='col-8'>
                      <legend
                        className='page-title'
                        style={{
                          backgroundColor: '#1986c8',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        {'Incident Information'}
                      </legend>
                      <div className='row'>
                        <div className='w-100 d-flex flow-row flex-wrap'>
                          <div className='col-6'>
                            <HookFormDatePicker
                              name='incidentDate'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTDATE'})}
                              labelWidth={labelWidth}
                              format={'dd/MMM/yyyy'}
                              width={100}
                              disabled
                            />
                            <HookFormInput
                              name='incidentNumber'
                              control={control}
                              label={t('Incident Number')}
                              labelWidth={labelWidth}
                              disabled
                            />
                            <HookFormInput
                              name='incidentName'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTNAME'})}
                              labelWidth={labelWidth}
                              disabled
                            />

                            <HookFormInput
                              name='incidentLocation'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTLOC'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                            <HookFormInput
                              name='witnessName'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.WITNESS'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                          </div>

                          <div className='col-6'>
                            <HookFormInput
                              name='workActivity'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.ACT'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                            <HookFormInput
                              name='workCondition'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.COND'})}
                              labelWidth={labelWidth}
                              disabled
                            />

                            <HookFormTextArea
                              name='description'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.DESCRIPTION'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                            <HookFormSwitch
                              name='statementTaken'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.STATEMENT'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                      <legend
                        className='page-title'
                        style={{
                          backgroundColor: '#1986c8',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        {'Attachment'}
                      </legend>
                      <Grid data={data?.incidentReportPhotos}>
                        <GridColumn field='description' title='description' width={auto} />
                        <GridColumn field='id' title='Action' width={120} cell={actionCell} />
                      </Grid>
                    </div>
                  </div>
                  <div className='row' style={{marginTop: '50px'}}>
                    <div className='col-12'>
                      <legend
                        className='page-title'
                        style={{
                          backgroundColor: '#1986c8',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        {'Impacted Person'}
                      </legend>

                      <Grid data={data?.impactedPersons}>
                        <GridColumn field='name' title='Name' />
                        <GridColumn field='gender' title='Gender' />
                        <GridColumn field='age' title='Age' />
                        <GridColumn field='companyName' title='Company' />
                        <GridColumn field='position' title='Position' />
                        <GridColumn field='address' title='Address' />
                        <GridColumn field='estimatedLoss' title='Estimated Loss' />
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <hr />

          <h4>Investigation</h4>
          <form id='myForm' onSubmit={handleSubmit(onSubmit)}>
            <TabStrip selected={selected} onSelect={handleSelect} className='mb-5'>
              <TabStripTab title={'Investigation'}>
                <>
                  <div className='card shadow m-3'>
                    <div className='card-body '>
                      <legend
                        className='page-title'
                        style={{
                          backgroundColor: '#1986c8',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        {'Investigation'}
                      </legend>

                      <HookFormComboBoxWithRemoteData
                        dataUrl='/api/Incident/Priority/List'
                        name='priority'
                        control={control}
                        label={'Priority'}
                        labelWidth={labelWidth}
                      />
                      <HookFormTextArea
                        name='causeOfIncident'
                        control={control}
                        label={t('Cause of Incident')}
                        labelWidth={labelWidth}
                      />

                      {data?.investigationStatus === 'REJECTED' &&
                        data?.investigation &&
                        data.investigation.rejectReason !== null && (
                          <div className='row mt-5'>
                            <div className='col-12'>
                              <legend
                                className='page-title'
                                style={{
                                  backgroundColor: '#1986c8',
                                  color: 'white',
                                  textAlign: 'center',
                                }}
                              >
                                {'Reject Reason'}
                              </legend>

                              <TextArea
                                name={data?.investigation.rejectReason}
                                value={data?.investigation.rejectReason}
                                disabled
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              </TabStripTab>
              <TabStripTab title={'Incident Losses'}>
                <div className='card shadow m-3'>
                  <div className='card-body '>
                    <legend
                      className='page-title'
                      style={{
                        backgroundColor: '#1986c8',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      {'Incident Losses'}
                    </legend>
                    {props.data?.investigationStatus !== 'WAITING APPROVAL' && (
                      <Button
                        type='button'
                        className='mb-2'
                        onClick={() => handleAddIncidentLossesClick(props.dataId)}
                      >
                        Add
                      </Button>
                    )}
                    <Grid data={data?.investigation?.incidentLosess}>
                      <GridColumn field='typeOfLoss' title='Type of Loss' />
                      <GridColumn field='description' title='Description' />
                      <GridColumn field='costOfLoss' title='Cost of Loss' />
                      {props.data?.investigationStatus !== 'WAITING APPROVAL' && (
                        <GridColumn field='id' title='Actions' cell={actionCellLoss} width={120} />
                      )}
                    </Grid>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab title={'Action Plans'}>
                <div className='card shadow m-3'>
                  <div className='card-body '>
                    <legend
                      className='page-title'
                      style={{
                        backgroundColor: '#1986c8',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      {'Action Plans'}
                    </legend>
                    {props.data?.investigationStatus !== 'WAITING APPROVAL' && (
                      <Button
                        type='button'
                        className='mb-2'
                        onClick={() => handleAddActionPlanClick(props.dataId)}
                      >
                        Add
                      </Button>
                    )}

                    <Grid data={data?.investigation?.actionPlans}>
                      <GridColumn field='description' title='Description' />
                      {props.data?.investigationStatus !== 'WAITING APPROVAL' && (
                        <GridColumn
                          field='actionCell'
                          title='Actions'
                          cell={actionCellAction}
                          width={120}
                        />
                      )}
                    </Grid>
                  </div>
                </div>
              </TabStripTab>

              {/* <TabStripTab title='Action Plans'>
                <>
                  {action.map((loss, index) => {
                    return (
                      <div key={index} className='pb-2'>
                        <div className='card shadow m-3'>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='w-100 d-flex flow-row flex-wrap gap-3'>
                                <div className='col-8'>
                                  <HookFormInput
                                    name={`investigation.actionPlans[${index}].incidentId`}
                                    control={control}
                                    label='incidentId'
                                    labelWidth={labelWidth}
                                    // hideAsField
                                    // hidden
                                    initialValue={incidentId}
                                  />
                                  <HookFormInput
                                    name={`investigation.actionPlans[${index}].description`}
                                    control={control}
                                    label={intl.formatMessage({id: 'Description'})}
                                    labelWidth={labelWidth}
                                  />{' '}
                                </div>{' '}
                              </div>
                            </div>
                          </div>
                          {index > 0 && (
                            <Button
                              togglable={false}
                              type='button'
                              className='btn btn-secondary btn-sm mt-3'
                              onClick={() => onDeletePersonClicked(index)}
                              // disabled={isDisabled}
                            >
                              {intl.formatMessage({id: 'TEXT.DELETE'})}
                            </Button>
                          )}
                        </div>
                      </div>
                    )
                  })}
                  <Button
                    togglable={false}
                    type='button'
                    className='btn btn-primary btn-sm mt-3'
                    onClick={onAddActionPlansClicked}
                  >
                    {intl.formatMessage({id: 'TEXT.ADD'})}
                  </Button>
                </>
              </TabStripTab> */}
            </TabStrip>
          </form>

          {/* <InvestigationFormBottom mode={'view'} data={dataInvestigation} dataId={undefined} /> */}
        </div>
        {deleteState.visible && (
          <DeleteDataDialog
            title={deleteState.title}
            url={deleteState.url}
            onClose={handleFormClose}
            onSuccess={handleFormDeleteSuccess}
          />
        )}
        {viewDialogVisible && (
          <Dialog title='View Image' onClose={() => setViewDialogVisible(false)} height={500}>
            <img src={viewImageUrl} alt='' height={450} />
          </Dialog>
        )}
        {submitState.visible && (
          <SubmitApproveRejectDataDialog
            title={submitState.title}
            url={submitState.url}
            onClose={handleFormClose}
            onSuccess={handleFormSubmitSuccess}
          />
        )}
        {rejectState.visible && (
          <RejectDataDialog
            id={rejectState.id}
            title={rejectState.title}
            url={rejectState.url}
            onClose={handleFormClose}
            onSuccess={handleFormSubmitSuccess}
          />
        )}
        {editIncidentLosses.visible && (
          <PopupIncidentLossesNew
            title={editIncidentLosses.title}
            mode={editIncidentLosses.mode}
            dataId={editIncidentLosses.dataId}
            incidentId={editIncidentLosses.incidentId}
            onClose={handleFormClose}
            onSuccess={handleFormSubmitSuccess}
          />
        )}
        {editActionPlan.visible && (
          <PopupActionPlansNew
            title={editActionPlan.title}
            mode={editActionPlan.mode}
            dataId={editActionPlan.dataId}
            incidentId={editActionPlan.incidentId}
            onClose={handleFormClose}
            onSuccess={handleFormSubmitSuccess}
          />
        )}
      </div>
    </>
  )
}
