import {PageActionSection, PageTitle, useLayout} from '../../../../_metronic/layout/core'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridHeaderCellProps,
} from '@progress/kendo-react-grid'
import {faEye, faFilePen, faPencil, faTrash} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from '@progress/kendo-react-buttons'
import {CenteredColumnHeader} from '../../../components/Grid/CenteredColumnHeader'
import DeleteDataDialog from '../../../components/Dialog/DeleteDataDialog'
import GridLoadingPanel from '../../../components/Grid/GridLoadingPanel'
import {SearchTextBox} from '../../../components/Filters/SearchTextBox'
import {useQueryClient} from 'react-query'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {State} from '@progress/kendo-data-query'
import {useGetObservationGridData} from './ObservationApi'

import toast from 'react-hot-toast'
import {Link, useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {faList} from '@fortawesome/pro-regular-svg-icons'

export default function IncidentReportMain() {
  const intl = useIntl()
  const {classes} = useLayout()
  const queryClient = useQueryClient()
  const {t} = useTranslation('translation')
  const navigate = useNavigate()

  const [searchText, setSearchText] = useState<string>('')
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  })

  const initialEditState = {
    visible: false,
    mode: '',
    title: '',
    dataId: '',
  }
  const initialDeleteState = {
    visible: false,
    title: '',
    url: '',
  }

  const [editState, setEditState] = useState(initialEditState)
  const [deleteState, setDeleteState] = useState(initialDeleteState)

  const {status, data, isFetching, isLoading, isFetched, refetch} = useGetObservationGridData({
    dataState: dataState,
    searchText: searchText,
  })

  console.log('dataaaaaaaa', data)

  function onSearchTextChange(value: string) {
    setSearchText(value)
  }

  function onGridStateChange(e: GridDataStateChangeEvent) {
    console.log(`onGridStateChange() skip: ${e.dataState.skip}`)
    setDataState(e.dataState)
  }

  {
    /* Function Action Delete ========================= */
  }

  function handleFormClose() {
    setDeleteState({...deleteState, visible: false})
  }
  function handleFormDeleteSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    refetch()
  }

  {
    /* Action Edit end Delete ===================*/
  }
  const actionCell = (props: GridCellProps) => {
    var hseFormId = props.dataItem.hseFormId != undefined ? props.dataItem.hseFormId : ''
    return (
      <td style={{borderBottom: '1px solid lightGray '}}>
        <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
          <span className='cursor-pointer' title={t('Start Inspection')}>
            <Link to={`${hseFormId}`} className='clickable-grid-cell text-hover-primary'>
              <Button className='bg-white hover-shadow' style={{color: '#1c8ae7'}}>
                Start Inspection
              </Button>
            </Link>
          </span>
          {/* <span
            className='cursor-pointer'
            onClick={() => hadleDeleteButtonClick(props.dataItem)}
            title={t('Delete')}
          >
            <FontAwesomeIcon icon={faTrash} size='lg' />
          </span> */}
        </div>
      </td>
    )
  }

  return (
    <>
      <PageTitle>Inspection</PageTitle>
      {/* <PageActionSection>
        <div className='d-flex'>
          <Button className='btn-primary-jotun-yellow' size='large' onClick={addData}>
            Add Company
          </Button>
        </div>
      </PageActionSection> */}
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body d-flex flex-column'>
          <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
            {/* <!-- left aligned controllers --> */}
            <div className='d-flex align-items-center mb-3'>
              <SearchTextBox onChange={onSearchTextChange} value={searchText}></SearchTextBox>
            </div>
            {/* <!-- right aligned controllers --> */}
            <div className='d-flex align-items-center mb-3'></div>
          </div>
          <div id='gridContainer' className='w-100 flex-grow-1 overflow-hidden h-400px'>
            <Grid
              filterable={false}
              sortable={true}
              pageable={true}
              scrollable='scrollable'
              {...dataState}
              data={data}
              onDataStateChange={onGridStateChange}
              className='h-100'
            >
              <GridColumn
                field='hseFormName'
                title={'Form'}
                cell={(props) => {
                  return (
                    <td style={{borderBottom: '1px solid lightGray '}}>
                      <div className='m-3'>
                        <h5> {props.dataItem.hseFormName}</h5>
                        <span style={{color: '#545454'}}>{props.dataItem.description}</span>
                      </div>
                    </td>
                  )
                }}
              />
              <GridColumn
                field='createdDate'
                title={'Form Created Date'}
                cell={(props) => (
                  <td style={{color: '#545454', borderBottom: '1px solid lightGray '}}>
                    {`${new Date(props.dataItem.createdDate)
                      .getDate()
                      .toString()
                      .padStart(2, '0')}/${(new Date(props.dataItem.createdDate).getMonth() + 1)
                      .toString()
                      .padStart(2, '0')}/${new Date(props.dataItem.createdDate).getFullYear()}`}
                  </td>
                )}
                width={200}
              />

              <GridColumn
                field=''
                title={intl.formatMessage({id: 'TEXT.ACTION'})}
                width={150}
                cell={actionCell}
                className='text-center'
                headerCell={CenteredColumnHeader}
              />
            </Grid>
            {/* {(isLoading || isFetching) && <GridLoadingPanel />} */}
            {deleteState.visible && (
              <DeleteDataDialog
                title={deleteState.title}
                url={deleteState.url}
                onClose={handleFormClose}
                onSuccess={handleFormDeleteSuccess}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
