import {PageActionSection, PageTitle, useLayout} from '../../../../_metronic/layout/core'

import {useEffect, useState} from 'react'
import {State} from '@progress/kendo-data-query'

import toast from 'react-hot-toast'
import {Link, useNavigate} from 'react-router-dom'
import {Splitter, SplitterOnChangeEvent} from '@progress/kendo-react-layout'
import axios from 'axios'
import {
  TreeView,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
} from '@progress/kendo-react-treeview'
import {ListView, ListViewItemProps} from '@progress/kendo-react-listview'
import {Button} from '@progress/kendo-react-buttons'
import {HookFormTextArea} from '../../../components/ReactHookForm/HookFormTextArea'
import {Input, NumericTextBox} from '@progress/kendo-react-inputs'
import {HookFormNumericTextBox} from '../../../components/ReactHookForm/HookFormNumericTextBox'
import {control} from 'leaflet'
import {DatePicker} from '@progress/kendo-react-dateinputs'

interface FormDataItem {
  text: string
  items: any
  expanded: any
  description: string
}

export default function ObservationInspectionMainPage() {
  const [panes, setPanes] = useState<Array<any>>([{size: '20%', collapsible: false}, {}])
  const [hseForm, setHseForm] = useState([])
  const [formData, setFormData] = useState<FormDataItem[]>([])

  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedHseFormCode, setSelectedHseFormCode] = useState(null)

  const getForm = async () => {
    try {
      const res = await axios.get(`/api/HseForm/GridData`)
      setHseForm(res.data.data)
    } catch (error) {}
  }

  const getFormData = async (hseFormCode: any) => {
    try {
      const url = `/api/HseForm/${hseFormCode}`
      const res = await axios.get(url)
      // Ubah objek tunggal menjadi array dengan satu elemen
      const transformedData = [res.data]
      console.log('transsssssssss', transformedData)

      // // Ubah properti hseFormCode menjadi text dan items untuk menampilkan label
      // .map((item: any) => ({
      //   ...item,
      //   text: item.hseFormCode, // Ubah hseFormCode menjadi text
      //   items: item.items.map((subItem: any) => ({
      //     text: subItem.label, // Menampilkan properti label dari property items
      //     description: subItem.description, // Menampilkan properti label dari property items
      //     // Jika diperlukan, Anda dapat menambahkan properti lain di sini
      //     items: subItem.items.map((subItems: any) => ({
      //       text: subItems.label,
      //       description: subItems.description,
      //     })),
      //     // expanded: true,
      //   })),
      //   expanded: true,
      // }))
      setFormData(transformedData)
    } catch (error) {
      console.error('Error fetching form data:', error)
      return [] // Atau tangani error dengan sesuai
    }
  }

  useEffect(() => {
    getFormData(selectedHseFormCode)
    getForm()
  }, [])

  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState)
  }

  const MyItemRender = (props: ListViewItemProps) => {
    let item = props.dataItem
    const handleItemClick = () => {
      // Jika item yang diklik adalah yang sedang terpilih, set selectedItem menjadi null
      if (selectedItem === item) {
        setSelectedItem(null)
        setSelectedHseFormCode(null) // Menetapkan null juga untuk selectedHseFormCode
      } else {
        setSelectedItem(item)
        setSelectedHseFormCode(item.hseFormId) // Setelah item dipilih, set nilai hseFormCode dari item tersebut
        getFormData(item.hseFormId) // Panggil getFormData dengan hseFormCode yang dipilih
      }
    }

    return (
      <div
        className={`k-listview-item ${selectedItem === item ? 'selected' : ''}`}
        style={{
          padding: 10,
          cursor: 'pointer',
          backgroundColor: selectedItem === item ? '#0d6efd' : 'transparent',
          color: selectedItem === item ? 'white' : 'black',
        }}
        onClick={handleItemClick}
      >
        {item.hseFormName}
      </div>
    )
  }

  return (
    <>
      <PageTitle>Observation & Inspection</PageTitle>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body d-flex flex-column'>
          <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
            <div className='d-flex align-items-center mb-3'></div>
          </div>
          <div id='gridContainer' className='w-100 flex-grow-1 overflow-hidden h-500px'>
            <Splitter style={{height: 500}} panes={panes} onChange={onChange}>
              <div className='tree-view m-3'>
                <h3>HSE Form</h3>
                <ListView data={hseForm} item={MyItemRender} style={{width: '100%'}} />
              </div>
              <div className='main-content m-5' style={{minHeight: '100vh'}}>
                <form id='FormData'>
                  {selectedItem ? (
                    <>
                      {formData &&
                      formData.length > 0 &&
                      formData[0].items &&
                      formData[0].items.length > 0 ? (
                        <div style={{paddingBottom: '100px'}}>
                          {formData.map((data: any) => (
                            <div key={data.hseFormId}>
                              <h2 className='text-center'>{data.hseFormName}</h2>

                              {/* <p>{data.description}</p> */}
                              {data.items &&
                                data.items.map((item: any) => (
                                  <div key={item.hseFormItemId} className='card shadow m-3'>
                                    <div className='card-body'>
                                      {item.isSection ? (
                                        <div className='card-title'>
                                          <h3>{item.label}</h3>
                                          <hr />
                                        </div>
                                      ) : (
                                        <div className=''>
                                          <h6>
                                            <li>{item.label}</li>
                                          </h6>
                                          <span>{item.description}</span>
                                          {item.inputType === 'TEXT' && ( // Tambahkan pengecekan untuk hseFormOptionId
                                            <div className='mb-5'>
                                              <textarea className='form-control'></textarea>
                                            </div>
                                          )}

                                          {item.inputType === 'DROPDOWN' ||
                                            item.inputType === 'RADIO' ||
                                            (item.inputType === 'CHECKBOX' && (
                                              <div>
                                                {item.options.map((option: any) => {
                                                  console.log(option, 'OPTIONS')

                                                  return (
                                                    <div>
                                                      <label className=' d-block'>
                                                        <input
                                                          className='me-1'
                                                          type='radio'
                                                          value={option.value}
                                                          name={item.hseFormItemId}
                                                        />
                                                        {option.label}
                                                      </label>
                                                    </div>
                                                  )
                                                })}
                                              </div>
                                            ))}
                                        </div>
                                      )}

                                      {item.items &&
                                        item.items.map((subItem: any) => (
                                          <div key={subItem.itemId}>
                                            <h6>
                                              <li>{subItem.label}</li>
                                            </h6>
                                            <span>{subItem.description}</span>
                                            <div className='mb-5'>
                                              {subItem.inputType === 'TEXT' && ( // Tambahkan pengecekan untuk hseFormOptionId
                                                <div className='mb-5'>
                                                  <Input className='form-control'></Input>
                                                </div>
                                              )}
                                              {subItem.inputType === 'MULTILINETEXT' && ( // Tambahkan pengecekan untuk hseFormOptionId
                                                <div className='mb-5'>
                                                  <textarea className='form-control'></textarea>
                                                </div>
                                              )}
                                              {subItem.inputType === 'NUMBER' && ( // Tambahkan pengecekan untuk hseFormOptionId
                                                <div className='mb-5'>
                                                  <NumericTextBox
                                                    name='inputType'
                                                    {...(subItem.numberMinValue !== null
                                                      ? {min: subItem.numberMinValue}
                                                      : {})}
                                                    {...(subItem.numberMaxValue !== null
                                                      ? {max: subItem.numberMaxValue}
                                                      : {})}
                                                  />
                                                </div>
                                              )}
                                              {subItem.inputType === 'DATE' && ( // Tambahkan pengecekan untuk hseFormOptionId
                                                <div className='mb-5'>
                                                  <DatePicker
                                                    name='value'
                                                    format={'dd/MMM/yyyy'}
                                                    {...(subItem.dateMinValue !== null
                                                      ? {min: subItem.dateMinValue}
                                                      : {})}
                                                    {...(subItem.dateMaxValue !== null
                                                      ? {max: subItem.dateMaxValue}
                                                      : {})}
                                                  />
                                                </div>
                                              )}
                                              {(subItem.inputType === 'RADIO' ||
                                                subItem.inputType === 'DROPDOWN' ||
                                                subItem.inputType === 'CHECKBOX' ||
                                                subItem.inputType === 'COMBOBOX') && (
                                                <div>
                                                  {subItem.options.map((option: any) => {
                                                    return (
                                                      <div>
                                                        <label className=' d-block'>
                                                          <input
                                                            className='me-1'
                                                            type='radio'
                                                            value={option.value}
                                                            name={subItem.hseFormItemId}
                                                          />
                                                          {option.label}
                                                        </label>
                                                      </div>
                                                    )
                                                  })}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ))}
                          {formData.some((data) => data.items) && (
                            <>
                              <div className='card m-3 shadow'>
                                <div className='card-body'>
                                  <h4 className='card-title text-center'>Notes</h4>
                                  <textarea className='form-control'></textarea>
                                </div>
                              </div>
                              <div className='d-flex justify-content-end m-3'>
                                <Button themeColor={'primary'} size={'large'}>
                                  Save
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          {/* {formData && formData.length > 0 ? (
                          <p>{formData[0].hseFormName}</p>
                        ) : (
                          <p>Tidak ada data formulir yang ditampilkan.</p>
                        )} */}

                          {formData.map((data: any) => {
                            return (
                              <div>
                                <center>
                                  <h2>{data.hseFormName}</h2>
                                </center>
                                <p>Tidak ada data formulir yang ditampilkan.</p>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    <p>Silakan pilih form dari daftar HSE Form.</p>
                  )}
                </form>
              </div>
            </Splitter>
          </div>
        </div>
      </div>
    </>
  )
}
