import {Button} from '@progress/kendo-react-buttons'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import axios from 'axios'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {HookFormInput} from '../../../../components/ReactHookForm/HookFormInput'
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  ListBoxItemClickEvent,
  ListBoxToolbarClickEvent,
  processListBoxDragAndDrop,
} from '@progress/kendo-react-listbox'
import {RoleModel} from '../../../../models/RoleModel'
import {MyFormState} from '../../../../classes/MyFormState'
import {useNavigate} from 'react-router-dom'
import {useAddRole, useUpdateRole} from './RoleApi'
import {ServiceResult} from '../../../../classes/ServiceResult'
import toast from 'react-hot-toast'

export interface RoleFormProps {
  mode: 'view' | 'create'
  data?: RoleModel
  dataId: ''
}

export default function RoleForm(props: RoleFormProps) {
  const {mode, data, dataId, ...others} = props
  const {t} = useTranslation('translation')
  const [formState, setFormState] = useState<MyFormState>('view')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const SELECTED_FIELD = 'selected'
  const [statePermissions, setStatePermissions] = useState({
    selected: [],
    available: [],
    //draggedItem: {},
  })

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<any>({})

  const addRole = useAddRole()
  const updateRole = useUpdateRole()

  useEffect(() => {
    if (props.mode === 'create') {
      setFormState('editing')
      getPermissions()
    } else {
      reset(props.data)
      var available = props.data != undefined ? props.data?.available! : []
      var permissions = props.data != undefined ? props.data?.permissions! : []
      setStatePermissions({
        ...statePermissions,
        available: available,
        selected: permissions,
      })
    }
  }, [props.mode, props.data])

  function getPermissions() {
    let url = `api/Permission/List`
    axios
      .get(url)
      .then((response) => {
        setStatePermissions({
          ...statePermissions,
          available: response.data,
          selected: [],
        })
      })
      .catch((error) => {
        setErrorMessage(t('FailedToLoadData'))
        setFormState('error')
      })
  }

  const canEdit = useMemo(() => {
    return true
  }, [])

  function onEditClicked() {
    setFormState('editing')
  }

  function onCancelClicked() {
    if (props.mode === 'create') {
      navigate('/role', {replace: true})
    } else {
      setFormState('view')
      console.log('onSubmit() data = ', navigate)
    }
  }

  function onSaveClicked() {
    console.log('onSaveClicked()..')
    handleSubmit(onSubmit)()
  }

  {
    /* Function Add and Update ========================= */
  }
  const onSubmit = (data: RoleModel) => {
    console.log('onSubmit() data = ', data)
    setFormState('saving')
    var selected: any = []
    statePermissions.selected.map((obj: any) => {
      selected.push(obj.id)
    })
    data.permissions = selected
    if (props.mode === 'create') {
      addRole.mutate(data, {
        onSuccess: (response: ServiceResult<RoleModel>) => {
          if (response.success) {
            let result = response.data!
            console.log('Add Role success')
            let message = t('Add Data Successful')
            toast.success(message)
            navigate(`/Role/${result.roleId}`)
          } else {
            console.log('Add Role failed: ' + response.errorMessage)
            toast.error(response.errorMessage ?? 'Error')
            setFormState('editing')
          }
        },
        onError: (data) => {
          console.log('Add Role error')
          let message = 'Add Role failed. Please check your network connection.'
          toast.error(message)
          setFormState('editing')
          //setFormState('view')
        },
      })
    } else {
      updateRole.mutate(data, {
        onSuccess: (response: ServiceResult<RoleModel>) => {
          if (response.success) {
            console.log('Update Role success')
            let message = t('Data Has Been Updated', {data: data.roleName})
            toast.success(message)
            setFormState('view')
          } else {
            console.log('Update Role failed: ' + response.errorMessage)
            toast.error(response.errorMessage ?? 'Error')
            setFormState('editing')
          }
        },
        onError: (data) => {
          console.log('Update Role error')
          let message = 'Update Role failed. Please check your network connection.'
          toast.error(message)
          setFormState('editing')
        },
      })
    }
  }

  {
    /** Function Click Available =========================================================================*/
  }
  const handleItemAvailableClick = (
    event: ListBoxItemClickEvent,
    data: string,
    connectedData: string
  ) => {
    setStatePermissions({
      ...statePermissions,
      [data]: statePermissions['available'].map((item: any) => {
        if (item.text === event.dataItem.text) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD]
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false
        }
        return item
      }),
      [connectedData]: statePermissions['selected'].map((item: any) => {
        item[SELECTED_FIELD] = false
        return item
      }),
    })
  }

  {
    /** Function Click Selected =========================================================================*/
  }
  const handleItemSelectedClick = (
    event: ListBoxItemClickEvent,
    data: string,
    connectedData: string
  ) => {
    setStatePermissions({
      ...statePermissions,
      [data]: statePermissions['selected'].map((item: any) => {
        if (item.text === event.dataItem.text) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD]
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false
        }
        return item
      }),
      [connectedData]: statePermissions['available'].map((item: any) => {
        item[SELECTED_FIELD] = false
        return item
      }),
    })
  }

  const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
    let toolName = e.toolName || ''
    let result: any = processListBoxData(
      statePermissions.selected,
      statePermissions.available,
      toolName,
      SELECTED_FIELD
    )
    setStatePermissions({
      ...statePermissions,
      selected: result.listBoxOneData,
      available: result.listBoxTwoData,
    })
  }

  const labelWidth = '120px'
  var isDisabled = formState !== 'editing'

  return (
    <div className='flex-column-fluid d-flex flex-column'>
      {/* toolbar */}
      <div className='h-60px d-flex flex-row align-items-center gap-2 flex-shrink-0 border-bottom'>
        {formState === 'view' && canEdit && (
          <>
            <Button
              togglable={false}
              className='btn-primary-jotun-yellow w-80px'
              onClick={onEditClicked}
            >
              {t('Edit')}
            </Button>
          </>
        )}
        {formState === 'editing' && (
          <>
            <Button
              type='button'
              togglable={false}
              className='btn-primary-jotun-yellow w-80px'
              onClick={onSaveClicked}
            >
              {t('Save')}
            </Button>
            <Button togglable={false} className='w-80px' onClick={onCancelClicked}>
              {t('Cancel')}
            </Button>
          </>
        )}
        {formState === 'saving' && (
          <>
            <Button type='button' togglable={false} className='btn-primary-jotun-yellow w-80px'>
              {t('Saving')}...
            </Button>
          </>
        )}
      </div>
      <div className='flex-column-fluid d-flex flex-column k-form k-form-horizontal'>
        <form id='myForm'>
          {formState === 'editing' && !!errorMessage && (
            <div className='alert alert-danger w-100' role='alert'>
              {errorMessage}
            </div>
          )}
          <div className='w-100 d-flex flow-row flex-wrap'>
            <div className='half'>
              <HookFormInput
                name='roleName'
                control={control}
                label={t('Role Name')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
              <HookFormInput
                name='description'
                control={control}
                label={t('Description')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
            </div>
          </div>
          <hr />
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col k-pr-2'>
                <h6>Selected Permissions</h6>
                <ListBox
                  style={{width: '100%'}}
                  data={statePermissions.selected}
                  valueField='id'
                  textField='text'
                  selectedField={SELECTED_FIELD}
                  onItemClick={(data) => handleItemSelectedClick(data, 'selected', 'available')}
                  toolbar={() => {
                    return (
                      <ListBoxToolbar
                        tools={['transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']} //, 'remove'
                        data={statePermissions.selected}
                        dataConnected={statePermissions.available}
                        onToolClick={handleToolBarClick}
                      />
                    )
                  }}
                />
              </div>
              <div className='col k-pl-2'>
                <h6>Available Permissions</h6>
                <ListBox
                  style={{width: '100%'}}
                  data={statePermissions.available}
                  valueField='id'
                  textField='text'
                  selectedField={SELECTED_FIELD}
                  onItemClick={(e: ListBoxItemClickEvent) =>
                    handleItemAvailableClick(e, 'available', 'selected')
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
