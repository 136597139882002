import ReactDOM from "react-dom"

const GridLoadingPanel = () => {
    const loadingPanel = (
        <div className='k-loading-mask' >
            <span className='k-loading-text' >Loading..</span>
            <div className='k-loading-image' />
            <div className='k-loading-color' />
        </div>
    )

    const gridContent = document && document.querySelector('.k-grid-content')
    return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel
}

export default GridLoadingPanel