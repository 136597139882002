/* eslint-disable jsx-a11y/alt-text */
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  ChartSeriesLabels,
  ChartAxisDefaults,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts'
import {State} from '@progress/kendo-data-query'
import CSS from 'csstype'
import {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns'
import axios from 'axios'
import moment from 'moment'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  Calendar,
  CalendarChangeEvent,
  DatePicker,
  DateTimePicker,
} from '@progress/kendo-react-dateinputs'
import {DropDownListWithRemoteData} from '../../../components/Dropdowns/DropDownListWithRemoteData'
import {faCoconut} from '@fortawesome/pro-regular-svg-icons'
import {faCoconut as faCoconutSolid} from '@fortawesome/pro-solid-svg-icons'
import {faCoconut as faCoconutLight} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Popup, PopupCloseEvent, PopupProps} from '@progress/kendo-react-popup'

export default function SopMainPage() {
  const [companyId, setCompanyId] = useState<string | null>('')
  const [varietyId, setVarietyId] = useState<string | null>('')
  const [estateId, setEstateId] = useState<string | null>('')
  const [searchText, setSearchText] = useState<string>('')
  const [date, setDate] = useState(new Date())
  const [monthSelectedDate, setMonthSelectedDate] = useState(new Date())
  const [yearSelectedDate, setYearSelectedDate] = useState(new Date())

  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  })

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <PageTitle>Permit to Work Page</PageTitle>
        </div>
      </div>
      <div className='card h-100 mb-10'>
        <div className='card-body'>
          <h3 className='mb-4'>Permit to Work</h3>
          <hr className='mb-4' />
        </div>
      </div>
    </>
  )
}
