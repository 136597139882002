import {Route, Routes} from 'react-router-dom'
import HazardMainPage from './HazardMainPage'
import HazardCreatePage from './HazardCreatePage'

export default function HazardPage() {
  return (
    <Routes>
      {/* <Route path=':companyId/*' element={<CompanyDetailPage  />} />*/}
      <Route path='new' element={<HazardCreatePage />} />
      <Route index element={<HazardMainPage />} />
    </Routes>
  )
}
