import ReactDOM from 'react-dom'
import {useState} from 'react'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import axios from 'axios'
import {useIntl} from 'react-intl'

interface IDeleteFormProps {
  title: string
  url: string
  onClose: () => void
  onSuccess: () => void
}

function DeleteDataDialog(props: IDeleteFormProps) {
  const intl = useIntl()
  const {title, url, onClose, onSuccess, ...others} = props
  const deleteData = () => {
    axios
      .post(url)
      .then((response) => {
        console.log('response.data>>', response.data)
        if (response.data.success) {
          if (onSuccess) onSuccess()
        } else {
          console.log(response.data.errorMessage)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Dialog title={title} onClose={onClose}>
      <p style={{margin: '25px', textAlign: 'center'}}>
        {intl.formatMessage({id: 'TEXT.CONFDELETE'})}
      </p>
      <DialogActionsBar>
        <button
          className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
          onClick={deleteData}
        >
          {intl.formatMessage({id: 'TEXT.YES'})}
        </button>
        <button
          className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
          onClick={onClose}
        >
          {intl.formatMessage({id: 'TEXT.NO'})}
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DeleteDataDialog
