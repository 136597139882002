/* eslint-disable jsx-a11y/alt-text */
import {Button} from '@progress/kendo-react-buttons'
import toast from 'react-hot-toast'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {MyFormState} from '../../../../classes/MyFormState'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {HookFormInput} from '../../../../components/ReactHookForm/HookFormInput'
import {HookFormTextArea} from '../../../../components/ReactHookForm/HookFormTextArea'
import {AttachmentModel, IncidentModel, PersonalModel} from '../../../../models/IncidentModel'
import {useAddIncident, useGetIncidentData, useUpdateIncident} from '../IncidentApi'
import {useNavigate, useParams} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import {HookFormDatePicker} from '../../../../components/ReactHookForm/HookFormDatePicker'
import {HookFormSwitch} from '../../../../components/ReactHookForm/HookFormSwitch'
import {HookFormUploadImage} from '../../../../components/ReactHookForm/HookFormUploadImage'
import {HookFormDropDownList} from '../../../../components/ReactHookForm/HookFormDropDownList'
import {HookFormNumericTextBox} from '../../../../components/ReactHookForm/HookFormNumericTextBox'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload'
import DeleteDataDialog from '../../../../components/Dialog/DeleteDataDialog'
import {Dialog} from '@progress/kendo-react-dialogs'
import axios from 'axios'
import {loadMessages, LocalizationProvider} from '@progress/kendo-react-intl'

import {useIntl} from 'react-intl'
import SubmitApproveRejectDataDialog from '../../../../components/Dialog/SubmitApproveDataDialog'
import RejectDataDialog from '../../../../components/Dialog/RejectDataDialog'
import {Grid, GridColumn} from '@progress/kendo-react-grid'
import PopupAttachment from './popup/PopupAtachment'
import {RadioButton, RadioButtonChangeEvent} from '@progress/kendo-react-inputs'
import {Label} from '@progress/kendo-react-labels'
export interface EstateFormProps {
  mode: 'view' | 'create' | 'delete'
  data?: IncidentModel
}

export default function PelaporanForm(props: EstateFormProps) {
  const intl = useIntl()
  const {t} = useTranslation('translation')
  const [formState, setFormState] = useState<MyFormState>('view')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  let {incidentId} = useParams()
  const {data, refetch} = useGetIncidentData(incidentId ?? '')

  const [numForms, setNumForms] = useState(0)
  const [viewDialogVisible, setViewDialogVisible] = useState(false)
  const [viewImageUrl, setViewImageUrl] = useState('')

  const validationSchema = Yup.object().shape({})

  const [selected, setSelected] = useState(0)
  const handleSelect = (e: any) => {
    setSelected(e.selected)
  }

  const {id} = useParams()
  const initialDeleteState = {
    visible: false,
    title: '',
    url: '',
  }

  const initialSubmitState = {
    visible: false,
    title: '',
    url: '',
  }
  const initialRejectState = {
    id: '',
    visible: false,
    title: '',
    url: '',
  }

  const initialAddAttachment = {
    visible: false,
    title: '',
    dataId: '',
    incidentId: '',
  }
  const fileStatuses = [
    'UploadFailed',
    'Initial',
    'Selected',
    'Uploading',
    'Uploaded',
    'RemoveFailed',
    'Removing',
  ]

  const gender = [
    {
      id: 'M',
      text: 'Male',
    },
    {
      id: 'F',
      text: 'Female',
    },
  ]

  const defaultPersonalData: PersonalModel = {
    id: '',
    incidentId: '',
    name: '',
    gender: '',
    companyName: '',
    position: '',
    age: 0,
    address: '',
    estimatedLoss: 0,
  }

  const defaultAttachmentData: AttachmentModel = {
    id: '',
    incidentId: ' ',
    attachmentType: ' ',
    description: ' ',
    imagePath: ' ',
    seqId: 0,
    image: ' ',
    imageUrl: ' ',
  }
  const [personalData, setPersonalData] = useState<PersonalModel[]>([defaultPersonalData])
  const [attachmentData, setAttachmentData] = useState<AttachmentModel[]>([defaultAttachmentData])

  const [deleteState, setDeleteState] = useState(initialDeleteState)
  const [deleteIncidentState, setDeleteIncidentState] = useState(initialDeleteState)
  const [submitState, setSubmitState] = useState(initialSubmitState)
  const [rejectState, setRejectState] = useState(initialRejectState)
  const [addAttach, setAddAttach] = useState(initialAddAttachment)
  // const [approveState, setApproveState] = useState(initialDeleteState)
  // const [rejectState, setApproveState] = useState(initialDeleteState)

  const [dialogUpload, setDialogUpload] = useState(false)
  const [attId, setAttId] = useState('')

  const [selectedStatement, setSelectedStatement] = useState(null)

  const handleChange = useCallback(
    (e: RadioButtonChangeEvent) => {
      setSelectedStatement(e.value)
    },
    [setSelectedStatement]
  )

  function createNewPersonalData(): PersonalModel {
    return {
      id: '',
      incidentId: '',
      name: '',
      gender: '',
      companyName: '',
      position: '',
      age: 0,
      address: '',
      estimatedLoss: 0,
    }
  }

  function createNewAttachmentData(): AttachmentModel {
    return {
      id: '',
      incidentId: ' ',
      attachmentType: ' ',
      description: ' ',
      imagePath: ' ',
      seqId: 0,
      image: ' ',
      imageUrl: ' ',
    }
  }

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<IncidentModel>({
    resolver: yupResolver(validationSchema),
  })

  const addIncident = useAddIncident()
  const updateIncident = useUpdateIncident()

  useEffect(() => {
    if (props.mode === 'create') {
      setFormState('editing')
    } else {
      reset(props.data)
      setPersonalData(props.data?.impactedPersons ?? [])
      setAttachmentData(props.data?.incidentReportPhotos ?? [])
      setNumForms(props.data?.impactedPersons?.length ?? 1)
    }
  }, [props.mode, props.data])

  const canEdit = useMemo(() => {
    return true
  }, [])

  function onEditClicked() {
    // setErrorMessage('')
    setFormState('editing')
  }

  function handleAddAttachmentClicked(data: any) {
    setAddAttach({
      visible: true,
      title: 'Attachment',
      dataId: data,
      incidentId: data.incidentId,
    })
  }
  function handleSubmitButtonClicked(data: any) {
    setSubmitState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/submit?Id=' + data.id,
    })
  }
  function handleApproveButtonClicked(data: any) {
    setSubmitState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Approve?Id=' + data.id,
    })
  }
  function handleCloseButtonClicked(data: any) {
    setSubmitState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Close?Id=' + data.id,
    })
  }
  function handleRejectButtonClicked(data: any) {
    setRejectState({
      id: data.id,
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Reject?Id=' + data.id,
    })
  }

  {
    /* Function Action Delete ========================= */
  }
  function hadleDeleteButtonClick(data: any) {
    setDeleteIncidentState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Delete?Id=' + data.id,
    })
  }

  function onCancelClicked() {
    if (props.mode === 'create') {
      navigate('/incident-report', {replace: true})
    } else {
      setFormState('view')
    }
  }

  function onAddPersonClicked() {
    const newData = createNewPersonalData() // Fungsi untuk membuat data form baru kosong
    setPersonalData([...personalData, newData])
    setNumForms(numForms + 1)
  }
  function onDeletePersonClicked(index: number) {
    const updatedPersonalData = [...personalData]
    updatedPersonalData.splice(index, 1)
    setPersonalData(updatedPersonalData)
    setNumForms(numForms - 1)

    // Update the form data
    const updatedFormData = {...getValues()}
    updatedFormData.impactedPersons.splice(index, 1)
    setValue('impactedPersons', updatedFormData.impactedPersons)
  }

  function onSaveClicked() {
    console.log('onSaveClicked()..')

    handleSubmit(onSubmit)()
    refetch()
  }

  const onSubmit = (data: IncidentModel) => {
    console.log('onSubmit() data = ', data)
    setFormState('saving')
    if (props.mode === 'create') {
      addIncident.mutate(data, {
        onSuccess: async (response: ServiceResult<IncidentModel>) => {
          let result = response.data!
          const idx: any = response.data?.incidentReportPhotos.length

          // const attachId = response.data?.incidentReportPhotos[idx].id

          console.log('atttttttttttttID', idx)

          // if (attachId) {
          //   await uploadImage(attachId, files[i])
          //   console.log('File uploaded:', files[idx].name)
          //   console.log('idxxxxxxxxxxxx', idx)
          // } else {
          //   console.error(' ID is undefined')
          // }
          console.log('Add Estate success')
          let message = t('Add Data Successful')
          toast.success(message)
          navigate(`/incident-report/pelaporan-awal/${result.id}`)
          // history.push(`/partner/${result.driverId}`)
          // } else {
          //   console.log('Add Estate failed: ' + response.errorMessage)
          //   toast.error(response.errorMessage ?? 'Error')
          //   setFormState('editing')
          // }
          // setFormState('view')
          // redirect to view route
        },
        onError: (data) => {
          console.log('Add Estate error')
          let message = 'Add Estate failed. Please check your network connection.'
          toast.error(message)
          setFormState('editing')
          //setFormState('view')
        },
      })
    } else {
      updateIncident.mutate(data, {
        onSuccess: (response: ServiceResult<IncidentModel>) => {
          if (response.success) {
            console.log('Update Estate success')
            let message = t('Data Has Been Updated', {data: data.incidentName})
            toast.success(message)
            setFormState('view')
            refetch()
          } else {
            console.log('Update Estate failed: ' + response.errorMessage)
            toast.error(response.errorMessage ?? 'Error')
            setFormState('editing')
          }
        },
        onError: (data) => {
          console.log('Update Estate error')
          let message = 'Update Estate failed. Please check your network connection.'
          toast.error(message)
          setFormState('editing')
        },
      })
    }
  }

  function handleFormClose() {
    setDeleteState({...deleteState, visible: false})
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    setAddAttach({...addAttach, visible: false})
    setDeleteIncidentState({...deleteIncidentState, visible: false})
  }
  function handleFormDeleteSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    refetch()
  }

  function handleDeleteIncidentSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteIncidentState({...deleteIncidentState, visible: false})
    navigate('/incident-report')
  }
  function handleFormSubmitSuccess() {
    let message = t('Change Status Data Successful')
    toast.success(message)
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    refetch()
  }

  function handleAddAttachmentSuccess() {
    let message = t('Add Attachment Data Successful')
    toast.success(message)
    setAddAttach({...addAttach, visible: false})
    refetch()
  }

  function handleDeleteImage(data: any) {
    const photoIds = data
    console.log('ID FOTO', photoIds)

    setDeleteState({
      visible: true,
      title: 'Delete Attachment',
      url: '/api/Incident/Report/Attachment/Delete?attachmentId=' + photoIds,
    })
  }

  function onViewClicked(index: number) {
    setViewImageUrl(attachmentData[index].imageUrl)
    setViewDialogVisible(true)
    console.log('id attach', attachmentData[index].id)
  }

  const labelWidth = '150px'
  var isDisabled = formState !== 'editing'

  // const CustomListItemUI = ({files}: {files: Array<UploadFileInfo>}) => {
  //   return (
  //     <ul>
  //       {files.map((file: UploadFileInfo, index) => {
  //         const lastIdx = data?.incidentReportPhotos.length || 0
  //         const i = lastIdx
  //         console.log('iiiiiiiiiiiiii', i)

  //         return (
  //           <>
  //             <>
  //               <li key={`${file.uid}`}>
  //                 <img
  //                   src={filePreviews[file.name]}
  //                   alt={file.name}
  //                   style={{width: 200, height: 100, marginBottom: '5px'}}
  //                 />

  //                 <HookFormInput
  //                   name={`incidentReportPhotos[${i}].description`}
  //                   label='Description'
  //                   control={control}
  //                   width={labelWidth}
  //                 />

  //                 {/* <input type='text' value={`incidentReportPhotos${i}.description`} /> */}
  //               </li>
  //             </>
  //           </>
  //         )
  //       })}
  //     </ul>
  //   )
  // }

  function getStatusClass(status: any) {
    let statusClass = 'badge'
    if (status === 'NA') {
      statusClass += 'badge badge-light-dark'
    } else if (status === 'CLOSED') {
      statusClass += 'badge bg-dark text-white'
    } else if (status === 'APPROVED') {
      statusClass += 'badge badge-light-success'
    } else if (status === 'WAITING APPROVAL') {
      statusClass += 'badge badge-light-warning'
    } else if (status === 'REJECTED') {
      statusClass += 'badge badge-light-danger'
    } else if (status === 'DRAFT') {
      statusClass += 'badge badge-light-primary'
    }
    return statusClass
  }

  return (
    <>
      <div className='flex-column-fluid d-flex flex-column'>
        {/* toolbar */}
        <div className='h-60px d-flex flex-row align-items-center gap-2 flex-shrink-0 border-bottom'>
          <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
            <div className='d-flex align-items-center mb-3 gap-2'>
              {formState === 'editing' && (
                <>
                  <Button
                    type='button'
                    togglable={false}
                    className='btn-primary-jotun-yellow w-80px'
                    onClick={onSaveClicked}
                  >
                    {intl.formatMessage({id: 'BTN.SAVE'})}
                  </Button>
                  <Button togglable={false} className='w-80px' onClick={onCancelClicked}>
                    {intl.formatMessage({id: 'BTN.CANCEL'})}
                  </Button>
                </>
              )}
              {formState === 'view' && canEdit && (
                <>
                  <Button
                    togglable={false}
                    className='btn-primary-jotun-yellow w-80px'
                    onClick={onEditClicked}
                    disabled={
                      props.data?.reportStatus !== 'DRAFT' &&
                      props.data?.reportStatus !== 'REJECTED'
                    }
                  >
                    {t('Edit')}
                  </Button>
                  {props.data?.reportStatus === 'DRAFT' && (
                    <Button
                      togglable={false}
                      className='btn-primary-jotun-yellow w-80px'
                      onClick={() => hadleDeleteButtonClick(props.data)}
                      disabled={props.data?.reportStatus !== 'DRAFT'}
                    >
                      {t('Delete')}
                    </Button>
                  )}
                </>
              )}
              <span
                className={`badge ${getStatusClass(props.data?.reportStatus)}`}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h5 className={`${getStatusClass(props.data?.reportStatus)}`}>
                  {t(`${props.data?.reportStatus}`)}
                </h5>
              </span>
            </div>
            <div className='d-flex align-items-center mb-3 gap-2'>
              <>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleSubmitButtonClicked(props.data)}
                  disabled={props.data?.reportStatus !== 'DRAFT'}
                >
                  {t('Submit')}
                </Button>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleApproveButtonClicked(props.data)}
                  disabled={props.data?.reportStatus !== 'WAITING APPROVAL'}
                >
                  {t('Approve')}
                </Button>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleRejectButtonClicked(props.data)}
                  disabled={props.data?.reportStatus !== 'WAITING APPROVAL'}
                >
                  {t('Reject')}
                </Button>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleCloseButtonClicked(props.data)}
                  disabled={props.data?.reportStatus !== 'WAITING APPROVAL'}
                >
                  {t('Close')}
                </Button>
              </>
            </div>
          </div>

          {formState === 'saving' && (
            <>
              <Button type='button' togglable={false} className='btn-primary-jotun-yellow w-80px'>
                {t('Saving')}...
              </Button>
            </>
          )}
        </div>
        <div className='flex-column-fluid d-flex flex-column k-form k-form-horizontal'>
          <form id='myForm'>
            {formState === 'editing' && !!errorMessage && (
              <div className='alert alert-danger w-100' role='alert'>
                {errorMessage}
              </div>
            )}

            <TabStrip selected={selected} onSelect={handleSelect} className='mb-5'>
              <TabStripTab title={intl.formatMessage({id: 'TEXT.INCIDENT'})}>
                <>
                  <h5 className='mt-3'>{intl.formatMessage({id: 'TEXT.INCIDENT'})}</h5>
                  <div className='card shadow m-3 '>
                    <div className='card-body '>
                      <div className='row'>
                        <div className='w-100 d-flex flow-row flex-wrap'>
                          <div className='col-6'>
                            <HookFormDatePicker
                              name='incidentDate'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTDATE'})}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                              format={'dd/MMM/yyyy'}
                              width={100}
                            />
                            {/* <HookFormInput
                              name='incidentNumber'
                              control={control}
                              label={t('Incident Number')}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                            /> */}
                            <HookFormInput
                              name='incidentName'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTNAME'})}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                            />

                            <HookFormInput
                              name='incidentLocation'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTLOC'})}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                            />
                            <HookFormInput
                              name='witnessName'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.WITNESS'})}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                            />
                            <HookFormSwitch
                              name='statementTaken'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.STATEMENT'})}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                            />
                          </div>

                          <div className='col-6'>
                            <HookFormInput
                              name='workActivity'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.ACT'})}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                            />
                            <HookFormInput
                              name='workCondition'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.COND'})}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                            />

                            <HookFormTextArea
                              name='description'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.DESCRIPTION'})}
                              labelWidth={labelWidth}
                              disabled={isDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </TabStripTab>

              <TabStripTab title={intl.formatMessage({id: 'TEXT.IMPACTED'})}>
                <>
                  <h5 className='mt-3'>{intl.formatMessage({id: 'TEXT.IMPACTEDPERSON'})}</h5>
                  {personalData.map((personal, index) => {
                    return (
                      <div key={index} className='pb-2'>
                        <div className='card shadow m-3'>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='w-100 d-flex flow-row flex-wrap'>
                                <div className='col-6'>
                                  <HookFormInput
                                    name={`impactedPersons[${index}].name`}
                                    control={control}
                                    label={intl.formatMessage({id: 'TEXT.NAME'})}
                                    labelWidth={labelWidth}
                                    disabled={isDisabled}
                                  />
                                  <HookFormInput
                                    name={`impactedPersons[${index}].companyName`}
                                    control={control}
                                    label={intl.formatMessage({id: 'TEXT.COMPANYNAME'})}
                                    labelWidth={labelWidth}
                                    disabled={isDisabled}
                                  />
                                  <HookFormDropDownList
                                    name={`impactedPersons[${index}].gender`}
                                    control={control}
                                    label={intl.formatMessage({id: 'TEXT.GENDER'})}
                                    data={gender}
                                    labelWidth={labelWidth}
                                    disabled={isDisabled}
                                  />
                                  <HookFormNumericTextBox
                                    name={`impactedPersons[${index}].age`}
                                    control={control}
                                    label={intl.formatMessage({id: 'TEXT.AGE'})}
                                    labelWidth={labelWidth}
                                    disabled={isDisabled}
                                  />
                                </div>
                                <div className='col-6'>
                                  <HookFormInput
                                    name={`impactedPersons[${index}].position`}
                                    control={control}
                                    label={intl.formatMessage({id: 'TEXT.POSITION'})}
                                    labelWidth={labelWidth}
                                    disabled={isDisabled}
                                  />
                                  <HookFormInput
                                    name={`impactedPersons[${index}].injuryType`}
                                    control={control}
                                    label={'Injury Type'}
                                    labelWidth={labelWidth}
                                    disabled={isDisabled}
                                  />
                                  <HookFormNumericTextBox
                                    name={`impactedPersons[${index}].estimatedLoss`}
                                    control={control}
                                    label={intl.formatMessage({id: 'TEXT.LOSS'})}
                                    labelWidth={labelWidth}
                                    disabled={isDisabled}
                                  />
                                  <HookFormTextArea
                                    name={`impactedPersons[${index}].address`}
                                    control={control}
                                    label={intl.formatMessage({id: 'TEXT.ADDRESS'})}
                                    labelWidth={labelWidth}
                                    disabled={isDisabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {index > 0 && (
                            <Button
                              togglable={false}
                              type='button'
                              className='btn btn-secondary btn-sm mt-3'
                              onClick={() => onDeletePersonClicked(index)}
                              disabled={isDisabled}
                            >
                              {intl.formatMessage({id: 'TEXT.DELETE'})}
                            </Button>
                          )}
                        </div>
                      </div>
                    )
                  })}
                  <Button
                    togglable={false}
                    type='button'
                    className='btn btn-primary btn-sm mt-3'
                    onClick={onAddPersonClicked}
                    disabled={isDisabled}
                  >
                    {intl.formatMessage({id: 'TEXT.ADD'})}
                  </Button>
                </>
              </TabStripTab>
              <TabStripTab title={intl.formatMessage({id: 'TEXT.ATTACHMENT'})}>
                <h5 className='mt-3'>{intl.formatMessage({id: 'TEXT.ATTACHMENT'})}</h5>
                <div>
                  <div>
                    <div>
                      <Button
                        disabled={isDisabled}
                        className='mb-2'
                        type='button'
                        onClick={handleAddAttachmentClicked}
                      >
                        Add Attachment
                      </Button>
                    </div>
                  </div>

                  {props.mode !== 'create' && (
                    <div className='card shadow mb-3 mx-3'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='w-100 d-flex flow-row flex-wrap'>
                            {attachmentData.length > 0 ? (
                              attachmentData.map((incidentReportPhotos, index) => {
                                return (
                                  <div
                                    key={index}
                                    className='col-xl-4 col-md-6 col-sm-12 d-flex flex-column align-items-center mb-3'
                                  >
                                    <div className='card shadow'>
                                      <div className='card-body'>
                                        {incidentReportPhotos.imageUrl && (
                                          <img
                                            src={incidentReportPhotos.imageUrl}
                                            alt=''
                                            width={255}
                                            height={255}
                                            className='p-1'
                                          />
                                        )}

                                        <div className='d-flex gap-2'>
                                          {incidentReportPhotos.imageUrl && (
                                            <Button
                                              togglable={false}
                                              type='button'
                                              className='btn btn-secondary btn-sm'
                                              onClick={() => onViewClicked(index)}
                                              style={{width: '100px'}}
                                            >
                                              View
                                            </Button>
                                          )}
                                          {!incidentReportPhotos.imageUrl && (
                                            <>
                                              <Button
                                                style={{width: '100px'}}
                                                onClick={() => {
                                                  setDialogUpload(true)
                                                  setAttId(incidentReportPhotos.id)
                                                }}
                                                type='button'
                                              >
                                                Upload
                                              </Button>
                                            </>
                                          )}

                                          <Button
                                            togglable={false}
                                            type='button'
                                            className='btn btn-danger btn-sm'
                                            onClick={() =>
                                              handleDeleteImage(incidentReportPhotos.id)
                                            }
                                            disabled={isDisabled}
                                            style={{width: '100px'}}
                                          >
                                            Delete
                                          </Button>
                                        </div>

                                        <HookFormInput
                                          name={`incidentReportPhotos[${index}].description`}
                                          label='Description'
                                          control={control}
                                          disabled={isDisabled}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            ) : (
                              <div className='text-center'>
                                Tidak ada attachment yang dapat ditampilkan
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </TabStripTab>
            </TabStrip>
          </form>
        </div>
        {deleteState.visible && (
          <DeleteDataDialog
            title={deleteState.title}
            url={deleteState.url}
            onClose={handleFormClose}
            onSuccess={handleFormDeleteSuccess}
          />
        )}

        {addAttach.visible && (
          <PopupAttachment
            title={addAttach.title}
            onClose={handleFormClose}
            onSuccess={handleAddAttachmentSuccess}
            dataId={addAttach.dataId}
            incidentId={addAttach.incidentId}
          />
        )}
        {deleteIncidentState.visible && (
          <DeleteDataDialog
            title={deleteIncidentState.title}
            url={deleteIncidentState.url}
            onClose={handleFormClose}
            onSuccess={handleDeleteIncidentSuccess}
          />
        )}
        {submitState.visible && (
          <SubmitApproveRejectDataDialog
            title={submitState.title}
            url={submitState.url}
            onClose={handleFormClose}
            onSuccess={handleFormSubmitSuccess}
          />
        )}

        {/* Render your form here */}
        {rejectState.visible && (
          <RejectDataDialog
            title={rejectState.title}
            url={rejectState.url}
            onClose={() => setRejectState(initialRejectState)}
            onSuccess={() => {
              setRejectState(initialRejectState)
              refetch()
            }}
            id={rejectState.id}
          />
        )}

        {viewDialogVisible && (
          <Dialog title='View Image' onClose={() => setViewDialogVisible(false)} height={500}>
            <img src={viewImageUrl} alt='' height={450} />
          </Dialog>
        )}
      </div>
    </>
  )
}
