import {Button} from '@progress/kendo-react-buttons'
import toast from 'react-hot-toast'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MyFormState} from '../../../../classes/MyFormState'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {HookFormInput} from '../../../../components/ReactHookForm/HookFormInput'
import {UserModel} from '../../../../models/UserModel'
import {useAddUser, useUpdateUser} from './UserApi'
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import {HookFormDropDownListDataBinding} from '../../../../components/ReactHookForm/HookFormDropDownListDataBinding'
import {HookFormInputPassword} from '../../../../components/ReactHookForm/HookFormInputPassword'
import {HookFormSwitch} from '../../../../components/ReactHookForm/HookFormSwitch'

export interface UserFormProps {
  mode: 'view' | 'create'
  data?: UserModel
  dataId: ''
}

export default function UserForm(props: UserFormProps) {
  const {mode, data, dataId, ...others} = props
  const {t} = useTranslation('translation')
  const [formState, setFormState] = useState<MyFormState>('view')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [valueArea, setValueArea] = useState<string>('')
  const UserModel = {
    userName: '',
    userFullName: '',
    newPassword: '',
    confirmPassword: '',
    companyId: '',
    roleId: '',
    phoneNumber: '',
    email: '',
    isGlobalUser: false,
  }

  const updateValidationSchema = Yup.object().shape({
    // userName: Yup.string()
    // .required(t('User Name is required', { data: t('User Name')})),
    //  companyId: Yup.string()
    //    .required(t('Company is required', {data: t('Site')}))
    //    .nullable(),
    roleId: Yup.string()
      .required(t('Role is required', {data: t('Role')}))
      .nullable(),
    email: Yup.string().email(t('Email Is Invalid', {data: 'Email'})),
  })

  const insertValidationSchema = Yup.object().shape({
    // userName: Yup.string()
    // .required(t('User Name is required', { data: t('User Name') }))
    // .min(4, t('Data Must Be Characters', { data: t('User Name'), n: 4 })),
    //  companyId: Yup.string()
    //    .required(t('Company is required', {data: t('Site')}))
    //    .nullable(),
    roleId: Yup.string()
      .required(t('Role is required', {data: t('Role')}))
      .nullable(),
    email: Yup.string().email(t('Email Is Invalid', {data: 'Email'})),
    newPassword: Yup.string()
      .required(t('New Password is required', {data: 'Password'}))
      .min(6, t('Minimum password 6 characters', {data: 'Password', n: 6})),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      t('Confirm Password Not Match')
    ),
  })

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<UserModel>({
    resolver: yupResolver(mode === 'create' ? insertValidationSchema : updateValidationSchema),
  })

  const addUser = useAddUser()
  const updateUser = useUpdateUser()

  useEffect(() => {
    if (props.mode === 'create') {
      setFormState('editing')
    } else {
      reset(props.data)
      //loadSite()
    }
  }, [props.mode, props.data])

  const canEdit = useMemo(() => {
    return true
  }, [])

  function onEditClicked() {
    // setErrorMessage('')
    setFormState('editing')
  }

  function onCancelClicked() {
    if (props.mode === 'create') {
      navigate('/user', {replace: true})
    } else {
      setFormState('view')
      console.log('onSubmit() data = ', navigate)
    }
  }

  function onSaveClicked() {
    console.log('onSaveClicked()..')

    handleSubmit(onSubmit)()
  }

  {
    /* Function Add and Update ========================= */
  }
  const onSubmit = (data: UserModel) => {
    console.log('onSubmit() data = ', data)
    data.userName = data.email
    if (data.newPassword == undefined) {
      data.newPassword = ''
    }
    setFormState('saving')
    if (props.mode === 'create') {
      addUser.mutate(data, {
        onSuccess: (response: ServiceResult<UserModel>) => {
          if (response.success) {
            let result = response.data!
            console.log('Add User success')
            let message = t('Add Data Successful')
            toast.success(message)
            navigate(`/user/${result.userId}`)
          } else {
            console.log('Add User failed: ' + response.errorMessage)
            toast.error(response.errorMessage ?? 'Error')
            setFormState('editing')
          }
        },
        onError: (data) => {
          console.log('Add User error')
          let message = 'Add User failed. Please check your network connection.'
          toast.error(message)
          setFormState('editing')
          //setFormState('view')
        },
      })
    } else {
      updateUser.mutate(data, {
        onSuccess: (response: ServiceResult<UserModel>) => {
          if (response.success) {
            console.log('Update User success')
            let message = t('Data Has Been Updated', {data: data.userName})
            toast.success(message)
            setFormState('view')
          } else {
            console.log('Update User failed: ' + response.errorMessage)
            toast.error(response.errorMessage ?? 'Error')
            setFormState('editing')
          }
        },
        onError: (data) => {
          console.log('Update User error')
          let message = 'Update User failed. Please check your network connection.'
          toast.error(message)
          setFormState('editing')
        },
      })
    }
  }

  const labelWidth = '120px'
  var isDisabled = formState !== 'editing'

  return (
    <div className='flex-column-fluid d-flex flex-column'>
      {/* toolbar */}
      <div className='h-60px d-flex flex-row align-items-center gap-2 flex-shrink-0 border-bottom'>
        {formState === 'view' && canEdit && (
          <>
            <Button
              togglable={false}
              className='btn-primary-jotun-yellow w-80px'
              onClick={onEditClicked}
            >
              {t('Edit')}
            </Button>
          </>
        )}
        {formState === 'editing' && (
          <>
            <Button
              type='button'
              togglable={false}
              className='btn-primary-jotun-yellow w-80px'
              onClick={onSaveClicked}
            >
              {t('Save')}
            </Button>
            <Button togglable={false} className='w-80px' onClick={onCancelClicked}>
              {t('Cancel')}
            </Button>
          </>
        )}
        {formState === 'saving' && (
          <>
            <Button type='button' togglable={false} className='btn-primary-jotun-yellow w-80px'>
              {t('Saving')}...
            </Button>
          </>
        )}
      </div>
      <div className='flex-column-fluid d-flex flex-column k-form k-form-horizontal'>
        <form id='myForm'>
          {formState === 'editing' && !!errorMessage && (
            <div className='alert alert-danger w-100' role='alert'>
              {errorMessage}
            </div>
          )}
          <div className='w-100 d-flex flow-row flex-wrap'>
            <div className='half'>
              <HookFormInput
                name='email'
                control={control}
                label={t('Email')}
                labelWidth={labelWidth}
                disabled={isDisabled}
                type={'email'}
              />
              <HookFormInput
                name='userFullName'
                control={control}
                label={t('User FullName')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
              <HookFormInput
                type='password'
                name='newPassword'
                control={control}
                label={t('New Password')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
              <HookFormInput
                type='password'
                name='confirmPassword'
                control={control}
                label={t('Confirm Password')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
            </div>
            <div className='half'>
              <HookFormInput
                name='phoneNumber'
                control={control}
                label={t('Phone Number')}
                labelWidth={labelWidth}
                disabled={isDisabled}
                type={'number'}
              />
              <HookFormDropDownListDataBinding
                dataUrl='api/Company/List'
                name='companyId'
                control={control}
                label={t('Company')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
              <HookFormDropDownListDataBinding
                dataUrl='api/subscription/List'
                name='subscriptionId'
                control={control}
                label={t('Subscription')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
              <HookFormDropDownListDataBinding
                dataUrl='api/Role/List'
                name='roleId'
                control={control}
                label={t('Role')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
