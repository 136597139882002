import ReactDOM from "react-dom"
import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import axios from 'axios'
import { Button } from "@progress/kendo-react-buttons";

interface IConfirmationDialogProps {
   title: string
   message: string
   confirmationType: 'yes-no' | 'yes-cancel' | 'ok-cancel' | 'ok'
   onClose: () => void
   onConfirmed: () => void
}

function ConfirmationDialog(props: IConfirmationDialogProps) {
   const { title, message, confirmationType, onClose, onConfirmed } = props

   return (
      <Dialog title={title} onClose={onClose} >
         <p style={{ margin: "25px", textAlign: "center" }}>
            {message}
         </p>
         <DialogActionsBar layout={'center'}>
            {(confirmationType == 'yes-no' || confirmationType == 'yes-cancel') && (
               <Button style={{ width: '100px' }} onClick={() => onConfirmed()}>Yes</Button>
            )}
            {(confirmationType == 'ok-cancel' || confirmationType == 'ok') && (
               <Button style={{ width: '100px' }} onClick={() => onConfirmed()}>OK</Button>
            )}
            {(confirmationType == 'yes-no') && (
               <Button style={{ width: '80px' }} onClick={() => onClose()}>No</Button>
            )}
            {(confirmationType == 'yes-cancel' || confirmationType == 'ok-cancel') && (
               <Button style={{ width: '80px' }} onClick={() => onClose()}>Cancel</Button>
            )}
         </DialogActionsBar>
      </Dialog>
   )
}

export default ConfirmationDialog