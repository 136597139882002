import {Route, Routes} from 'react-router-dom'
import PermitMainPage from './PermitMainPage'

export default function PermitPage() {
  return (
    <Routes>
      {/* <Route path=':companyId/*' element={<CompanyDetailPage  />} />
            <Route path='new' element={<CompanyCreatePage />} /> */}
      <Route index element={<PermitMainPage />} />
    </Routes>
  )
}
