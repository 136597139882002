import {PageActionSection, PageTitle, useLayout} from '../../../../../_metronic/layout/core'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridHeaderCellProps,
} from '@progress/kendo-react-grid'
import {faEye, faFilePen, faPencil, faTrash} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from '@progress/kendo-react-buttons'
import {CenteredColumnHeader} from '../../../../components/Grid/CenteredColumnHeader'
import DeleteDataDialog from '../../../../components/Dialog/DeleteDataDialog'
import GridLoadingPanel from '../../../../components/Grid/GridLoadingPanel'
import {SearchTextBox} from '../../../../components/Filters/SearchTextBox'
import {useQueryClient} from 'react-query'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {State} from '@progress/kendo-data-query'
import {useGetIncidentGridData} from '../IncidentApi'

import toast from 'react-hot-toast'
import {Link, useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {faList} from '@fortawesome/pro-regular-svg-icons'

export default function IncidentReportMain() {
  const intl = useIntl()
  const {classes} = useLayout()
  const queryClient = useQueryClient()
  const {t} = useTranslation('translation')
  const navigate = useNavigate()

  const [searchText, setSearchText] = useState<string>('')
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
    filter: {
      logic: 'and',
      filters: [
        {field: 'progressStatus', operator: 'isnotnull'},

        // {field: 'progress', operator: 'isnotnull'},
        {field: 'investigationStatus', operator: 'doesnotcontain', value: 'WAITING APPROVAL'},
        {field: 'investigationStatus', operator: 'doesnotcontain', value: 'NA'},
        {field: 'investigationStatus', operator: 'doesnotcontain', value: 'DRAFT'},
        {field: 'progressStatus', operator: 'doesnotcontain', value: 'APPROVED'},
        {field: 'progressStatus', operator: 'doesnotcontain', value: 'REJECTED'},
      ],
    },
  })

  const initialEditState = {
    visible: false,
    mode: '',
    title: '',
    dataId: '',
  }
  const initialDeleteState = {
    visible: false,
    title: '',
    url: '',
  }

  const [editState, setEditState] = useState(initialEditState)
  const [deleteState, setDeleteState] = useState(initialDeleteState)

  const {status, data, isFetching, isLoading, isFetched, refetch} = useGetIncidentGridData({
    dataState: dataState,
    searchText: searchText,
  })

  console.log('DATAAA', data)

  function onSearchTextChange(value: string) {
    setSearchText(value)
  }

  function onGridStateChange(e: GridDataStateChangeEvent) {
    console.log(`onGridStateChange() skip: ${e.dataState.skip}`)
    setDataState(e.dataState)
  }

  function addData() {
    navigate('/incident-report/new')
  }

  {
    /* Function Action Delete ========================= */
  }
  function hadleDeleteButtonClick(data: any) {
    setDeleteState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/Delete?Id=' + data.id,
    })
  }
  function handleFormClose() {
    setDeleteState({...deleteState, visible: false})
  }
  function handleFormDeleteSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    refetch()
  }

  {
    /* Action Edit end Delete ===================*/
  }
  const actionCell = (props: GridCellProps) => {
    var incidentId = props.dataItem.id != undefined ? props.dataItem.id : ''
    return (
      <td>
        <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
          <span className='cursor-pointer' title={t('Edit')}>
            <Link to={`progress/${incidentId}`} className='clickable-grid-cell text-hover-primary'>
              <FontAwesomeIcon icon={faList} size='lg' />
            </Link>
          </span>
          {/* <span
            className='cursor-pointer'
            onClick={() => hadleDeleteButtonClick(props.dataItem)}
            title={t('Delete')}
          >
            <FontAwesomeIcon icon={faTrash} size='lg' />
          </span> */}
        </div>
      </td>
    )
  }

  return (
    <>
      <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
        {/* <!-- left aligned controllers --> */}
        <div className='d-flex align-items-center mb-3'>
          <SearchTextBox onChange={onSearchTextChange} value={searchText}></SearchTextBox>
        </div>
        {/* <!-- right aligned controllers --> */}
        <div className='d-flex align-items-center mb-3'>
          {/* <Button className='btn-primary-jotun-yellow' size='large' onClick={addData}>
            {t('Add')} {t('Incident')}
          </Button> */}
        </div>
      </div>
      <div id='gridContainer' className='w-100 flex-grow-1 overflow-hidden h-400px'>
        <Grid
          filterable={false}
          sortable={true}
          pageable={true}
          scrollable='scrollable'
          {...dataState}
          data={data}
          onDataStateChange={onGridStateChange}
          className='h-100'
        >
          <GridColumn
            field='incidentDate'
            title={intl.formatMessage({id: 'TEXT.INCIDENTDATE'})}
            cell={(props) => (
              <td>
                {`${new Date(props.dataItem.incidentDate).getDate().toString().padStart(2, '0')}/${(
                  new Date(props.dataItem.incidentDate).getMonth() + 1
                )
                  .toString()
                  .padStart(2, '0')}/${new Date(props.dataItem.incidentDate).getFullYear()}`}
              </td>
            )}
            width={120}
          />
          <GridColumn field='incidentName' title={intl.formatMessage({id: 'TEXT.INCIDENTNAME'})} />
          <GridColumn
            field='incidentLocation'
            title={intl.formatMessage({id: 'TEXT.INCIDENTLOC'})}
            width={170}
          />
          <GridColumn field='workActivity' title={intl.formatMessage({id: 'TEXT.ACT'})} />
          <GridColumn field='workCondition' title={intl.formatMessage({id: 'TEXT.COND'})} />
          <GridColumn
            field='progressStatus'
            title={intl.formatMessage({id: 'Progress Status'})}
            width={150}
            cell={(props) => {
              let statusClass = 'badge'

              // if (props.dataItem.progressStatus === 'NA') {
              //   statusClass += ' bg-dark'
              // } else if (props.dataItem.progressStatus === 'APPROVED') {
              //   statusClass += ' bg-success'
              // } else if (props.dataItem.progressStatus === 'WAITING APPROVAL') {
              //   statusClass += ' bg-warning'
              // } else if (props.dataItem.progressStatus === 'REJECTED') {
              //   statusClass += ' bg-danger'
              // } else if (props.dataItem.progressStatus === 'DRAFT') {
              //   statusClass += ' bg-primary'
              // }

              if (props.dataItem.progressStatus === 'NA') {
                statusClass += 'badge badge-light-dark'
              } else if (props.dataItem.progressStatus === 'APPROVED') {
                statusClass += 'badge badge-light-success'
              } else if (props.dataItem.progressStatus === 'WAITING APPROVAL') {
                statusClass += 'badge badge-light-warning'
              } else if (props.dataItem.progressStatus === 'REJECTED') {
                statusClass += 'badge badge-light-danger'
              } else if (props.dataItem.progressStatus === 'DRAFT') {
                statusClass += 'badge badge-light-primary'
              }

              return (
                <td>
                  <span
                    className={statusClass}
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '3px',
                    }}
                  >
                    {props.dataItem.progressStatus}
                  </span>
                </td>
              )
            }}
          />

          <GridColumn
            field=''
            title={intl.formatMessage({id: 'TEXT.ACTION'})}
            width='100px'
            cell={actionCell}
            className='text-center'
            headerCell={CenteredColumnHeader}
          />
        </Grid>
        {/* {(isLoading || isFetching) && <GridLoadingPanel />} */}
        {deleteState.visible && (
          <DeleteDataDialog
            title={deleteState.title}
            url={deleteState.url}
            onClose={handleFormClose}
            onSuccess={handleFormDeleteSuccess}
          />
        )}
      </div>
    </>
  )
}
