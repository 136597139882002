import {GridColumnMenuWrapper, GridHeaderCellProps} from '@progress/kendo-react-grid'
import React from 'react'

export const CenteredColumnHeader = (props: GridHeaderCellProps) => {
  return (
    <div className='text-center' style={{wordWrap: 'break-word', whiteSpace: 'normal'}}>
      {props.title}
    </div>
  )
}

export const LeftedColumnHeader = (props: GridHeaderCellProps) => {
  return (
    <div className='text-left' style={{wordWrap: 'break-word', whiteSpace: 'normal'}}>
      {props.title}
    </div>
  )
}

export const CenteredColumnHeaderWithMenu = (props: any) => {
  return (
    <React.Fragment>
      <span style={{textAlign: 'center', wordWrap: 'break-word', whiteSpace: 'normal'}}>
        {props.title}
      </span>
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
    </React.Fragment>
  )
}
