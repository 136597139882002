import {State} from '@progress/kendo-data-query'
import {useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridHeaderCellProps,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'
import GridLoadingPanel from '../../../components/Grid/GridLoadingPanel'
import {Button} from '@progress/kendo-react-buttons'
import {CenteredColumnHeader} from '../../../components/Grid/CenteredColumnHeader'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLayerPlus, faPencil, faTrash} from '@fortawesome/pro-regular-svg-icons'
import toast from 'react-hot-toast'
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog'
import axios from 'axios'
import {useLayout} from '../../../../_metronic/layout/core/LayoutProvider'
import {useGetHseFormGridData} from './HseOptionApi'
import {
  HseFormItemOptionModel,
  HseFormModel,
  HseFormOptionModel,
} from '../../../models/HSEFormModel'
import {PageActionSection, PageTitle} from '../../../../_metronic/layout/core'
import HseOptionFrom from './HseOptionForm'

import HseFormOptionItem from './HseFormOptionItem'

export default function HseOptionPage() {
  const queryClient = useQueryClient()

  const [gridHeight, setGridHeight] = useState<number>(0)
  const [searchText, setSearchText] = useState<string>('')
  const [dataState, setDataState] = useState<State>({
    take: 100,
    skip: 0,
  })
  const [skip, setSkip] = useState<number>(0)
  const buttonSize = 'medium'
  const pageSize = 50

  const initialEditState = {
    visible: false,
    mode: '',
    title: '',
    dataId: '',
  }
  const initialDeleteState = {
    visible: false,
    title: '',
    id: '',
  }

  const [editState, setEditState] = useState(initialEditState)
  const [deleteState, setDeleteState] = useState(initialDeleteState)

  const {status, data, isFetching, isLoading, isFetched, refetch} = useGetHseFormGridData({
    dataState: dataState,
    searchText: searchText,
  })

  function onGridStateChange(e: GridDataStateChangeEvent) {
    console.log(`onGridStateChange() skip: ${e.dataState.skip}`)
    setDataState(e.dataState)
  }

  function onBtnAddClick(data: any) {
    setEditState({
      visible: true,
      mode: 'add',
      title: 'Add HSE Option',
      dataId: '',
    })
  }

  function handleEditButtonClick(data: HseFormOptionModel) {
    console.log('hadleEditButtonClick(): ' + JSON.stringify(data))
    setEditState({
      visible: true,
      mode: 'edit',
      title: 'Edit HSE Option',
      dataId: data.hseFormOptionId,
    })
  }

  function handleAddFormClose() {
    setEditState({...editState, visible: false})
  }

  function handleFormSuccess(data: any, responseData: HseFormOptionModel) {
    console.log('handleFormSuccess() data = ', data, 'responseData = ', responseData)
    let message = ''
    if (editState.mode === 'add') {
      message = responseData.hseFormOptionName + ' has been added'
    } else {
      message = responseData.hseFormOptionName + ' has been updated'
    }
    toast.success(message)
    refetch()
    setEditState({...editState, visible: false})
  }

  // Delete functions

  function handleDeleteButtonClick(data: HseFormOptionModel) {
    setDeleteState({
      visible: true,
      title: data.hseFormOptionName ?? '',
      id: data.hseFormOptionId,
    })
  }

  function handleDeleteFormClose() {
    setDeleteState({...deleteState, visible: false})
  }

  function handleDeleteConfirmed() {
    setDeleteState({...deleteState, visible: false})
    const deletePromise = new Promise<HseFormOptionModel>((resolve, reject) => {
      let id = deleteState.id
      let url = '/api/HseFormOption/Delete?id=' + id
      axios
        .post(url, data)
        .then((response) => {
          console.log('response >>', response)
          if (response.data.success) {
            refetch()
            resolve(response.data.data)
          } else {
            reject(response.data.errorMessage)
            console.log(response.data.errorMessage)
          }
        })
        .catch((error) => {
          console.log(error)
          reject(error.response.data)
        })
    })
    toast.promise(
      deletePromise,
      {
        loading: 'Deleting..',
        success: (data) => `Successfully delete ${data.hseFormOptionName}`,
        error: (err) => `${err.toString()}`,
      },
      {
        style: {
          minWidth: '300px',
        },
        loading: {
          duration: 60000,
        },
        success: {
          duration: 5000,
          // icon: '🔥',
        },
        error: {
          duration: 5000,
          // icon: '🔥',
        },
      }
    )
  }

  function handleDeleteFormSuccess() {
    let message = 'Data has been deleted successfully'
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    refetch()
  }

  const actionCell = (props: GridCellProps) => {
    return (
      <td>
        <div className='menu-grid-cell'>
          <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-1'>
            <Button
              size={buttonSize}
              fillMode={'flat'}
              style={{width: '40px'}}
              title='Add Section'
              onClick={() => handleAddOptionItemButtonClick(props.dataItem)}
            >
              <FontAwesomeIcon icon={faLayerPlus} size='lg' />
            </Button>
            <Button
              size={buttonSize}
              fillMode={'flat'}
              style={{width: '40px'}}
              title='Edit'
              onClick={() => handleEditButtonClick(props.dataItem)}
            >
              <FontAwesomeIcon icon={faPencil} size='lg' />
            </Button>
            <Button
              size={buttonSize}
              fillMode={'flat'}
              style={{width: '40px'}}
              title='Delete'
              onClick={() => handleDeleteButtonClick(props.dataItem)}
            >
              <FontAwesomeIcon icon={faTrash} size='lg' />
            </Button>
          </div>
        </div>
      </td>
    )
  }

  const [hseFormOptionData, setHseFormOptionData] = useState<any[]>()
  const [hseFormOptionItemData, setHseFormOptionItemData] = useState<any[]>()

  useEffect(() => {
    setHseFormOptionData(data?.data)
  }, [data])

  const expandChangeOptionItem = (event: GridExpandChangeEvent) => {
    let hseFormOptionId = event.dataItem.hseFormOptionId

    data?.data.map((item: any) => {
      item.expanded = false
      item.details = []
      if (item.hseFormOptionId === event.dataItem.hseFormOptionId) {
        item.expanded = !event.dataItem.expanded
      }
      return item
    })

    let url = `api/HseFormOption/Item/GridData?optionId=` + hseFormOptionId
    axios
      .get(url)
      .then((response) => {
        let dt: any = hseFormOptionData?.slice()
        let index = dt.findIndex((d: any) => d.hseFormOptionId === hseFormOptionId)
        dt[index].details = response.data
        setHseFormOptionData(dt)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const DetailComponentOptionItem = (props: GridDetailRowProps) => {
    setHseFormOptionItemData(props.dataItem.details.data)
    if (hseFormOptionItemData) {
      return (
        <Grid data={hseFormOptionItemData}>
          <GridColumn field='hseFormOptionItemCode' title='Option Item Code' />
          <GridColumn field='label' title='Label' />
          <GridColumn field='description' title='Description' />
          <GridColumn field='value' title='Value' />
          <GridColumn
            field='hse_form_option_item_id'
            title={'Action'}
            width='200px'
            cell={actionCellOptionItem}
            className='text-center'
            headerCell={CenteredColumnHeader}
          />
        </Grid>
      )
    }
    return (
      <div style={{height: '50px', width: '100%'}}>
        <div style={{position: 'absolute', width: '100%'}}>
          <div className='k-loading-image' />
        </div>
      </div>
    )
  }

  const actionCellOptionItem = (props: GridCellProps) => {
    return (
      <td>
        <Button
          size={buttonSize}
          fillMode={'flat'}
          style={{width: '40px'}}
          title='Edit'
          onClick={() => handleEditOptionItemButtonClick(props.dataItem)}
        >
          <FontAwesomeIcon icon={faPencil} size='lg' />
        </Button>
        <Button
          size={buttonSize}
          fillMode={'flat'}
          style={{width: '40px'}}
          title='Delete'
          onClick={() => handleDeleteOptionItemButtonClick(props.dataItem)}
        >
          <FontAwesomeIcon icon={faTrash} size='lg' />
        </Button>
      </td>
    )
  }

  const initialEditOptionItemState = {
    visible: false,
    mode: '',
    title: '',
    hseFormOptionId: '',
    dataId: '',
  }

  const [editOptionItemState, setEditOptionItemState] = useState(initialEditOptionItemState)
  const [deleteOptionItemState, setDeleteOptionItemState] = useState(initialDeleteState)

  function handleAddOptionItemButtonClick(data: any) {
    setEditOptionItemState({
      visible: true,
      mode: 'add',
      title: 'Add Option Item',
      hseFormOptionId: data.hseFormOptionId,
      dataId: '',
    })
  }

  function handleEditOptionItemButtonClick(data: HseFormItemOptionModel) {
    setEditOptionItemState({
      visible: true,
      mode: 'edit',
      title: 'Edit OptionItem',
      hseFormOptionId: data.hseFormOptionId,
      dataId: data.hseFormOptionItemId,
    })
  }

  function handleOptionItemFormClose() {
    setEditOptionItemState({...editOptionItemState, visible: false})
  }

  function handleOptionItemFormSuccess(data: any, responseData: HseFormItemOptionModel) {
    let message = ''
    if (editState.mode === 'add') {
      message = responseData.label + ' has been added'
    } else {
      message = responseData.label + ' has been updated'
    }
    toast.success(message)
    refetch()
    setEditOptionItemState({...editOptionItemState, visible: false})
  }

  function handleDeleteOptionItemButtonClick(data: HseFormItemOptionModel) {
    setDeleteOptionItemState({
      visible: true,
      title: data.label ?? '',
      id: data.hseFormOptionItemId,
    })
  }

  function handleDeleteOptionItemFormClose() {
    setDeleteOptionItemState({...deleteOptionItemState, visible: false})
  }

  function handleDeleteOptionItemConfirmed() {
    setDeleteOptionItemState({...deleteOptionItemState, visible: false})
    const deletePromise = new Promise<HseFormItemOptionModel>((resolve, reject) => {
      let id = deleteOptionItemState.id
      let url = '/api/HseFormOption/Item/Delete?Id=' + id
      axios
        .post(url, data)
        .then((response) => {
          console.log('response >>', response)
          if (response.data.success) {
            refetch()
            resolve(response.data.data)
          } else {
            reject(response.data.errorMessage)
            console.log(response.data.errorMessage)
          }
        })
        .catch((error) => {
          console.log(error)
          reject(error.response.data)
        })
    })
    toast.promise(
      deletePromise,
      {
        loading: 'Deleting..',
        success: (data) => `Successfully delete ${data.hseFormOptionItemCode}`,
        error: (err) => `${err.toString()}`,
      },
      {
        style: {
          minWidth: '300px',
        },
        loading: {
          duration: 60000,
        },
        success: {
          duration: 5000,
          // icon: '🔥',
        },
        error: {
          duration: 5000,
          // icon: '🔥',
        },
      }
    )
  }

  return (
    <>
      <PageTitle>HSE Option</PageTitle>
      <PageActionSection>
        <div className='d-flex'>
          <Button className='btn-primary-jotun-yellow' size='large' onClick={onBtnAddClick}>
            Add
          </Button>
        </div>
      </PageActionSection>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body d-flex flex-column'>
          <Grid
            filterable={false}
            sortable={true}
            pageable={true}
            scrollable='scrollable'
            {...dataState}
            data={hseFormOptionData}
            skip={dataState.skip}
            take={dataState.take}
            total={data?.total}
            onDataStateChange={onGridStateChange}
            className='h-100'
            detail={DetailComponentOptionItem}
            expandField='expanded'
            onExpandChange={expandChangeOptionItem}
            style={{height: `${gridHeight - 32}px`}}
          >
            <GridColumn field='hseFormOptionName' title='HSE Code' sortable={true} />
            <GridColumn
              field='hseFormOptionId'
              title='Action'
              width='200px'
              cell={actionCell}
              className='text-center'
              headerCell={CenteredColumnHeader}
            />
          </Grid>

          {(isLoading || isFetching) && <GridLoadingPanel />}
          {editState.visible && (
            <HseOptionFrom
              mode={editState.mode}
              title={editState.title}
              dataId={editState.dataId}
              onClose={handleAddFormClose}
              onSuccess={handleFormSuccess}
            />
          )}
          {editOptionItemState.visible && (
            <HseFormOptionItem
              mode={editOptionItemState.mode}
              title={editOptionItemState.title}
              hseFormOptionId={editOptionItemState.hseFormOptionId}
              dataId={editOptionItemState.dataId}
              onClose={handleOptionItemFormClose}
              onSuccess={handleOptionItemFormSuccess}
            />
          )}
          {deleteState.visible && (
            <ConfirmationDialog
              title='Delete'
              message={"Remove '" + deleteState.title + "' from HSE option?"}
              confirmationType='yes-no'
              onClose={handleDeleteFormClose}
              onConfirmed={handleDeleteConfirmed}
            />
          )}
          {deleteOptionItemState.visible && (
            <ConfirmationDialog
              title='Delete'
              message={"Remove '" + deleteOptionItemState.title + "' from Item?"}
              confirmationType='yes-no'
              onClose={handleDeleteOptionItemFormClose}
              onConfirmed={handleDeleteOptionItemConfirmed}
            />
          )}
        </div>
      </div>
    </>
  )
}
