/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {callValidateAddToken, getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useIsAuthenticated, useMsal} from '@azure/msal-react'
import {loginRequest} from '../../../../authConfig'
import {InteractionRequiredAuthError, InteractionStatus} from '@azure/msal-browser'
import {AuthModel} from '../core/_models'
import {ServiceResult} from '../../../classes/ServiceResult'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload, faEye, faEyeSlash, faGlobe} from '@fortawesome/pro-regular-svg-icons'

const loginSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email('Wrong email format')
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Email is required'),
  userName: Yup.string().required('User Name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  userName: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const {instance, accounts, inProgress} = useMsal()
  const [apiData, setApiData] = useState(null)
  const [isLoginWithMicrosoft, setIsLoginWithMicrosoft] = useState<boolean>(false)
  // const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (
      isLoginWithMicrosoft &&
      !loading &&
      inProgress === InteractionStatus.None &&
      accounts.length > 0
    ) {
      setIsLoginWithMicrosoft(false)
      console.log('Logged in with MSAL')
      if (apiData) {
        // Skip data refresh if already set - adjust logic for your specific use case
        return
      }

      const tokenRequest = {
        account: accounts[0], // This is an example - Select account based on your app's requirements
        scopes: ['User.Read'],
      }
    }
  }, [isLoginWithMicrosoft, inProgress, accounts, instance, loading, apiData])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.userName, values.password)
        console.log('auth = ' + JSON.stringify(auth))
        saveAuth(auth)
        console.log('auth.authToken = ' + auth.authToken)
        const {data: user} = await getUserByToken(auth.authToken)
        console.log('user = ' + JSON.stringify(user))
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Invalid User Name or Password')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  function loginWithMicrosoft() {
    setIsLoginWithMicrosoft(true)
    instance.loginPopup(loginRequest)
  }

  const [passwordType, setPasswordType] = useState('password')

  function hadleShowButtonClick() {
    setPasswordType('text')
  }

  function hadleHideButtonClick() {
    setPasswordType('password')
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-5'>
        {/* <h1 className='text-dark mb-3'>LOGIN</h1> */}
        <img src={toAbsoluteUrl('/media/logos/smartsafetee-logo-text.svg')} className='h-70px' />
      </div>
      {/* <div className='text-center mb-5'>
            <h4 className='text-dark'>PT.HARAPAN HORM</h4>
         </div> */}
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //     continue.
        //   </div>
        // </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bold text-dark'>User Name</label>
        <input
          placeholder='UserName'
          {...formik.getFieldProps('userName')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.userName && formik.errors.userName},
            {
              'is-valid': formik.touched.userName && !formik.errors.userName,
            }
          )}
          // type='email'
          name='userName'
          autoComplete='off'
        />
        {formik.touched.userName && formik.errors.userName && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.userName}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bold text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bold'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mt-5'>
            <input
              type={passwordType}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {passwordType == 'password' ? (
              <span
                className='cursor-pointer'
                style={{paddingLeft: '5px'}}
                onClick={() => hadleShowButtonClick()}
                title={'show'}
              >
                <FontAwesomeIcon icon={faEye} size='lg' />
              </span>
            ) : (
              <span
                className='cursor-pointer'
                style={{paddingLeft: '5px'}}
                onClick={() => hadleHideButtonClick()}
                title={'hide'}
              >
                <FontAwesomeIcon icon={faEyeSlash} size='lg' />
              </span>
            )}
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary-smartSafetee-blue w-100 mb-5 '
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label text-white'>Log In</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {/* end::Action */}
      {/* <div className='row mt-2'>
            <span className='cursor-pointer' style={{paddingLeft: '0px'}}>
               <a href="https://marinedatafleetjotunid.blob.core.windows.net/marinefleetid/APK/JotunSecurity.apk" target="_blank">
                  Download Mobile App &nbsp;
                  <FontAwesomeIcon icon={faDownload} size='sm' /> 
               </a>
            </span>
         </div> */}
    </form>
  )
}
