import {PageActionSection, PageTitle} from '../../../../../_metronic/layout/core'
import UserForm from './UserForm'

export default function UserCreatePage() {
  return (
    <>
      <PageTitle>Add User</PageTitle>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body pt-9 pb-0 d-flex flex-column'>
          <UserForm mode='create' dataId='' />
        </div>
      </div>
    </>
  )
}
