import {PageActionSection, PageTitle} from '../../../../_metronic/layout/core'
import HazardForm from './HazardForm'

export default function PelaporanCreatePage() {
  return (
    <>
      <PageTitle>Add Hazard</PageTitle>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body pt-9 pb-0 d-flex flex-column'>
          <HazardForm mode='create' />
        </div>
      </div>
    </>
  )
}
