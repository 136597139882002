import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent, DropDownListFilterChangeEvent, } from '@progress/kendo-react-dropdowns'
import axios from 'axios'
import React, { CSSProperties, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IdTextTuple } from '../../models/IdTextTuple'

export interface DropDownListDataBindingProps {
    value?: string | null
    dataUrl: string
    className?: string
    filterable?: boolean
    addAllAsDefault?: boolean
    autoSelect?: boolean
    disabled?: boolean
    onChange?: (value: string | null, syntheticEvent: SyntheticEvent) => void
    onValueChange?: (value: string | null) => void
}

const delay = 500
const textField = 'text'
const idField = 'id'
const emptyItem = 'loading..'
const pageSize = 20

//const dataUrl = 'api/locationType/list'

export function DropDownListDataBinding(props: DropDownListDataBindingProps) {
    const [sourceData, setSourceData] = useState(new Array<IdTextTuple>())
    const [data, setData] = React.useState(new Array<IdTextTuple>())
    const [loading, setLoading] = React.useState(false)
    const { t } = useTranslation('translation')
    const timeout = React.useRef<any>()
    const componentRef = React.createRef<ComboBox>()

    const loadData = () => {
        axios
            .get(props.dataUrl)
            .then((response) => {
                let list = response.data;                
                if (props.addAllAsDefault) {
                    list.unshift({ id: '', text: '(All)' })
                }
                setSourceData(list)
                if (list.length > 0) {
                    const item = list.find((item: any) => item['id'] === props.value)
                    if (props.autoSelect && item === undefined) {
                        // change to first item
                        const item = list[0]
                        triggerChange(item.id)
                    }
                }
                setLoading(false)
            })
            .catch((error) => console.error(`Error: ${error}`))
    }

    useEffect(() => {
        loadData()
    }, [])

    function triggerChange(value: string | null, syntheticEvent?: SyntheticEvent) {
        if (props.onChange && syntheticEvent) props.onChange(value, syntheticEvent)
        if (props.onValueChange) props.onValueChange(value)
    }

{/* DropDownListChangeEvent ============================== */}
    const handleChange = (event: ComboBoxChangeEvent) => {
        if (event) {
            let value = event.target.value
            triggerChange(value ? value.id : null, event.syntheticEvent)
        }
    }

    const filterData = (value: string) => {
        if (value && value.length > 0) {
            let upperCase = value.toUpperCase()
            const localData = sourceData.slice().filter((rec) => {
                return rec.text.toUpperCase().includes(upperCase)
            })
            return localData
        } else {
            return sourceData.slice()
        }
    }


{/* DropDownListFilterChangeEvent ============================== */}
    const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }

        timeout.current = setTimeout(() => {
            console.log('event.filter = ' + JSON.stringify(event.filter))
            if (event.filter && event.filter.value) {
                setData(filterData(event.filter.value))
            } else {
                setData(sourceData.slice())
            }
            setLoading(false)
        }, delay)

        setLoading(true)
    }

    const itemFromValue = (value: any) => {
        let result = value !== null && value !== undefined
            ? sourceData.find((item: any) => item['id'] === value)
            : value
        //console.log('value: "' + value + '" item: ', result);
        return result;
    }

    function getData(filterable: boolean | undefined) {
        if (filterable === true) return data;
        else return sourceData;
    }

    

    return (
        <ComboBox
            data={getData(props.filterable)}
            textField='text'
            dataItemKey='id'
            value={itemFromValue(props.value)}
            filterable={props.filterable}
            onFilterChange={handleFilterChange}
            loading={loading}
            disabled={props.disabled}
            onChange={handleChange}
            ref={componentRef}
            className={props.className}
            //style={{ width: 250}}
        />
    )
}

