import {Route, Routes} from 'react-router-dom'
// import SopMainPage from './SopMainPage'
// import {SopCreatePage} from './SopCreatePage'
import SopNewMainPage from './SopNewMainPage'
import FileViewer from './FileViewer'

export default function SopPage() {
  return (
    <Routes>
      {/* <Route path=':companyId/*' element={<CompanyDetailPage  />} /> */}
      {/* <Route path='new' element={<SopCreatePage />} /> */}
      <Route index element={<SopNewMainPage />} />
      <Route path=':documentId/*' element={<FileViewer />} />
    </Routes>
  )
}
