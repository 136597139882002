import {DataResult, State, toDataSourceRequestString} from '@progress/kendo-data-query'
import {formatDate} from '@telerik/kendo-intl'
import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {ServiceResult} from '../../../classes/ServiceResult'
import {FolderModel} from '../../../models/SopModel'
import {UserModel} from '../../../models/UserModel'

interface GetFolderGridDataParam {
  dataState: State
  searchText: string | null
}
interface GetUserGridDataParam {
  searchText: string | null
}
interface GetFileGridDataParam {
  searchText: string | null
}

// Get Grid Data..
const getFolderGridData = async (params: GetFolderGridDataParam) => {
  //console.log(`Call getVoucherGridData().. skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `/api/Document/Folder/Griddata`

  const data = {
    searchText: params.searchText,
  }
  console.log(`getSiteGridData() queryString=${queryString}, searchText=${data.searchText}`)
  const response = await axios.get<DataResult>(url, {params: data})

  return response.data
}

function useGetFolderGridData(params: GetFolderGridDataParam) {
  console.log(`useGetUserGridData() skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(['privilages', params], () => getFolderGridData(params), {
    // enabled: false
  })
}

const getFileGridData = async (params: GetFileGridDataParam) => {
  let url = `/api/Document/Griddata`
  const dataFile = {
    searchText: params.searchText,
  }
  console.log(`getSiteGridData() searchText=${dataFile.searchText}`)
  const response = await axios.get<DataResult>(url, {params: dataFile})

  return response.data
}

function useGetFileGridData(params: GetFileGridDataParam) {
  return useQuery<DataResult, Error>(['privilages', params], () => getFileGridData(params), {
    // enabled: false
  })
}

// Get Grid Data..
const getUserGridData = async (params: GetUserGridDataParam) => {
  //console.log(`Call getVoucherGridData().. skip=${params.dataState.skip}`)

  let url = `/api/user/GridData`

  const data = {
    searchText: params.searchText,
  }
  const response = await axios.get<DataResult>(url, {params: data})
  console.log('response received')

  return response.data
}

function useGetUserGridData(params: GetUserGridDataParam) {
  return useQuery<DataResult, Error>(['privilages', params], () => getUserGridData(params), {
    // enabled: false
  })
}

// Get Detail data =======================
const getUserData = async (id: string) => {
  const url = `/api/User/${id}`
  const response = await axios.get<UserModel>(url)
  var data = response.data
  return data
}

const useGetUserData = (id: string) => {
  return useQuery<UserModel, Error>(
    ['privilage', id],
    async () => {
      return await getUserData(id)
    },
    {
      onSuccess: (data: UserModel) => {
        //console.log("Test3 :" + data)
      },
    }
  )
}

export {useGetFolderGridData, useGetFileGridData, useGetUserGridData}
