import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {PageActionSection, PageTitle} from '../../../../_metronic/layout/core'
import {Checkbox, Input, NumericTextBox} from '@progress/kendo-react-inputs'
import {DatePicker, DateTimePicker, TimePicker} from '@progress/kendo-react-dateinputs'
import {Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faImages,
  faPenToSquare,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons'
import {Upload} from '@progress/kendo-react-upload'
import CustomPopup from '../../../components/Dialog/CustomPopup'
import {DropDownList, MultiSelect, MultiSelectChangeEvent} from '@progress/kendo-react-dropdowns'
import {HookFormDropDownList} from '../../../components/ReactHookForm/HookFormDropDownList'
import AddAttachment from './popup/AddAttachment'

interface FormDataItem {
  hseFormName: string
  text: string
  items: any
  expanded: any
  description: string
}

const initialAddAttachment = {
  visible: false,
  title: '',
  dataId: '',
  incidentId: '',
}
const FormDataPage = () => {
  const navigate = useNavigate()
  const {hseFormId} = useParams()
  const [renderFormData, setRenderFormData] = useState<FormDataItem[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const sectionsPerPage = 1 // Adjust this to display more sections per page

  const [value, setValue] = useState<string[]>([])

  const [addAttach, setAddAttach] = useState(initialAddAttachment)

  function onBackClicked() {
    navigate('/work-inspection', {replace: true})
  }

  const getFormData = async (data: any) => {
    try {
      const url = `/api/HseForm/${data}`
      const res = await axios.get(url)
      const transformedData = [res.data]
      console.log('transsssssssss', transformedData)
      setRenderFormData(transformedData)
    } catch (error) {
      console.error('Error fetching form data:', error)
      return []
    }
  }

  useEffect(() => {
    getFormData(hseFormId)
    console.log(hseFormId)
  }, [hseFormId])

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
  }

  const paginatedData = renderFormData.map((data) => ({
    ...data,
    items: data.items.slice(currentPage * sectionsPerPage, (currentPage + 1) * sectionsPerPage),
  }))

  const onChange = (event: MultiSelectChangeEvent) => {
    setValue([...event.value])
  }
  console.log('VALUE', value)

  const handleAddNote = (item: any) => {}

  function handleAddAttachmentClicked(data: any) {
    setAddAttach({
      visible: true,
      title: 'Attachment',
      dataId: data,
      incidentId: data,
    })
  }
  function handleFormClose() {
    setAddAttach({...addAttach, visible: false})
  }

  function handleAddAttachmentSuccess() {
    setAddAttach({...addAttach, visible: false})
    // refetch()
  }

  return (
    <div>
      {paginatedData.map((data: any) => (
        <div key={data.hseFormCode}>
          <PageTitle>Inspection</PageTitle>
          <PageActionSection>
            <div className='d-flex'>
              <Button className='btn btn-secondary' size='sm' onClick={onBackClicked}>
                Back
              </Button>
            </div>
          </PageActionSection>
          <div className='card mb-5 flex-column-fluid' style={{backgroundColor: '#f3f3f3'}}>
            <div className='card-body d-flex flex-column'>
              <h3>{data.hseFormName}</h3>
              <span style={{color: '#545454'}}>{data.description}</span>
              <hr />
              {data.items &&
                data.items.map((item: any) => (
                  <div key={item.hseFormItemId}>
                    <div>
                      {item.isSection ? (
                        <center>
                          <div>
                            <h4>{item.label}</h4>
                            <span style={{color: '#545454'}}>{item.description}</span>
                          </div>
                        </center>
                      ) : (
                        ''
                      )}
                      {item.items &&
                        item.items.map((subItem: any) => (
                          <div
                            className='card shadow my-3 mx-lg-5 mx-md-5 mx-n5'
                            key={subItem.itemId}
                          >
                            <div className='card-body'>
                              <h6>
                                <li>{subItem.label}</li>
                              </h6>
                              <span>{subItem.description}</span>
                              <div className='mb-5'>
                                {subItem.inputType === 'TEXT' && (
                                  <div className='mb-5'>
                                    <Input className='form-control'></Input>
                                  </div>
                                )}

                                {subItem.inputType === 'MULTILINETEXT' && (
                                  <div className='mb-5'>
                                    <textarea className='form-control'></textarea>
                                  </div>
                                )}
                                {subItem.inputType === 'LOCATION' && (
                                  <div className='mb-5'>
                                    <textarea className='form-control'></textarea>
                                  </div>
                                )}
                                {subItem.inputType === 'NUMBER' && (
                                  <div className='mb-5'>
                                    <NumericTextBox
                                      format='n0'
                                      name='inputType'
                                      {...(subItem.numberMinValue !== null
                                        ? {min: subItem.numberMinValue}
                                        : {})}
                                      {...(subItem.numberMaxValue !== null
                                        ? {max: subItem.numberMaxValue}
                                        : {})}
                                    />
                                  </div>
                                )}
                                {subItem.inputType === 'DOUBLE' && (
                                  <div className='mb-5'>
                                    <NumericTextBox
                                      name='inputType'
                                      {...(subItem.numberMinValue !== null
                                        ? {min: subItem.numberMinValue}
                                        : {})}
                                      {...(subItem.numberMaxValue !== null
                                        ? {max: subItem.numberMaxValue}
                                        : {})}
                                    />
                                  </div>
                                )}
                                {subItem.inputType === 'IMAGE' && (
                                  <div className='mb-5'>
                                    <Upload className='form-control'></Upload>
                                  </div>
                                )}
                                {subItem.inputType === 'PHOTO' && (
                                  <div className='mb-5'>
                                    <input
                                      className='form-control'
                                      type='file'
                                      accept='image/*'
                                      capture
                                    ></input>
                                  </div>
                                )}
                                {subItem.inputType === 'DATE' && (
                                  <div className='mb-5'>
                                    <DatePicker
                                      name='value'
                                      format={'dd/MMM/yyyy'}
                                      {...(subItem.dateMinValue !== null
                                        ? {min: subItem.dateMinValue}
                                        : {})}
                                      {...(subItem.dateMaxValue !== null
                                        ? {max: subItem.dateMaxValue}
                                        : {})}
                                    />
                                  </div>
                                )}
                                {subItem.inputType === 'DATETIME' && (
                                  <div className='mb-5'>
                                    <DateTimePicker
                                      name='value'
                                      format={'dd/MMM/yyyy hh:mm'}
                                      placeholder='Date Time'
                                      popup={CustomPopup}
                                      {...(subItem.dateMinValue !== null
                                        ? {min: subItem.dateMinValue}
                                        : {})}
                                      {...(subItem.dateMaxValue !== null
                                        ? {max: subItem.dateMaxValue}
                                        : {})}
                                    />
                                  </div>
                                )}
                                {subItem.inputType === 'TIME' && (
                                  <div className='mb-5'>
                                    <TimePicker
                                      name='value'
                                      format={'hh:mm'}
                                      placeholder='Time'
                                      popup={CustomPopup}
                                      {...(subItem.dateMinValue !== null
                                        ? {min: subItem.dateMinValue}
                                        : {})}
                                      {...(subItem.dateMaxValue !== null
                                        ? {max: subItem.dateMaxValue}
                                        : {})}
                                    />
                                  </div>
                                )}
                                {subItem.inputType === 'RADIO' && (
                                  <div>
                                    {subItem.options.map((option: any) => {
                                      return (
                                        <div key={option.value}>
                                          <label className=' d-block'>
                                            <input
                                              className='me-1'
                                              type='radio'
                                              value={option.value}
                                              name={subItem.hseFormItemId}
                                            />
                                            {option.label}
                                          </label>
                                        </div>
                                      )
                                    })}
                                  </div>
                                )}
                                {subItem.inputType === 'DROPDOWN' && (
                                  <div>
                                    {subItem.options.map((option: any, idx: any) => {
                                      console.log('OPTIONs Index => ', idx, option)
                                    })}
                                    <div>
                                      <DropDownList
                                        data={subItem.options.map((option: any) => option.label)}
                                        name={`${value}`}
                                      />
                                    </div>
                                  </div>
                                )}
                                {subItem.inputType === 'CHECKBOX' && (
                                  <>
                                    {subItem.options.map((option: any) => {
                                      return (
                                        <span>
                                          <Checkbox id={option.value} className='me-3'>
                                            <label
                                              htmlFor={option.value}
                                              className={'k-checkbox-label'}
                                            >
                                              {option.label}
                                            </label>
                                          </Checkbox>{' '}
                                        </span>
                                      )
                                    })}
                                    {/* <Checkbox id={'chb1'}>
                                      {subItem.options.map((option: any) => {
                                        return (
                                          <label htmlFor={'chb1'} className={'k-checkbox-label'}>
                                            {option.label}
                                          </label>
                                        )
                                      })}
                                    </Checkbox> */}
                                  </>
                                )}
                                {subItem.inputType === 'COMBOBOX' && (
                                  <div>
                                    <div>
                                      <MultiSelect
                                        data={subItem.options.map((option: any) => option.label)}
                                        onChange={onChange}
                                        value={value}
                                        placeholder='Please select ...'
                                        name={`${value}`}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='d-flex justify-content-end mb-n5'>
                                <button
                                  className='btn btn-item btn-sm'
                                  // style={{backgroundColor: 'transparent', color: '#1c8ae7'}}

                                  onClick={() => handleAddNote(subItem.itemId)}
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} size='lg' /> Add Note
                                </button>
                                <button
                                  className='btn btn-item btn-sm'
                                  // style={{backgroundColor: 'transparent', color: '#1c8ae7'}}

                                  onClick={() => handleAddAttachmentClicked(subItem.itemId)}
                                >
                                  <FontAwesomeIcon icon={faImages} size='lg' /> Attach Media
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ))}
      <div className='pagination-controls'>
        <center>
          <div
            className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2'
            style={{width: '20%'}}
          >
            {/* <!-- left aligned controllers --> */}
            <div className='d-flex align-items-center mb-3'>
              <Button
                className='btn-sm'
                style={{width: '100px'}}
                onClick={handlePrevPage}
                disabled={currentPage === 0}
              >
                <FontAwesomeIcon icon={faChevronLeft} /> Previous
              </Button>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <Button
                className='btn-sm'
                style={{width: '100px'}}
                onClick={handleNextPage}
                disabled={
                  currentPage >= Math.ceil(renderFormData[0]?.items.length / sectionsPerPage) - 1
                }
              >
                Next
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          </div>
        </center>
      </div>

      <Button className='mb-5'>Submit</Button>
      {addAttach.visible && (
        <AddAttachment
          title={addAttach.title}
          onClose={handleFormClose}
          onSuccess={handleAddAttachmentSuccess}
          dataId={addAttach.dataId}
          incidentId={addAttach.incidentId}
        />
      )}
    </div>
  )
}

export default FormDataPage
