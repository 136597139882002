import {
  ItemRenderProps,
  TreeView,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
} from '@progress/kendo-react-treeview'
import {getName} from '../../../_metronic/helpers/helperMethods'
import {DataModel} from '../../models/FileManagerModels'
import {useEffect, useState} from 'react'
import axios from 'axios'

export const FolderTree = (props: any) => {
  const [data, setData] = useState<DataModel[]>([])

  useEffect(() => {
    // Fetch data from API when component mounts
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/Document/Folder/GridData')
      setData(response.data) // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleEdit = (event: React.ChangeEvent<HTMLInputElement>, item: DataModel) => {
    props.onItemEdit({
      item,
      event,
    })
  }

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>, item: DataModel) => {
    props.onItemBlur({
      item,
      event,
    })
  }

  const handleExpandChange = (event: TreeViewExpandChangeEvent) => {
    props.onItemClick({
      item: event.item,
      event,
    })
  }

  const handleItemClick = (event: TreeViewItemClickEvent) => {
    if (event.item) {
      props.onItemClick({
        item: event.item,
        event,
      })
    }
  }

  const handleContextMenu = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    item: DataModel
  ) => {
    event.preventDefault()
    props.onContextMenu({
      tree: true,
      dataItem: item,
      event,
    })
  }

  const TreeViewItem = (props: ItemRenderProps) => {
    if (props.item.edit) {
      return (
        <span>
          <input
            className='k-textbox'
            autoFocus
            value={props.item.path}
            onChange={(e) => handleEdit(e, props.item)}
            onBlur={(e) => handleBlur(e, props.item)}
          />
        </span>
      )
    }

    const name = getName(props.item.path)
    console.log('namee===>', props.item.path)
    return <span onContextMenu={(event) => handleContextMenu(event, props.item)}>{name}</span>
  }

  return (
    <TreeView
      data={props.data}
      item={TreeViewItem}
      className='k-filemanager-treeview'
      textField='path'
      expandIcons={true}
      onExpandChange={handleExpandChange}
      onItemClick={handleItemClick}
    />
  )
}
