import {PageActionSection, PageTitle} from '../../../../../_metronic/layout/core'
import PermissionForm from './PermissionForm'

export default function PermissionCreatePage() {
  return (
    <>
      <PageTitle>Add Permission</PageTitle>
      <div className='card mb-5 flex-column-fluid'>
        <div className='card-body pt-9 pb-0 d-flex flex-column'>
          <PermissionForm mode='create' dataId='' />
        </div>
      </div>
    </>
  )
}
