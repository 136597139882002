import {yupResolver} from '@hookform/resolvers/yup'
import {Button} from '@progress/kendo-react-buttons'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {HookFormInput} from '../../../../components/ReactHookForm/HookFormInput'
import {HookFormTextArea} from '../../../../components/ReactHookForm/HookFormTextArea'
import {HseFormItemModel} from '../../../../models/HSEFormModel'
import {HookFormNumericTextBox} from '../../../../components/ReactHookForm/HookFormNumericTextBox'
import {HookFormDropDownListWithRemoteData} from '../../../../components/ReactHookForm/HookFormDropDownListRemoteData'
import {HookFormSwitch} from '../../../../components/ReactHookForm/HookFormSwitch'
import {HookFormDropDownList} from '../../../../components/ReactHookForm/HookFormDropDownList'
import {IdTextTuple} from '../../../../models/IdTextTuple'
import {HookFormDatePicker} from '../../../../components/ReactHookForm/HookFormDatePicker'
import {DateTimePicker} from '@progress/kendo-react-dateinputs'
import {HookFormDateTimePicker} from '../../../../components/ReactHookForm/HookFormDateTimePicker'

interface HseFormItemProps {
  mode: string
  title: string
  hseFormId: string
  hseFormSectionId: string
  dataId: string
  hseFormItemCode: string
  onClose: () => void
  onSuccess: (data: any, responseData: any) => void
}

export default function HseFormItem(props: HseFormItemProps) {
  const {
    mode,
    title,
    hseFormId,
    hseFormSectionId,
    dataId,
    hseFormItemCode,
    onClose,
    onSuccess,
    ...others
  } = props
  const [formState, setFormState] = useState('init')
  const [errorMessage, setErrorMessage] = useState('')

  const [disableIsSection, setDisableIsSection] = useState(false)

  const NeedApprovalList = [
    {
      id: 'YES',
      text: 'Yes',
    },
    {
      id: 'NO',
      text: 'No',
    },
  ]
  const validationSchema = Yup.object().shape({
    label: Yup.string().required('Label is required'),
  })

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<HseFormItemModel>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (mode === 'edit') {
      loadData()
      setDisableIsSection(true)
    } else {
      reset({
        hseFormId: '',
        parentItemId: '',
        hseFormItemId: '',
        isSection: false,
        hseFormItemCode: '',
        number: 0,
        label: '',
        description: '',
        isMandatory: false,
        hseFormOptionId: '',
        inputType: '',
        needApprovalValue: '',
        passedValue: '',
      })
      setFormState('')
      setDisableIsSection(false)
    }
  }, [mode])

  function loadData() {
    console.log('loadData().. dataId = ' + dataId)
    setFormState('loading')
    let url = `api/HseForm/Item/${dataId}`
    axios
      .get(url)
      .then((response) => {
        //const data = response.data
        console.log('Response Data:', response.data)
        reset({
          hseFormId: response.data.hseFormId,
          parentItemId: response.data.parentItemId,
          hseFormItemId: response.data.hseFormItemId,
          isSection: response.data.isSection,
          hseFormItemCode: response.data.hseFormItemCode,
          number: response.data.number,
          label: response.data.label,
          description: response.data.description,
          dataType: response.data.dataType,
          inputType: response.data.inputType,
          numberMinValue: response.data.numberMinValue,
          numberMaxValue: response.data.numberMaxValue,
          textMaxLength: response.data.textMaxLength,
          dateMinValue: response.data.dateMinValue,
          dateMaxValue: response.data.dateMaxValue,

          hseFormOptionId: response.data.hseFormOptionId,
          passedValue: response.data.passedValue,
          needApprovalValue: response.data.needApprovalValue,
          isMandatory: response.data.isMandatory,
          value: response.data.value,
          status: response.data.status,
        })
        setFormState('')
        setinputTypValue(response.data.inputType)
        if (
          response.data.inputType == 'DROPDOWN' ||
          response.data.inputType == 'RADIO' ||
          response.data.inputType == 'COMBOBOX' ||
          response.data.inputType == 'CHECKBOX'
        ) {
          let dataUrl = `api/HseFormOption/Item/List?optionId=` + response.data.hseFormOptionId
          axios
            .get(dataUrl)
            .then((response) => {
              let list = response.data
              setOptionItemList(list)
              setOptionItemValue(response.data.passedValue)
            })
            .catch((error) => console.error(`Error: ${error}`))
        }
      })
      .catch((error) => {
        console.log(error)
        setErrorMessage('Failed to load data')
        setFormState('error')
      })
  }

  const onSubmit = handleSubmit((data) => {
    console.log('onSubmit() data = ', data)
    let url = 'api/HseForm/'
    if (mode === 'add') {
      url = url + 'AddItemOrSection'
      data.hseFormId = hseFormId
      data.parentItemId = hseFormSectionId
      data.hseFormItemId = ''
    } else {
      url = 'api/HseForm/Item/Update'
      data.hseFormId = hseFormId
      data.parentItemId = hseFormSectionId
      data.hseFormItemId = dataId
      data.hseFormItemCode = hseFormItemCode
    }
    data.passedValue = optionItemValue
    axios
      .post(url, data)
      .then((response) => {
        console.log('response >>', response)
        if (response.data.success) {
          setFormState('submitted')
          if (onSuccess) onSuccess(data, response.data.data)
        } else {
          setFormState('')
          setErrorMessage(response.data.errorMessage)
          console.log(response.data.errorMessage)
        }
      })
      .catch((error) => {
        console.log(error)
        setFormState('')
        setErrorMessage(error.response.data)
      })
  })

  const [inputTypValue, setinputTypValue] = useState<string>('')
  const [inputTypList, setInputTypeList] = useState(new Array<IdTextTuple>())

  useEffect(() => {
    let dataUrl = `/api/HseForm/InputTypeList`
    axios
      .get(dataUrl)
      .then((response) => {
        let list = response.data
        setInputTypeList(list)
      })
      .catch((error) => console.error(`Error: ${error}`))
  }, [])

  function onInputTypeChange(value: string | null) {
    setinputTypValue(value!)
  }

  function itemFromValueInputType(value: any) {
    return value !== null && value !== undefined
      ? inputTypList.find((item: any) => item['id'] === value)
      : value
  }

  const [optionValue, setOptionValue] = useState<string>('')
  const [optionList, setOptionList] = useState(new Array<IdTextTuple>())

  useEffect(() => {
    let dataUrl = `api/HseFormOption/List`
    axios
      .get(dataUrl)
      .then((response) => {
        let list = response.data
        setOptionList(list)
      })
      .catch((error) => console.error(`Error: ${error}`))
  }, [])

  function onOptionChange(value: string | null) {
    setOptionValue(value!)
  }

  function itemFromValueOption(value: any) {
    return value !== null && value !== undefined
      ? optionList.find((item: any) => item['id'] === value)
      : value
  }

  const [optionItemValue, setOptionItemValue] = useState<string>('')
  const [optionItemList, setOptionItemList] = useState(new Array<IdTextTuple>())

  useEffect(() => {
    let dataUrl = `api/HseFormOption/Item/List?optionId=` + optionValue
    axios
      .get(dataUrl)
      .then((response) => {
        let list = response.data
        setOptionItemList(list)
        console.log('listttt===>', list)
      })
      .catch((error) => console.error(`Error: ${error}`))
  }, [optionValue])

  function onOptionItemChange(value: string | null) {
    setOptionItemValue(value!)
  }

  function itemFromValueOptionItem(value: any) {
    return value !== null && value !== undefined
      ? optionItemList.find((item: any) => item['id'] === value)
      : value
  }

  return (
    <Dialog title={title} onClose={onClose}>
      <div className='w-100 h-100 d-flex flex-row gap-8 pb-3'>
        <div className='w-500px h-100'>
          <form id='myForm' onSubmit={onSubmit}>
            <div className='w-100 k-form k-form-horizontal'>
              {errorMessage && (
                <div className={'k-messagebox k-messagebox-error'}>{errorMessage}</div>
              )}
              <div className='row'>
                <div className='col-12'>
                  <HookFormNumericTextBox
                    name='number'
                    control={control}
                    label={'Number'}
                    disabled={mode == 'add'}
                  />
                  <HookFormInput name='label' control={control} label={'Item Label'} />
                  <HookFormTextArea
                    name='description'
                    control={control}
                    label={'Description'}
                    rows={2}
                  />

                  {/* <HookFormSwitch
                    name='isSection'
                    control={control}
                    label={'Section'}
                    disabled={disableIsSection}
                  /> */}

                  <HookFormInput name='hseFormItemCode' control={control} label={'Item Code'} />
                  {/* <HookFormInput name='dataType' control={control} label={'Data Type'} /> */}
                  <HookFormDropDownList
                    name='inputType'
                    control={control}
                    data={inputTypList}
                    label={'Input Type'}
                    value={itemFromValueInputType(inputTypValue)}
                    onChange={onInputTypeChange}
                  />
                </div>

                {(watch('inputType') === 'NUMBER' || watch('inputType') === 'DOUBLE') && (
                  <>
                    <HookFormNumericTextBox
                      name='numberMinValue'
                      control={control}
                      label={'Number Min Value'}
                      hideAsField={inputTypValue != 'NUMBER' && inputTypValue != 'DOUBLE'}
                    />
                    <HookFormNumericTextBox
                      name='numberMaxValue'
                      control={control}
                      label={'Number Max Value'}
                      hideAsField={inputTypValue != 'NUMBER' && inputTypValue != 'DOUBLE'}
                    />
                  </>
                )}
                {watch('inputType') === 'TEXT' && (
                  <HookFormNumericTextBox
                    name='textMaxLength'
                    control={control}
                    label={'Text Max Length'}
                    hideAsField={inputTypValue != 'TEXT'}
                  />
                )}
                {watch('inputType') === 'DATE' && (
                  <>
                    <HookFormDatePicker
                      name='dateMinValue'
                      control={control}
                      label={'Date Min Value'}
                      format={'dd/MMMM/yyyy'}
                      hideAsField={inputTypValue != 'DATE'}
                    />

                    <HookFormDatePicker
                      name='dateMaxValue'
                      control={control}
                      label={'Date Max Value'}
                      format={'dd/MMMM/yyyy'}
                    />
                  </>
                )}

                {watch('inputType') === 'DATETIME' && (
                  <>
                    <HookFormDateTimePicker
                      name='dateMinValue'
                      control={control}
                      label={'Date Min Value'}
                      format={'dd/MMMM/yyyy HH:mm'}
                    />

                    <HookFormDateTimePicker
                      name='dateMaxValue'
                      control={control}
                      label={'Date Max Value'}
                      format={'dd/MMMM/yyyy HH:mm'}
                    />
                  </>
                )}

                <HookFormDropDownList
                  name='hseFormOptionId'
                  control={control}
                  data={optionList}
                  label={'Option'}
                  value={itemFromValueOption(optionValue)}
                  onChange={onOptionChange}
                  hideAsField={
                    inputTypValue !== 'DROPDOWN' &&
                    inputTypValue !== 'RADIO' &&
                    inputTypValue !== 'CHECKBOX' &&
                    inputTypValue !== 'COMBOBOX'
                  }
                />
                <HookFormDropDownList
                  name='passedValue'
                  control={control}
                  label={'Passed Value'}
                  data={optionItemList}
                  value={itemFromValueOptionItem(optionItemValue)}
                  onChange={onOptionItemChange}
                  hideAsField={
                    inputTypValue != 'DROPDOWN' &&
                    inputTypValue != 'RADIO' &&
                    inputTypValue != 'CHECKBOX' &&
                    inputTypValue != 'COMBOBOX'
                  }
                />
                <HookFormDropDownList
                  name='needApprovalValue'
                  control={control}
                  label={'Need Approval'}
                  data={NeedApprovalList}
                />
                {/* <HookFormInput name='value' control={control} label={'Value'} /> */}
                {/* <HookFormInput name='status' control={control} label={'Status'} /> */}
                <HookFormSwitch name='isMandatory' control={control} label={'Mandatory'} />
              </div>
            </div>
          </form>
        </div>
      </div>
      <DialogActionsBar layout={'end'}>
        {formState !== 'error' && (
          <Button
            themeColor={'primary'}
            className='btn-primary '
            type={'submit'}
            form={'myForm'}
            style={{minWidth: 100}}
            //disabled={formState !== ''}
            onSubmit={onSubmit}
          >
            Save
            {/*{formState === 'submitting' ? `${t('Saving')}...` : 'OK'}*/}
          </Button>
        )}
        <Button onClick={onClose} style={{minWidth: 80}}>
          Cancel
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
