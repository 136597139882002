import {Dialog} from '@progress/kendo-react-dialogs'
import {PDFViewer} from '@progress/kendo-react-pdf-viewer'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Loader} from '@progress/kendo-react-indicators'
import {useAuth} from '../../../modules/auth'
import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer'

export default function FileViewer() {
  const [base64, setBase64] = useState('')
  const [loading, setLoading] = useState(true)

  const {currentUser} = useAuth()
  const stringObj = JSON.stringify(currentUser)
  const arryObj = JSON.parse(stringObj)

  const urlParams = new URLSearchParams(window.location.search)
  const docId = urlParams.get('docId')

  useEffect(() => {
    const fetchPdfAsBase64 = async () => {
      try {
        const response = await fetch(`${docId}`)
        const blob = await response.blob()
        const reader = new FileReader()
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            setBase64(reader.result)
            setLoading(false)
          }
        }
        reader.readAsDataURL(blob)
      } catch (error) {
        console.error('Error fetching PDF:', error)
        setLoading(false)
      }
    }

    fetchPdfAsBase64()
  }, [])

  const strUrl = `${docId}`
  const arrUrl = [{uri: strUrl}]

  const fileExtension = strUrl.split('.').pop()?.toLowerCase() ?? '' // Use optional chaining and nullish coalescing
  const isPdf = fileExtension === 'pdf'
  return (
    <>
      {loading ? ( // Render loading indicator if loading is true
        <div className='card mb-5 flex-column-fluid overflow-hidden'>
          <div className='card-body d-flex flex-column'>
            <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100 text-center'>
              <Loader type='pulsing' size='large' />
            </div>
          </div>
        </div>
      ) : (
        <div className='card flex-column-fluid overflow-hidden mb-5' style={{marginTop: '-80px'}}>
          <div>
            {isPdf ? (
              <PDFViewer
                data={base64}
                tools={
                  arryObj.userName !== 'admin'
                    ? ['search', 'spacer', 'zoom', 'zoomInOut']
                    : ['download', 'print', 'search', 'spacer', 'zoom', 'zoomInOut']
                }
              />
            ) : (
              <DocViewer
                documents={arrUrl}
                pluginRenderers={DocViewerRenderers}
                style={{height: '500px'}}
                config={{
                  header: {
                    disableFileName: false,
                  },
                  pdfVerticalScrollByDefault: true,
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
