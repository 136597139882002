import {Link, Outlet, useNavigate} from 'react-router-dom'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import GridLoadingPanel from '../../../components/Grid/GridLoadingPanel'
import {SearchTextBox} from '../../../components/Filters/SearchTextBox'
import {faEye, faFilePen, faPencil, faTrash} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useQueryClient} from 'react-query'
import {useTranslation} from 'react-i18next'
import {useState, useEffect, useRef} from 'react'
import {State} from '@progress/kendo-data-query'
import {Button, ToolbarItem} from '@progress/kendo-react-buttons'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import {Splitter, SplitterOnChangeEvent} from '@progress/kendo-react-layout'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import {useGetFileGridData, useGetFolderGridData, useGetUserGridData} from './SopNewApi'
import {FolderModel} from '../../../models/SopModel'
import {MyFormState} from '../../../classes/MyFormState'
import toast from 'react-hot-toast'
import DeleteDataDialog from '../../../components/Dialog/DeleteDataDialog'
import {faFolderClosed} from '@fortawesome/pro-duotone-svg-icons'
import {orderBy, SortDescriptor} from '@progress/kendo-data-query'
import {TreeView} from '@progress/kendo-react-treeview'
import {
  Breadcrumb,
  BreadcrumbLinkMouseEvent,
  BreadcrumbLinkKeyDownEvent,
} from '@progress/kendo-react-layout'
import {PDFViewer} from '@progress/kendo-react-pdf-viewer'
import FolderForm from './FolderCreateForm'
import FileForm from './FileCreateForm'
import {
  faEllipsisVertical,
  faExclamationCircle,
  faFilePdf,
} from '@fortawesome/pro-regular-svg-icons'
import {useAuth} from '../../../modules/auth'
import {CenteredColumnHeader} from '../../../components/Grid/CenteredColumnHeader'
import {DropDownList} from '@progress/kendo-react-dropdowns'
import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer'
import {useIntl} from 'react-intl'

interface DataModel {
  id: string
  text?: string
  icon?: React.ReactNode
  iconClass?: string
}

export interface UserListProps {
  mode: 'view' | 'delete'
  data?: FolderModel
}

interface TreeViewDataItem {
  text: string
  expanded?: boolean
  checked?: boolean
  selected?: boolean
  items?: TreeViewDataItem[]
}

const initialEditFolderState = {
  visible: false,
  mode: '',
  title: '',
  dataId: '',
  parentFolderId: '',
}
const initialEditFileState = {
  visible: false,
  mode: '',
  title: '',
  dataId: '',
  documentFolderId: '',
}

const initialDeleteState = {
  visible: false,
  title: '',
  url: '',
}

const initialSort: Array<SortDescriptor> = []

export default function SopNewMainPage() {
  const intl = useIntl()

  const {currentUser} = useAuth()
  const stringObj = JSON.stringify(currentUser)
  const arryObj = JSON.parse(stringObj)

  console.log('USER NOW =>', arryObj)

  const [sort, setSort] = useState(initialSort)
  const {classes} = useLayout()
  const queryClient = useQueryClient()
  const {t} = useTranslation('translation')
  const navigation = useNavigate()

  const [selectedMode, setSelectedMode] = useState('SOP')

  const [panes, setPanes] = useState<Array<any>>([{size: '20%', collapsible: false}, {}])

  const [formState, setFormState] = useState<MyFormState>('view')
  const [editState, setEditState] = useState(initialEditFolderState)
  const [editDocState, setEditDocState] = useState(initialEditFileState)
  const [deleteState, setDeleteState] = useState(initialDeleteState)
  const [searchText, setSearchText] = useState<string>('')
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  })

  const [selectedFolder, setSelectedFolder] = useState<FolderModel | null>(null) //
  const [selectedFolderId, setSelectedFolderId] = useState<string>('')
  const [parentFolder, setParentFolder] = useState<FolderModel[]>([])

  const [base64, setBase64] = useState('')
  const [urlDoc, setUrlDoc] = useState<any>([])
  const [visible, setVisible] = useState(false)
  const [visibleDialogFile, setVisibleDialogFile] = useState(false)
  const [visibleDialogFolder, setVisibleDialogFolder] = useState(false)
  const rightClickTarget = useRef<HTMLElement | null>(null)
  const toggleDialog = () => {
    setVisible(!visible)
    if (!visible) {
      setUrlDocSelected('')
    }
  }
  const togglePopup = (e: any) => {
    setVisibleDialogFile(!visibleDialogFile)
  }
  const toggleFolder = (e: any) => {
    setVisibleDialogFolder(!visibleDialogFolder)
  }
  function handleFileRightClick(e: React.MouseEvent<HTMLSpanElement>, dataItem: any) {
    e.preventDefault() // Mencegah munculnya menu konteks browser
    setFileSelected(dataItem.documentName)
    setUrlDocSelected(dataItem.filePath)
    setFileSelectedId(dataItem.documentId)
    setVisibleDialogFile(true)
    rightClickTarget.current = e.currentTarget
  }

  function handleFolderRightClick(e: React.MouseEvent<HTMLSpanElement>, dataItem: any) {
    e.preventDefault()
    setSelectedFolder(dataItem.name) // 2
    setVisibleDialogFolder(true)
    setSelectedFolderId(dataItem.id)

    rightClickTarget.current = e.currentTarget
  }

  // useEffect(() => {
  //   const handleOutsideClick = (e: any) => {
  //     // Jika yang diklik bukanlah target dari Popup atau child dari Popup
  //     if (!rightClickTarget.current?.contains(e.target)) {
  //       setVisiblePopup(false)
  //     }
  //   }

  //   // Menambahkan event listener ke window untuk menangani klik di luar area Popup
  //   window.addEventListener('click', handleOutsideClick)

  //   return () => {
  //     // Membersihkan event listener setelah komponen di-unmount
  //     window.removeEventListener('click', handleOutsideClick)
  //   }
  // }, [])

  const [fileSelected, setFileSelected] = useState<string>('')
  const [fileSelectedId, setFileSelectedId] = useState<string>('')
  const [urlDocSelected, setUrlDocSelected] = useState<string>('')

  // Function View Data in Data Grid
  const {status, data, isFetching, isLoading, isFetched, refetch} = useGetFolderGridData({
    dataState: dataState,
    searchText: searchText,
  })

  const {data: fileData, refetch: refetchFile} = useGetFileGridData({
    searchText: searchText,
  })

  const {data: userData} = useGetUserGridData({
    searchText: null,
  })

  // Fungsi untuk mendapatkan nama pengguna berdasarkan ID pengguna
  function getUserNameById(userId: any) {
    if (!userData) return '' // Jika data pengguna belum tersedia, kembalikan string kosong
    const user = userData.data.find((user: any) => user.userId === userId) // Cari pengguna dengan ID yang sesuai
    return user ? user.userFullName : '' // Jika pengguna ditemukan, kembalikan nama pengguna, jika tidak, kembalikan string kosong
  }

  useEffect(() => {
    if (data) {
      const filteredFolders = data.data
        .filter((folder) => folder.parentFolderId === null && folder.isDeleted === false)
        .sort((a: FolderModel, b: FolderModel) => a.folderName.localeCompare(b.folderName))
      setParentFolder(filteredFolders)
    }
  }, [data])

  const filteredDataFolder =
    data?.data?.filter((item: any) => {
      const urlParams = new URLSearchParams(window.location.search)
      const parentFolderIdParam = urlParams.get('folderId')

      if (!parentFolderIdParam && !item.parentFolderId && !item.isDeleted) {
        // Jika tidak ada parameter parentFolderId, parentFolderId pada data null, dan isDeleted false, tampilkan
        return true
      } else if (
        parentFolderIdParam &&
        item.parentFolderId &&
        item.parentFolderId.toString() === parentFolderIdParam &&
        !item.isDeleted
      ) {
        // Jika ada parameter parentFolderId, parentFolderId pada data sama dengan parameter, dan isDeleted false, tampilkan
        return true
      }
      return false
    }) || []

  const filteredFolders = data?.data.filter((folder) => folder.parentFolderId === null)

  const filteredFileData =
    fileData?.data?.filter((file: any) => {
      const urlParams = new URLSearchParams(window.location.search)
      const folderIdParam = urlParams.get('folderId')

      if (!folderIdParam && !file.documentFolderId && !file.isDeleted) {
        return true
      } else if (
        folderIdParam &&
        file.documentFolderId &&
        file.documentFolderId.toString() === folderIdParam &&
        !file.isDeleted
      ) {
        return true
      }
      return false
    }) || []

  const filterExpiredFile =
    fileData?.data.filter((file: any) => {
      if (!file.isDeleted && file.validUntil && new Date(file.validUntil) <= new Date()) {
        return true
      }
      return false
    }) || []

  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState)
  }

  function onGridStateChange(e: GridDataStateChangeEvent) {
    setDataState(e.dataState)
  }

  function convertFolderDataToSameStructure(data: FolderModel[]): any[] {
    return data.map((item) => ({
      type: 'folder',
      id: item.id,
      name: item.folderName,
      parentFolderId: item.parentFolderId,
      createdDate: item.createdDate,
      createdBy: item.createdBy,
      lastModifiedDate: item.lastModifiedDate,
      lastModifiedBy: item.lastModifiedBy,
    }))
  }

  // Fungsi untuk menggabungkan data folder dan data file
  function mergeFolderAndFileData(folderData: any[], fileData: any[]): any[] {
    return [...folderData, ...fileData]
  }

  // Gunakan fungsi convertFolderDataToSameStructure untuk mengubah struktur data folder
  const convertedFolderData = convertFolderDataToSameStructure(filteredDataFolder)

  // Gabungkan data folder dan data file
  const mergedData = mergeFolderAndFileData(convertedFolderData, filteredFileData || [])

  {
    /* Function Search Data ====================*/
  }
  function onSearchTextChange(value: string) {
    console.log('search:', value)
    setSearchText(value)
  }

  {
    /* Function Action ========================= */
  }

  function handleEditFolderButtonClick(data: any) {
    const urlParams = new URLSearchParams(window.location.search)
    const parentFolderIdParam = urlParams.get('folderId')
    setEditState({
      visible: true,
      mode: 'edit',
      title: t(`Edit Folder ${selectedFolder}`),
      dataId: selectedFolderId,
      parentFolderId: parentFolderIdParam || '',
    })
  }

  function handleFolderDeleteButtonClick(data: any) {
    setDeleteState({
      visible: true,
      title: `Delete Folder ${selectedFolder}`,
      url: `api/Document/Folder/Delete?id=${selectedFolderId}`,
    })
  }

  function openFileNewTab(data: any) {
    const newTab = window.open(`sop/${fileSelectedId}?docId=${urlDocSelected}`, '_blank')
    if (newTab) {
      newTab.focus()
    }
  }

  function handleEditFileButtonClick(data: any) {
    const urlParams = new URLSearchParams(window.location.search)
    const parentFolderIdParam = urlParams.get('folderId')
    setEditDocState({
      visible: true,
      mode: 'edit',
      title: t(`Edit File ${fileSelected}`),
      dataId: fileSelectedId,
      documentFolderId: parentFolderIdParam || '',
    })
  }

  function handleFileDeleteButtonClick(data: any) {
    setDeleteState({
      visible: true,
      title: `Delete File ${fileSelected}`,
      url: 'api/Document/Delete?id=' + fileSelectedId,
    })
  }

  function handleFormClose() {
    setDeleteState({...deleteState, visible: false})
  }

  function handleEditFormSuccess() {
    let message = ''
    if (editState.mode === 'add') {
      message = t('Add Data Successful')
    } else {
      message = t('Update Data Successful')
    }
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    refetch()
    refetchFile()
  }
  function handleDeleteFormSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    refetch()
    refetchFile()
    setVisibleDialogFile(false)
  }
  function handleAddFormClose() {
    setEditState({...editState, visible: false})
    setEditDocState({...editDocState, visible: false})
  }

  function handleAddFolderButtonClick(data: any) {
    const urlParams = new URLSearchParams(window.location.search)
    const parentFolderIdParam = urlParams.get('folderId')
    setEditState({
      visible: true,
      mode: 'add',
      title: `${intl.formatMessage({id: 'TEXT.ADD'})} Folder`,
      parentFolderId: parentFolderIdParam || '',
      dataId: '',
    })
  }
  function handleAddFileButtonClick(data: any) {
    const urlParams = new URLSearchParams(window.location.search)
    const parentFolderIdParam = urlParams.get('folderId')
    setEditDocState({
      visible: true,
      mode: 'add',
      title: `${intl.formatMessage({id: 'TEXT.ADD'})} File`,
      documentFolderId: parentFolderIdParam || '',
      dataId: '',
    })
  }

  // Fungsi untuk mengubah struktur data menjadi format yang sesuai untuk TreeView
  function buildTreeData(data: FolderModel[]): TreeViewDataItem[] {
    return data.map((item) => ({
      text: item.folderName,
    }))
  }

  const treeData = buildTreeData(parentFolder)

  function handleFileClick(dataItem: any) {
    setFileSelected(dataItem.documentName)
    setUrlDocSelected(dataItem.filePath)
    setVisible(true)
  }

  function handleRightClick(e: any) {
    if (e.type === 'click') {
      console.log('Left click')
    } else if (e.type === 'contextmenu') {
      console.log('Right click')

      setVisibleDialogFile(true)
    }
  }

  useEffect(() => {
    const fetchPdfAsBase64 = async () => {
      try {
        if (fileSelected && visible) {
          const response = await fetch(`${urlDocSelected}`)
          const blob = await response.blob()
          const reader = new FileReader()
          reader.onloadend = () => {
            if (typeof reader.result === 'string') {
              setBase64(reader.result)
            }
          }
          reader.readAsDataURL(blob)
        } else {
          setBase64('')
        }
      } catch (error) {
        console.error('Error fetching PDF:', error)
      }
    }

    fetchPdfAsBase64()
  }, [fileSelected, urlDocSelected, visible])

  const arrUrl = [{uri: urlDocSelected}]
  useEffect(() => {
    const getUrl = async () => {
      try {
        if (fileSelected && visible) {
          const arrUrl = [{uri: urlDocSelected}]
          setUrlDoc(arrUrl)
        }
      } catch (error) {
        console.log(error)
      }
    }
    getUrl()
  }, [fileSelected, urlDocSelected, visible])

  const strUrl = `${urlDocSelected}`
  const fileExtension = strUrl.split('.').pop()?.toLowerCase() ?? '' // Use optional chaining and nullish coalescing
  const isPdf = fileExtension === 'pdf'

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'SUBMENU.SOP'})}</PageTitle>

      <div className='card mb-5 flex-column-fluid overflow-hidden'>
        <div className='card-body d-flex flex-column'>
          <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
            {/* <!-- left aligned controllers --> */}
            <div className='d-flex align-items-center mb-3'>
              <ToolbarItem>
                <DropDownList
                  data={['SOP', 'Expired Document']}
                  value={selectedMode}
                  onChange={(e: any) => setSelectedMode(e.target.value)}
                  className={'k-toolbar-dropdown'}
                  popupSettings={{
                    popupClass: 'k-toolbar-popup',
                  }}
                  style={{width: '160px'}}
                />
              </ToolbarItem>
            </div>

            {/* <!-- right aligned controllers --> */}
            <div className='d-flex align-items-center mb-3'>
              <SearchTextBox onChange={onSearchTextChange} value={searchText}></SearchTextBox>
              {selectedMode === 'SOP' && arryObj.userName === 'admin' && (
                <>
                  <Button
                    className='btn-primary-jotun-yellow me-2'
                    onClick={handleAddFolderButtonClick}
                  >
                    {intl.formatMessage({id: 'BTN.NEWFOLDER'})}
                  </Button>
                  <Button onClick={handleAddFileButtonClick}>
                    {' '}
                    {intl.formatMessage({id: 'BTN.UPLOAD'})}
                  </Button>
                </>
              )}
            </div>
          </div>

          {/* <Splitter style={{height: 400}} panes={panes} onChange={onChange}>
            <div className='m-3'>
              <h4>Home</h4>
              <TreeView data={treeData} />
            </div> */}

          <>
            <div id='gridContainer' className='w-100 flex-grow-1 overflow-hidden h-400px'>
              {selectedMode === 'SOP' && (
                <Grid
                  filterable={false}
                  sortable={true}
                  scrollable='scrollable'
                  {...dataState}
                  data={orderBy(mergedData, sort)}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort)
                  }}
                  onDataStateChange={onGridStateChange}
                  className='h-100'
                  style={{border: '0'}}
                >
                  <GridColumn
                    field='name'
                    title={intl.formatMessage({id: 'TEXT.NAME'})}
                    cell={(props) => (
                      <td>
                        <div className='d-flex align-items-center'>
                          {props.dataItem.type === 'folder' ? (
                            <span
                              onDoubleClick={() => navigation(`?folderId=${props.dataItem.id}`)}
                              className='clickable-grid-cell file-data'
                              onContextMenu={(e) => handleFolderRightClick(e, props.dataItem)}
                            >
                              <FontAwesomeIcon icon={faFolderClosed} className='me-2' />
                              {props.dataItem.name}
                            </span>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faFilePdf} className='me-2' />
                              <span
                                className='clickable-grid-cell file-data'
                                onDoubleClick={() => handleFileClick(props.dataItem)}
                                // onClick={handleRightClick}
                                onContextMenu={(e) => handleFileRightClick(e, props.dataItem)}
                              >
                                {props.dataItem.documentName}
                              </span>
                              {props.dataItem.validUntil &&
                                new Date(props.dataItem.validUntil) < new Date() && ( // Check apakah validUntil sudah lewat
                                  <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className='text-danger ms-2'
                                    title='Expired Document'
                                    style={{cursor: 'pointer'}}
                                  />
                                )}
                            </>
                          )}
                        </div>
                      </td>
                    )}
                  />
                  <GridColumn
                    field='createdDate'
                    title={intl.formatMessage({id: 'TEXT.CREATEDDATE'})}
                    cell={(props) => (
                      <td>
                        {`${new Date(props.dataItem.createdDate)
                          .getDate()
                          .toString()
                          .padStart(2, '0')}/${(new Date(props.dataItem.createdDate).getMonth() + 1)
                          .toString()
                          .padStart(2, '0')}/${new Date(
                          props.dataItem.createdDate
                        ).getFullYear()}, ${new Date(props.dataItem.createdDate)
                          .getHours()
                          .toString()
                          .padStart(2, '0')}:${new Date(props.dataItem.createdDate)
                          .getMinutes()
                          .toString()
                          .padStart(2, '0')}:${new Date(props.dataItem.createdDate)
                          .getSeconds()
                          .toString()
                          .padStart(2, '0')}`}
                      </td>
                    )}
                    width={170}
                  />
                  <GridColumn
                    field='lastModifiedDate'
                    title={intl.formatMessage({id: 'TEXT.LASTMODIFIED'})}
                    cell={(props) => (
                      <td>
                        {`${new Date(props.dataItem.lastModifiedDate)
                          .getDate()
                          .toString()
                          .padStart(2, '0')}/${(
                          new Date(props.dataItem.lastModifiedDate).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, '0')}/${new Date(
                          props.dataItem.lastModifiedDate
                        ).getFullYear()}, ${new Date(props.dataItem.lastModifiedDate)
                          .getHours()
                          .toString()
                          .padStart(2, '0')}:${new Date(props.dataItem.lastModifiedDate)
                          .getMinutes()
                          .toString()
                          .padStart(2, '0')}:${new Date(props.dataItem.lastModifiedDate)
                          .getSeconds()
                          .toString()
                          .padStart(2, '0')}`}
                      </td>
                    )}
                    width={170}
                  />

                  <GridColumn
                    field='lastModifiedBy'
                    title={intl.formatMessage({id: 'TEXT.MODIFIEDBY'})}
                    cell={(props) => <td>{getUserNameById(props.dataItem.lastModifiedBy)}</td>}
                    width={150}
                  />

                  <GridColumn
                    title={intl.formatMessage({id: 'TEXT.ACTION'})}
                    cell={(props) => (
                      <td
                        className='text-center'
                        style={{cursor: 'pointer'}}
                        onClick={(e) => {
                          if (props.dataItem.type === 'folder') {
                            handleFolderRightClick(e, props.dataItem)
                          } else {
                            handleFileRightClick(e, props.dataItem)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </td>
                    )}
                    headerCell={CenteredColumnHeader}
                    width={70}
                  />
                </Grid>
              )}

              {selectedMode === 'Expired Document' && (
                <div id='gridContainer' className='w-100 flex-grow-1 overflow-hidden h-400px'>
                  <Grid
                    data={filterExpiredFile}
                    style={{border: '0'}}
                    onDataStateChange={onGridStateChange}
                  >
                    <GridColumn
                      field='documentName'
                      title={intl.formatMessage({id: 'TEXT.NAME'})}
                      cell={(props) => (
                        <td>
                          <span
                            className='clickable-grid-cell file-data'
                            onDoubleClick={() => handleFileClick(props.dataItem)}
                            // onClick={handleRightClick}
                            onContextMenu={(e) => handleFileRightClick(e, props.dataItem)}
                          >
                            {props.dataItem.documentName}
                          </span>
                          {props.dataItem.validUntil &&
                            new Date(props.dataItem.validUntil) < new Date() && ( // Check apakah validUntil sudah lewat
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className='text-danger ms-2'
                                title='Expired Document'
                                style={{cursor: 'pointer'}}
                              />
                            )}
                        </td>
                      )}
                    />
                    <GridColumn
                      field='createdDate'
                      title={intl.formatMessage({id: 'TEXT.CREATEDDATE'})}
                      cell={(props) => (
                        <td>
                          {`${new Date(props.dataItem.createdDate)
                            .getDate()
                            .toString()
                            .padStart(2, '0')}/${(
                            new Date(props.dataItem.createdDate).getMonth() + 1
                          )
                            .toString()
                            .padStart(2, '0')}/${new Date(
                            props.dataItem.createdDate
                          ).getFullYear()}, ${new Date(props.dataItem.createdDate)
                            .getHours()
                            .toString()
                            .padStart(2, '0')}:${new Date(props.dataItem.createdDate)
                            .getMinutes()
                            .toString()
                            .padStart(2, '0')}:${new Date(props.dataItem.createdDate)
                            .getSeconds()
                            .toString()
                            .padStart(2, '0')}`}
                        </td>
                      )}
                      width={170}
                    />
                    <GridColumn
                      field='lastModifiedDate'
                      title={intl.formatMessage({id: 'TEXT.LASTMODIFIED'})}
                      cell={(props) => (
                        <td>
                          {`${new Date(props.dataItem.lastModifiedDate)
                            .getDate()
                            .toString()
                            .padStart(2, '0')}/${(
                            new Date(props.dataItem.lastModifiedDate).getMonth() + 1
                          )
                            .toString()
                            .padStart(2, '0')}/${new Date(
                            props.dataItem.lastModifiedDate
                          ).getFullYear()}, ${new Date(props.dataItem.lastModifiedDate)
                            .getHours()
                            .toString()
                            .padStart(2, '0')}:${new Date(props.dataItem.lastModifiedDate)
                            .getMinutes()
                            .toString()
                            .padStart(2, '0')}:${new Date(props.dataItem.lastModifiedDate)
                            .getSeconds()
                            .toString()
                            .padStart(2, '0')}`}
                        </td>
                      )}
                      width={170}
                    />
                    <GridColumn
                      field='validUntil'
                      title={intl.formatMessage({id: 'TEXT.EXPIREDDATE'})}
                      cell={(props) => (
                        <td>
                          {`${new Date(props.dataItem.validUntil)
                            .getDate()
                            .toString()
                            .padStart(2, '0')}/${(
                            new Date(props.dataItem.validUntil).getMonth() + 1
                          )
                            .toString()
                            .padStart(2, '0')}/${new Date(
                            props.dataItem.validUntil
                          ).getFullYear()}`}
                        </td>
                      )}
                      width={120}
                    />
                    <GridColumn
                      field='lastModifiedBy'
                      title={intl.formatMessage({id: 'TEXT.MODIFIEDBY'})}
                      cell={(props) => <td>{getUserNameById(props.dataItem.lastModifiedBy)}</td>}
                      width={150}
                    />
                  </Grid>
                </div>
              )}

              {(isLoading || isFetching) && <GridLoadingPanel />}
              {editState.visible && (
                <FolderForm
                  mode={editState.mode}
                  title={editState.title}
                  dataId={editState.dataId}
                  onClose={handleAddFormClose}
                  onSuccess={() => {
                    handleEditFormSuccess()
                    setVisibleDialogFolder(false)
                  }}
                  parentFolderId={editState.parentFolderId}
                />
              )}
              {editDocState.visible && (
                <FileForm
                  mode={editDocState.mode}
                  title={editDocState.title}
                  dataId={editDocState.dataId}
                  onClose={handleAddFormClose}
                  onSuccess={() => {
                    handleEditFormSuccess()
                    setVisibleDialogFile(false)
                    refetchFile()
                  }}
                  documentFolderId={editState.parentFolderId}
                />
              )}

              {deleteState.visible && (
                <DeleteDataDialog
                  title={deleteState.title}
                  url={deleteState.url}
                  onClose={handleFormClose}
                  onSuccess={() => {
                    handleDeleteFormSuccess()
                    setVisibleDialogFolder(false)
                  }}
                />
              )}
              {visible && (
                <Dialog title={fileSelected} height={500} width={900} onClose={toggleDialog}>
                  {isPdf ? (
                    <PDFViewer
                      data={base64}
                      style={{height: '500'}}
                      tools={
                        arryObj.userName !== 'admin'
                          ? ['search', 'spacer', 'zoom', 'zoomInOut']
                          : ['download', 'print', 'search', 'spacer', 'zoom', 'zoomInOut']
                      }
                    />
                  ) : (
                    <DocViewer
                      documents={urlDoc}
                      pluginRenderers={DocViewerRenderers}
                      config={{
                        header: {
                          disableFileName: true,
                          retainURLParams: false,
                        },
                        pdfVerticalScrollByDefault: true,
                      }}
                      style={{height: '450px', marginTop: '-50px'}}
                    />
                  )}
                </Dialog>
              )}

              {visibleDialogFolder && arryObj.userName === 'admin' && (
                <Dialog title={`${selectedFolder}`} onClose={toggleFolder} height={150} width={200}>
                  <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    {}
                    <Button
                      onClick={() => {
                        handleEditFolderButtonClick(rightClickTarget.current)
                      }}
                    >
                      {intl.formatMessage({id: 'TEXT.RENAME'})}
                    </Button>
                    <Button
                      onClick={() => {
                        // Panggil fungsi handleDeleteButtonClick saat tombol "Delete" diklik
                        handleFolderDeleteButtonClick(rightClickTarget.current)
                      }}
                    >
                      {intl.formatMessage({id: 'TEXT.DELETE'})}
                    </Button>
                  </div>
                </Dialog>
              )}
              {visibleDialogFile && (
                <Dialog title={`${fileSelected}`} onClose={togglePopup}>
                  <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Button
                      onClick={() => {
                        // handleEditFileButtonClick(rightClickTarget.current)

                        openFileNewTab(rightClickTarget.current)
                      }}
                    >
                      Open New Tab
                    </Button>
                    {arryObj.userName === 'admin' && (
                      <>
                        <Button
                          onClick={() => {
                            handleEditFileButtonClick(rightClickTarget.current)
                          }}
                        >
                          {intl.formatMessage({id: 'TEXT.RENAME'})}
                        </Button>
                        <Button
                          onClick={() => {
                            // Panggil fungsi handleDeleteButtonClick saat tombol "Delete" diklik
                            handleFileDeleteButtonClick(rightClickTarget.current)
                          }}
                        >
                          {intl.formatMessage({id: 'TEXT.DELETE'})}
                        </Button>
                      </>
                    )}
                  </div>
                </Dialog>
              )}
            </div>
          </>

          {/* </Splitter> */}
        </div>
      </div>
    </>
  )
}
