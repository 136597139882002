import _ from 'lodash'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {useAuth} from '../../../../app/modules/auth'

function usePrivilege() {
  const {currentUser} = useAuth()
  const stringObj = JSON.stringify(currentUser)
  const arryObj = JSON.parse(stringObj)
  console.log(currentUser)

  const hasPrivilege = useCallback(
    (permissions: string) => {
      if (!currentUser || !arryObj.permissions) return false
      if (permissions === '*') return true

      return _.findIndex(arryObj.permissions, (val) => val === '*' || val === permissions) !== -1
    },
    [arryObj?.permissions]
  )

  return {
    hasPrivilege: hasPrivilege,
  }
}

export default usePrivilege
