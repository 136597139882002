import {DataResult, State, toDataSourceRequestString} from '@progress/kendo-data-query'
import {formatDate} from '@telerik/kendo-intl'
import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {RoleModel} from '../../../../models/RoleModel'

interface GetRoleGridDataParam {
  dataState: State
  searchText: string | null
}

const getRoleGridData = async (params: GetRoleGridDataParam) => {
  let queryString = toDataSourceRequestString(params.dataState)
  let url = `/api/Role/GridData?${queryString}`
  console.log('Data Grid :' + queryString)
  const data = {
    searchText: params.searchText,
  }
  console.log(`getRoleGridData() queryString=${queryString}, searchText=${data.searchText}`)
  const response = await axios.get<DataResult>(url, {params: data})
  console.log('response received')
  return response.data
}

function useGetRoleGridData(params: GetRoleGridDataParam) {
  console.log(`useGetRoleGridData() skip=${params.dataState.skip}`)
  let queryString = toDataSourceRequestString(params.dataState)
  return useQuery<DataResult, Error>(['roles', params], () => getRoleGridData(params), {
    // enabled: false
  })
}

const getRoleData = async (id: string) => {
  const url = `/api/Role/${id}`
  const response = await axios.get<RoleModel>(url)
  var data = response.data
  return data
}

const useGetRoleData = (id: string) => {
  return useQuery<RoleModel, Error>(
    ['role', id],
    async () => {
      return await getRoleData(id)
    },
    {
      onSuccess: (data: RoleModel) => {},
    }
  )
}

// Add Data ====================================
const callAddRole = async (data: RoleModel) => {
  const url = 'api/role/add'
  console.log('URL: ', url)
  const response = await axios.post<ServiceResult<RoleModel>>(url, data)
  console.log('Response: ', response)
  return response.data
}

const useAddRole = () => {
  const queryClient = useQueryClient()
  return useMutation((data: RoleModel) => callAddRole(data), {
    onSuccess: (response: ServiceResult<RoleModel>) => {
      queryClient.invalidateQueries(['roles'])
    },
  })
}

// Update Data ====================================
const callUpdateRole = async (data: RoleModel) => {
  console.log('callUpdateUser()..')
  const url = 'api/role/update'
  const response = await axios.post<ServiceResult<RoleModel>>(url, data)
  return response.data
}

const useUpdateRole = () => {
  const queryClient = useQueryClient()
  return useMutation((data: RoleModel) => callUpdateRole(data), {
    onSuccess: (response: ServiceResult<RoleModel>) => {
      queryClient.invalidateQueries(['roles'])
    },
  })
}

export {useGetRoleGridData, useGetRoleData, useAddRole, useUpdateRole}
