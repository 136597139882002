import {Button} from '@progress/kendo-react-buttons'
import toast from 'react-hot-toast'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MyFormState} from '../../../../classes/MyFormState'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {HookFormInput} from '../../../../components/ReactHookForm/HookFormInput'
import {PermissionModel} from '../../../../models/PermissionModel'
import {useAddPermission, useUpdatePermission} from './PermissionApi'
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export interface PermissionFormProps {
  mode: 'view' | 'create'
  data?: PermissionModel
  dataId: ''
}

export default function PermissionForm(props: PermissionFormProps) {
  const {mode, data, dataId, ...others} = props
  const {t} = useTranslation('translation')
  const [formState, setFormState] = useState<MyFormState>('view')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [valueArea, setValueArea] = useState<string>('')
  const initialFormData: any = {
    permissionId: '',
    permissionName: '',
    description: '',
    permissionGroup: '',
    roles: '',
  }

  const updateValidationSchema = Yup.object().shape({
    permissionName: Yup.string().required(t('Permission Name is required', {data: t('Name')})),
    description: Yup.string()
      .nullable()
      .required(t('Description is required', {data: t('Description')})),
  })

  const insertValidationSchema = Yup.object().shape({
    permissionName: Yup.string().required(t('Permission Name is required', {data: t('Name')})),
    description: Yup.string()
      .nullable()
      .required(t('Description is required', {data: t('Description')})),
  })

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<PermissionModel>({
    resolver: yupResolver(mode === 'create' ? insertValidationSchema : updateValidationSchema),
  })

  const addPermission = useAddPermission()
  const updatePermission = useUpdatePermission()

  useEffect(() => {
    if (props.mode === 'create') {
      setFormState('editing')
    } else {
      reset(props.data)
      //loadSite()
    }
  }, [props.mode, props.data])

  const canEdit = useMemo(() => {
    return true
  }, [])

  function onEditClicked() {
    // setErrorMessage('')
    setFormState('editing')
  }

  function onCancelClicked() {
    if (props.mode === 'create') {
      navigate('/Permission', {replace: true})
    } else {
      setFormState('view')
      console.log('onSubmit() data = ', navigate)
    }
  }

  function onSaveClicked() {
    console.log('onSaveClicked()..')

    handleSubmit(onSubmit)()
  }

  {
    /* Function Add and Update ========================= */
  }
  const onSubmit = (data: PermissionModel) => {
    console.log('onSubmit() data = ', data)
    setFormState('saving')
    if (props.mode === 'create') {
      addPermission.mutate(data, {
        onSuccess: (response: ServiceResult<PermissionModel>) => {
          if (response.success) {
            let result = response.data!
            console.log('Add Permission success')
            let message = t('Add Data Successful')
            toast.success(message)
            navigate(`/Permission/${result.permissionId}`)
          } else {
            console.log('Add Permission failed: ' + response.errorMessage)
            toast.error(response.errorMessage ?? 'Error')
            setFormState('editing')
          }
        },
        onError: (data) => {
          console.log('Add Permission error')
          let message = 'Add Permission failed. Please check your network connection.'
          toast.error(message)
          setFormState('editing')
          //setFormState('view')
        },
      })
    } else {
      updatePermission.mutate(data, {
        onSuccess: (response: ServiceResult<PermissionModel>) => {
          if (response.success) {
            console.log('Update Permission success')
            let message = t('Data Has Been Updated', {data: data.permissionName})
            toast.success(message)
            setFormState('view')
          } else {
            console.log('Update Permission failed: ' + response.errorMessage)
            toast.error(response.errorMessage ?? 'Error')
            setFormState('editing')
          }
        },
        onError: (data) => {
          console.log('Update Permission error')
          let message = 'Update Permission failed. Please check your network connection.'
          toast.error(message)
          setFormState('editing')
        },
      })
    }
  }

  const labelWidth = '120px'
  var isDisabled = formState !== 'editing'

  return (
    <div className='flex-column-fluid d-flex flex-column'>
      {/* toolbar */}
      <div className='h-60px d-flex flex-row align-items-center gap-2 flex-shrink-0 border-bottom'>
        {formState === 'view' && canEdit && (
          <>
            <Button
              togglable={false}
              className='btn-primary-jotun-yellow w-80px'
              onClick={onEditClicked}
            >
              {t('Edit')}
            </Button>
          </>
        )}
        {formState === 'editing' && (
          <>
            <Button
              type='button'
              togglable={false}
              className='btn-primary-jotun-yellow w-80px'
              onClick={onSaveClicked}
            >
              {t('Save')}
            </Button>
            <Button togglable={false} className='w-80px' onClick={onCancelClicked}>
              {t('Cancel')}
            </Button>
          </>
        )}
        {formState === 'saving' && (
          <>
            <Button type='button' togglable={false} className='btn-primary-jotun-yellow w-80px'>
              {t('Saving')}...
            </Button>
          </>
        )}
      </div>
      <div className='flex-column-fluid d-flex flex-column k-form k-form-horizontal'>
        <form id='myForm'>
          {formState === 'editing' && !!errorMessage && (
            <div className='alert alert-danger w-100' role='alert'>
              {errorMessage}
            </div>
          )}
          <div className='w-100 d-flex flow-row flex-wrap'>
            <div className='half'>
              <HookFormInput
                name='permissionName'
                control={control}
                label={t('Name')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
              <HookFormInput
                name='description'
                control={control}
                label={t('Description')}
                labelWidth={labelWidth}
                disabled={isDisabled}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
