/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import {Button} from '@progress/kendo-react-buttons'
import toast from 'react-hot-toast'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {MyFormState} from '../../../../classes/MyFormState'
import {ServiceResult} from '../../../../classes/ServiceResult'
import {HookFormInput} from '../../../../components/ReactHookForm/HookFormInput'
import {HookFormTextArea} from '../../../../components/ReactHookForm/HookFormTextArea'
import {
  ActionPlansModel,
  AttachmentModel,
  IncidentLossesModel,
  IncidentModel,
  InvestigationModel,
  PersonalModel,
  ProgressModel,
} from '../../../../models/IncidentModel'
import {
  useAddIncident,
  useGetIncidentData,
  useGetIncidentDataProgress,
  useSaveInvestigation,
  useSaveProgress,
  useUpdateIncident,
} from '../IncidentApi'
import {useNavigate, useParams} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import {HookFormDatePicker} from '../../../../components/ReactHookForm/HookFormDatePicker'
import {HookFormSwitch} from '../../../../components/ReactHookForm/HookFormSwitch'
import {HookFormUploadImage} from '../../../../components/ReactHookForm/HookFormUploadImage'
import {HookFormDropDownList} from '../../../../components/ReactHookForm/HookFormDropDownList'
import {HookFormNumericTextBox} from '../../../../components/ReactHookForm/HookFormNumericTextBox'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload'
import DeleteDataDialog from '../../../../components/Dialog/DeleteDataDialog'
import {Dialog} from '@progress/kendo-react-dialogs'
import axios from 'axios'
import {loadMessages, LocalizationProvider} from '@progress/kendo-react-intl'

import {useIntl} from 'react-intl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
  faImageLandscape,
  faTrash,
  faUpload,
} from '@fortawesome/pro-regular-svg-icons'
import {Grid, GridCellProps, GridColumn} from '@progress/kendo-react-grid'
import {auto} from '@popperjs/core'
// import PopupIncidentLosses from './popup/PopupIncidentLosses'
import SubmitApproveRejectDataDialog from '../../../../components/Dialog/SubmitApproveDataDialog'
import {HookFormComboBoxWithRemoteData} from '../../../../components/ReactHookForm/HookFormComboBoxRemoteData'
import PopupAttachment from '../pelaporan-awal/popup/PopupAtachment'
import PopupAttachmentActionPlan from './popup/PopupAttachmentActionPlan'
import RejectDataDialog from '../../../../components/Dialog/RejectDataDialog'
import {Input} from '@progress/kendo-react-inputs'
import ProgressFormBottom from './ProgressFormBottom'

export interface EstateFormProps {
  mode: 'view' | 'create' | 'delete'
  data?: IncidentModel
  dataId: string | number | undefined
}

export default function PelaporanFormparent(props: EstateFormProps) {
  const intl = useIntl()
  const {t} = useTranslation('translation')
  // const [formState, setFormState] = useState<MyFormState>('view')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  let {incidentId} = useParams()
  const {data, refetch} = useGetIncidentData(incidentId ?? '')
  const {data: dataProgress} = useGetIncidentDataProgress(incidentId ?? '')

  const [numForms, setNumForms] = useState(0)
  const [viewDialogVisible, setViewDialogVisible] = useState(false)
  const [viewImageUrl, setViewImageUrl] = useState('')
  const [dialogUpload, setDialogUpload] = useState(false)
  const [attId, setAttId] = useState('')

  const validationSchema = Yup.object().shape({})

  const [selected, setSelected] = useState(0)
  const handleSelect = (e: any) => {
    setSelected(e.selected)
  }

  const [files, setFiles] = useState<Array<UploadFileInfo>>([])
  const [events, setEvents] = useState<Array<any>>([])
  const [filePreviews, setFilePreviews] = useState<{[key: string]: string}>({})
  const [affectedFiles, setAffectedFiles] = useState<Array<UploadFileInfo>>([])

  const initialSubmitState = {
    visible: false,
    title: '',
    url: '',
  }
  const initialRejectState = {
    id: '',
    visible: false,
    title: '',
    url: '',
  }
  const [submitState, setSubmitState] = useState(initialSubmitState)
  const [rejectState, setRejectState] = useState(initialRejectState)

  const initialEditIncidentLosses = {
    visible: false,
    mode: '',
    title: '',
    dataId: '',
    incidentId: '',
  }
  const initialAddAttachment = {
    visible: false,
    title: '',
    dataId: '',
    incidentId: '',
  }

  const [editIncidentLosses, setEditIncidentLosses] = useState(initialEditIncidentLosses)
  const [addAttach, setAddAttach] = useState(initialAddAttachment)

  const initialDeleteState = {
    visible: false,
    title: '',
    url: '',
  }

  const fileStatuses = [
    'UploadFailed',
    'Initial',
    'Selected',
    'Uploading',
    'Uploaded',
    'RemoveFailed',
    'Removing',
  ]

  const defaultIncidentLosses: IncidentLossesModel = {
    id: '',
    incidentId: '',
    typeOfLoss: '',
    description: '',
    costOfLoss: 0,
  }
  const defaultActionPlans: ActionPlansModel = {
    id: '',
    incidentId: '',
    description: '',
    isCompleted: false,
    notes: '',
    progressPhotos: [
      {
        id: '',
        incidentId: '',
        attachmentType: '',
        itemId: '',
        description: '',
        imagePath: '',
        seqId: '',
        image: '',
        imageUrl: '',
      },
    ],
  }

  const defaultAttachmentData: AttachmentModel = {
    id: '',
    incidentId: ' ',
    attachmentType: ' ',
    description: ' ',
    imagePath: ' ',
    seqId: 0,
    image: ' ',
    imageUrl: ' ',
  }
  const [incidentLosses, setIncidentLosses] = useState<IncidentLossesModel[]>([
    defaultIncidentLosses,
  ])
  const [action, setAction] = useState<ActionPlansModel[]>([defaultActionPlans])
  const [actionId, setActionId] = useState('')

  const [attachmentData, setAttachmentData] = useState<AttachmentModel[]>([defaultAttachmentData])

  const [deleteState, setDeleteState] = useState(initialDeleteState)
  const [visibleReport, setVisibleReport] = useState(false)
  const [visibleInvestigation, setVisibleInvestigation] = useState(true)

  function createNewPersonalData(): PersonalModel {
    return {
      id: '',
      incidentId: '',
      name: '',
      gender: '',
      companyName: '',
      position: '',
      age: 0,
      address: '',
      estimatedLoss: 0,
    }
  }
  function createNewIncidentLossData(): IncidentLossesModel {
    return {
      id: '',
      incidentId: '',
      typeOfLoss: '',
      description: '',
      costOfLoss: 0,
    }
  }
  function createNewActionPlansData(): ActionPlansModel {
    return {
      id: '',
      incidentId: '',
      description: '',
      isCompleted: false,
      notes: '',
      progressPhotos: [
        {
          id: '',
          incidentId: '',
          attachmentType: '',
          itemId: '',
          description: '',
          imagePath: '',
          seqId: '',
          image: '',
          imageUrl: '',
        },
      ],
    }
  }

  function createNewAttachmentData(): AttachmentModel {
    return {
      id: '',
      incidentId: ' ',
      attachmentType: ' ',
      description: ' ',
      imagePath: ' ',
      seqId: 0,
      image: ' ',
      imageUrl: ' ',
    }
  }

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: {errors},
  } = useForm<ProgressModel>({
    resolver: yupResolver(validationSchema),
  })

  const saveInvestigation = useSaveProgress()
  // const updateIncident = useUpdateIncident()

  useEffect(() => {
    affectedFiles
      .filter((file: UploadFileInfo) => !file.validationErrors)
      .forEach((file: UploadFileInfo) => {
        const reader = new FileReader()

        reader.onloadend = (ev: any) => {
          setFilePreviews({
            ...filePreviews,
            [file.uid]: ev.target.result,
          })
        }
        if (file && file.getRawFile) {
          reader.readAsDataURL(file.getRawFile())
        }
      })

    if (props.mode === 'create') {
      // setFormState('editing')
    } else {
      reset(props.data)

      if (props.data?.investigation) {
        setAction(props.data?.investigation.actionPlans ?? [])
      }
      setAttachmentData(props.data?.incidentReportPhotos ?? [])
      if (props.data?.investigation) {
        setNumForms(props.data?.investigation.actionPlans.length ?? 1)
      }
    }
  }, [props.mode, props.data])

  const canEdit = useMemo(() => {
    return true
  }, [])

  function onHideReport() {
    setVisibleReport(!visibleReport)
  }
  function onHideInvestigation() {
    setVisibleInvestigation(!visibleInvestigation)
  }

  function onEditClicked() {
    // setErrorMessage('')
    // setFormState('editing')
  }

  function handleSubmitButtonClicked(data: any) {
    setSubmitState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/progress/Submit?incidentId=' + data.id,
    })
  }
  function handleApproveButtonClicked(data: any) {
    setSubmitState({
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/progress/Approve?incidentId=' + data.id,
    })
  }
  function handleRejectButtonClicked(data: any) {
    setRejectState({
      id: data.id,
      visible: true,
      title: data.incidentName,
      url: '/api/Incident/progress/Reject',
    })
  }

  function onCancelClicked() {
    if (props.mode === 'create') {
      navigate('/incident-report', {replace: true})
    } else {
      // setFormState('view')
    }
  }

  function onAddActionPlansClicked() {
    const newData = createNewActionPlansData() // Fungsi untuk membuat data form baru kosong
    setAction([...action, newData])
    setNumForms(numForms + 1)
  }

  function onDeletePersonClicked(index: number) {
    const updatedPersonalData = [...action]
    updatedPersonalData.splice(index, 1)
    setAction(updatedPersonalData)
    setNumForms(numForms - 1)

    // Update the form data
    const updatedFormData = {...getValues()}
    updatedFormData.actionPlans.splice(index, 1)
    setValue('actionPlans', updatedFormData.actionPlans)
  }

  function handleAddIncidentLossesClick(data: any) {
    console.log('data handleEditPersonVehicleClick: ', data)
    // if (props.dataX.incident_report_status === "APPROVED") {
    //   setErrorMessageImpactedVehicle("Tidak bisa diedit karena status sudah APPROVED ");
    //   return false;
    // } else {
    setEditIncidentLosses({
      visible: true,
      mode: 'add',
      title: 'Incident Losses',
      dataId: data,
      incidentId: data,
    })
    // }
  }

  // function onSaveClicked() {
  //   console.log('onSaveClicked()..')

  //   handleSubmit(onSubmit)()
  //   refetch()
  // }

  console.log('PREVIEW', filePreviews)

  // const onSubmit = (dataProgress: ProgressModel) => {
  //   console.log('onSubmit() data = ', data)
  //   dataProgress.incidentId = incidentId
  //   console.log('data progress')

  //   saveInvestigation.mutate(dataProgress, {
  //     onSuccess: (response: ServiceResult<ProgressModel>) => {
  //       let result = response.data!
  //       console.log('Add Estate success')
  //       let message = t('Add Data Successful')
  //       toast.success(message)
  //       // navigate(`/incident-report/investigation/${incidentId}`)
  //       refetch()
  //       // history.push(`/partner/${result.driverId}`)
  //       // } else {
  //       //   console.log('Add Estate failed: ' + response.errorMessage)
  //       //   toast.error(response.errorMessage ?? 'Error')
  //       //   setFormState('editing')
  //       // }
  //       // setFormState('view')
  //       // redirect to view route
  //     },
  //     onError: (data) => {
  //       console.log('Save data error')
  //       let message = 'Save data failed. Please check your network connection.'
  //       toast.error(message)
  //       // setFormState('editing')
  //       //setFormState('view')
  //     },
  //   })
  // }

  function handleAddAttachmentClicked(data: any) {
    setAddAttach({
      visible: true,
      title: 'Attachment',
      dataId: data,
      incidentId: data.incidentId,
    })
  }

  function handleAddAttachmentSuccess() {
    let message = t('Add Attachment Data Successful')
    toast.success(message)
    setAddAttach({...addAttach, visible: false})
    refetch()
  }

  function handleFormClose() {
    setDeleteState({...deleteState, visible: false})
    setEditIncidentLosses({...editIncidentLosses, visible: false})
    setSubmitState({...submitState, visible: false})
    setAddAttach({...addAttach, visible: false})
    setRejectState({...rejectState, visible: false})
  }
  function handleFormDeleteSuccess() {
    let message = t('Delete Data Successful')
    toast.success(message)
    setDeleteState({...deleteState, visible: false})
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    refetch()
  }

  function handleFormSubmitSuccess() {
    let message = t('Change Status Data Successful')
    toast.success(message)
    setSubmitState({...submitState, visible: false})
    setRejectState({...rejectState, visible: false})
    refetch()
  }

  function handleDeleteAtt(data: any) {
    const attId = data.id
    console.log('ID FOTO', attId)

    setDeleteState({
      visible: true,
      title: 'Delete Attachment',
      url: '/api/Incident/Report/Attachment/Delete?attachmentId=' + attId,
    })
  }

  function onViewClicked(props: any) {
    setViewDialogVisible(true)
    setViewImageUrl(props)
    console.log(props)
  }

  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState)
    setEvents([...events, `File selected: ${event.affectedFiles[0].name}`])
    setAffectedFiles(event.affectedFiles)

    event.affectedFiles.forEach((file: UploadFileInfo) => {
      const reader = new FileReader()

      reader.onloadend = (ev: any) => {
        setFilePreviews((prevState) => ({
          ...prevState,
          [file.name]: ev.target.result,
        }))
      }

      if (file && file.getRawFile) {
        reader.readAsDataURL(file.getRawFile())
      }
    })
  }

  const onRemove = (event: UploadOnRemoveEvent) => {
    let newFilePreviews = {...filePreviews}
    event.affectedFiles.forEach((file) => {
      delete newFilePreviews[file.uid]
    })

    setFiles(event.newState)
    setEvents([...events, `File removed: ${event.affectedFiles[0].name}`])
    setFilePreviews(newFilePreviews)
  }

  const onProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState)
    setEvents([...events, `On Progress: ${event.affectedFiles[0].progress} %`])
  }

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    const file = event.affectedFiles[0]
    setFiles(event.newState)
    setEvents([...events, `File '${file.name}' status changed to: ${fileStatuses[file.status]}`])
  }

  const onSaveRequest = (files: UploadFileInfo[]): Promise<{uid: string}> => {
    const currentFile = files[0] as UploadFileInfo
    const uid = currentFile.uid

    // Find the corresponding attachmentId from attachmentData

    const attachmentId = attId
    console.log('attachmentId', attachmentId)

    if (!attachmentId) {
      return Promise.reject({uid: uid})
    }

    const saveRequestPromise = new Promise<{uid: string}>(async (resolve, reject) => {
      if (currentFile.validationErrors && currentFile.validationErrors.length > 0) {
        reject({uid: uid})
      } else {
        const formData = new FormData()
        formData.append('file', currentFile.getRawFile!())

        try {
          const response = await axios.post(
            '/api/Incident/AcctionPlan/Attachment/Upload?attachmentId=' + attachmentId,
            formData
          )

          resolve({uid: uid})
          // onSuccess(currentFile.name, response.data)
          setDialogUpload(false)
          refetch()
        } catch (err) {
          reject({uid: uid})
        }
      }
    })

    return saveRequestPromise
  }

  const labelWidth = '150px'
  // var isDisabled = formState !== 'editing'

  function getStatusClass(status: any) {
    let statusClass = 'badge'
    if (status === 'NA') {
      statusClass += ' bg-dark'
    } else if (status === 'APPROVED') {
      statusClass += ' bg-success'
    } else if (status === 'WAITING APPROVAL') {
      statusClass += ' bg-warning'
    } else if (status === 'REJECTED') {
      statusClass += ' bg-danger'
    } else if (status === 'DRAFT') {
      statusClass += ' bg-primary'
    }
    return statusClass
  }

  const actionCell = (props: GridCellProps) => {
    var incidentId = props.dataItem.id != undefined ? props.dataItem.id : ''
    return (
      <td>
        <div className='d-flex flex-row flex-1 align-items-center justify-content-center gap-3'>
          <span className='cursor-pointer' title={t('View')}>
            <FontAwesomeIcon
              icon={faImageLandscape}
              size='lg'
              onClick={() => onViewClicked(props.dataItem.imageUrl)}
            />
          </span>
          <span
            className='cursor-pointer'
            onClick={() => {
              setDialogUpload(true)
              setAttId(props.dataItem.id)
              setFiles([])
            }}
            title={t('Delete')}
          >
            <FontAwesomeIcon icon={faUpload} size='lg' />
          </span>
          <span
            className='cursor-pointer'
            onClick={() => handleDeleteAtt(props.dataItem)}
            title={t('Delete')}
          >
            <FontAwesomeIcon icon={faTrash} size='lg' />
          </span>
        </div>
      </td>
    )
  }

  return (
    <>
      <div className='flex-column-fluid d-flex flex-column'>
        {/* toolbar */}
        <div className='h-60px d-flex flex-row align-items-center gap-2 flex-shrink-0 border-bottom'>
          <div className='flex-grow-0 d-flex align-items-center flex-wrap justify-content-between mt-2 mb-2 w-100'>
            <div className='d-flex align-items-center gap-2'>
              {/* <>
                <Button
                  type='button'
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={onSaveClicked}
                >
                  {intl.formatMessage({id: 'BTN.SAVE'})}
                </Button>
                <Button togglable={false} className='w-80px' onClick={onCancelClicked}>
                  {intl.formatMessage({id: 'BTN.CANCEL'})}
                </Button>
              </> */}

              {/* {formState === 'view' && canEdit && (
                <>
                  <Button
                    togglable={false}
                    className='btn-primary-jotun-yellow w-80px'
                    onClick={onEditClicked}
                    disabled={props.data?.investigationStatus !== 'DRAFT'}
                  >
                    {t('Edit')}
                  </Button>
                </>
              )} */}
              <span
                className={`text-white  badge ${getStatusClass(props.data?.progressStatus)}`}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h5 className='text-white'>{t(`${props.data?.progressStatus}`)}</h5>
              </span>
            </div>
            <div className='d-flex align-items-center mb-3 gap-2'>
              <>
                {/* <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  // onClick={onSaveClicked}
                  disabled={
                    props.data?.progressStatus !== 'DRAFT' && props.data?.progressStatus !== 'NA'
                  }
                >
                  {t('Save')}
                </Button> */}
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleSubmitButtonClicked(props.data)}
                  disabled={props.data?.progressStatus !== 'DRAFT'}
                >
                  {t('Submit')}
                </Button>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleApproveButtonClicked(props.data)}
                  disabled={props.data?.progressStatus !== 'WAITING APPROVAL'}
                >
                  {t('Approve')}
                </Button>
                <Button
                  togglable={false}
                  className='btn-primary-jotun-yellow w-80px'
                  onClick={() => handleRejectButtonClicked(props.data)}
                  disabled={props.data?.progressStatus !== 'WAITING APPROVAL'}
                >
                  {t('Reject')}
                </Button>
              </>
            </div>
          </div>

          {/* {formState === 'saving' && (
            <>
              <Button type='button' togglable={false} className='btn-primary-jotun-yellow w-80px'>
                {t('Saving')}...
              </Button>
            </>
          )} */}
        </div>
        <div className='flex-column-fluid d-flex flex-column k-form k-form-horizontal'>
          {/* {formState === 'editing' && !!errorMessage && (
            <div className='alert alert-danger w-100' role='alert'>
              {errorMessage}
            </div>
          )} */}
          <h4>
            Laporan Awal{' '}
            <FontAwesomeIcon
              icon={visibleReport ? faChevronUp : faChevronDown}
              className='ms-2 mt-2 cursor-pointer'
              onClick={onHideReport}
            />
          </h4>
          {visibleReport && (
            <>
              <div className='card shadow m-3 '>
                <div className='card-body '>
                  <div className='row'>
                    <div className='col-8'>
                      <legend
                        className='page-title'
                        style={{
                          backgroundColor: '#1986c8',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        {'Incident Information'}
                      </legend>
                      <div className='row'>
                        <div className='w-100 d-flex flow-row flex-wrap'>
                          <div className='col-6'>
                            <HookFormDatePicker
                              name='incidentDate'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTDATE'})}
                              labelWidth={labelWidth}
                              format={'dd/MMM/yyyy'}
                              width={100}
                              disabled
                            />
                            <HookFormInput
                              name='incidentNumber'
                              control={control}
                              label={t('Incident Number')}
                              labelWidth={labelWidth}
                              disabled
                            />
                            <HookFormInput
                              name='incidentName'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTNAME'})}
                              labelWidth={labelWidth}
                              disabled
                            />

                            <HookFormInput
                              name='incidentLocation'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.INCIDENTLOC'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                            <HookFormInput
                              name='witnessName'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.WITNESS'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                          </div>

                          <div className='col-6'>
                            <HookFormInput
                              name='workActivity'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.ACT'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                            <HookFormInput
                              name='workCondition'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.COND'})}
                              labelWidth={labelWidth}
                              disabled
                            />

                            <HookFormTextArea
                              name='description'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.DESCRIPTION'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                            <HookFormSwitch
                              name='statementTaken'
                              control={control}
                              label={intl.formatMessage({id: 'TEXT.STATEMENT'})}
                              labelWidth={labelWidth}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                      <legend
                        className='page-title'
                        style={{
                          backgroundColor: '#1986c8',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        {'Attachment'}
                      </legend>
                      <Grid data={data?.incidentReportPhotos}>
                        <GridColumn field='description' title='description' width={auto} />
                        <GridColumn field='id' title='Action' width={120} cell={actionCell} />
                      </Grid>
                    </div>
                  </div>
                  <div className='row' style={{marginTop: '50px'}}>
                    <div className='col-12'>
                      <legend
                        className='page-title'
                        style={{
                          backgroundColor: '#1986c8',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        {'Impacted Person'}
                      </legend>

                      <Grid data={data?.impactedPersons}>
                        <GridColumn field='name' title='Name' />
                        <GridColumn field='gender' title='Gender' />
                        <GridColumn field='age' title='Age' />
                        <GridColumn field='companyName' title='Company' />
                        <GridColumn field='position' title='Position' />
                        <GridColumn field='address' title='Address' />
                        <GridColumn field='estimatedLoss' title='Estimated Loss' />
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <hr />
          <h4>
            Investigation{' '}
            <FontAwesomeIcon
              icon={visibleReport ? faChevronUp : faChevronDown}
              className='ms-2 mt-2 cursor-pointer'
              onClick={onHideInvestigation}
            />
          </h4>
          <form id='myForm'>
            {visibleInvestigation && (
              <>
                <div className='card shadow m-3'>
                  <div className='card-body '>
                    <div className='row'>
                      <div className='col-5'>
                        <legend
                          className='page-title'
                          style={{
                            backgroundColor: '#1986c8',
                            color: 'white',
                            textAlign: 'center',
                          }}
                        >
                          {'Investigation'}
                        </legend>

                        <HookFormComboBoxWithRemoteData
                          dataUrl='/api/Incident/Priority/List'
                          name='priority'
                          control={control}
                          label={'Priority'}
                          labelWidth={labelWidth}
                          disabled
                        />

                        <HookFormTextArea
                          name='investigation.causeOfIncident'
                          control={control}
                          label={t('Cause of Incident')}
                          labelWidth={labelWidth}
                          disabled
                        />
                      </div>
                      <div className='col-7'>
                        <legend
                          className='page-title'
                          style={{
                            backgroundColor: '#1986c8',
                            color: 'white',
                            textAlign: 'center',
                          }}
                        >
                          {'Incident Losses'}
                        </legend>

                        <Grid data={data?.investigation?.incidentLosess}>
                          <GridColumn field='typeOfLoss' title='Type' />
                          <GridColumn field='description' title='description' />
                          <GridColumn field='costOfLoss' title='costOfLoss' />
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='card shadow m-3'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-12'>
                          <legend
                            className='page-title'
                            style={{
                              backgroundColor: '#1986c8',
                              color: 'white',
                              textAlign: 'center',
                            }}
                          >
                            {'Action Plans'}
                          </legend>

                          <>
                            {action && (
                              <>
                                {action.map((loss, index) => {
                                  return (
                                    <div key={index} className='pb-2'>
                                      <div className='card shadow m-3'>
                                        <div className='card-body'>
                                          <div className='row'>
                                            <div className='w-100 d-flex flow-row flex-wrap gap-3'>
                                              <div className='col-8'>
                                                <HookFormInput
                                                  name={`investigation.actionPlans[${index}].description`}
                                                  control={control}
                                                  label='Description'
                                                  labelWidth={labelWidth}
                                                  disabled
                                                />

                                                <HookFormSwitch
                                                  name={`progress.actionPlans[${index}].isCompleted`}
                                                  label='Complete?'
                                                  control={control}
                                                  labelWidth={labelWidth}
                                                />

                                                <HookFormInput
                                                  name={`progress.actionPlans[${index}].notes`}
                                                  control={control}
                                                  label={intl.formatMessage({id: 'Notes'})}
                                                  labelWidth={labelWidth}
                                                />
                                                <Input
                                                  name={`progress.actionPlans[${index}].id`}
                                                  value={loss.id}
                                                  hidden
                                                />
                                              </div>
                                              <div className='col-3'>
                                                <Button
                                                  type='button'
                                                  className='mb-2'
                                                  onClick={() =>
                                                    handleAddAttachmentClicked(loss.id)
                                                  }
                                                >
                                                  Add Attachment
                                                </Button>
                                                <Grid
                                                  data={
                                                    data?.progress?.actionPlans[index]
                                                      ?.progressPhotos
                                                  }
                                                >
                                                  <GridColumn
                                                    field='description'
                                                    title='description'
                                                  />
                                                  <GridColumn
                                                    field=''
                                                    title='action'
                                                    cell={actionCell}
                                                  />
                                                </Grid>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='w-100 d-flex flow-row flex-wrap'></div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </>
            )}
          </form>{' '}
          <ProgressFormBottom mode={'view'} dataId={undefined} />
        </div>
        {deleteState.visible && (
          <DeleteDataDialog
            title={deleteState.title}
            url={deleteState.url}
            onClose={handleFormClose}
            onSuccess={handleFormDeleteSuccess}
          />
        )}
        {addAttach.visible && (
          <PopupAttachmentActionPlan
            title={addAttach.title}
            onClose={handleFormClose}
            onSuccess={handleAddAttachmentSuccess}
            dataId={addAttach.dataId}
            incidentId={addAttach.incidentId}
          />
        )}
        {viewDialogVisible && (
          <Dialog title='View Image' onClose={() => setViewDialogVisible(false)} height={500}>
            <img src={viewImageUrl} alt='' height={450} />
          </Dialog>
        )}
        {submitState.visible && (
          <SubmitApproveRejectDataDialog
            title={submitState.title}
            url={submitState.url}
            onClose={handleFormClose}
            onSuccess={handleFormSubmitSuccess}
          />
        )}

        {rejectState.visible && (
          <RejectDataDialog
            id={rejectState.id}
            title={rejectState.title}
            url={rejectState.url}
            onClose={handleFormClose}
            onSuccess={handleFormSubmitSuccess}
          />
        )}

        {dialogUpload && (
          <Dialog onClose={() => setDialogUpload(false)} title={'Upload'}>
            <Upload
              autoUpload={false}
              restrictions={{
                allowedExtensions: ['.jpg', '.jpeg', '.png'],
                maxFileSize: 1000000,
              }}
              multiple={true}
              batch={false}
              files={files}
              onAdd={onAdd}
              onRemove={onRemove}
              withCredentials={false}
              saveUrl={onSaveRequest}
              onStatusChange={onStatusChange}
              onProgress={onProgress}
            />
          </Dialog>
        )}

        {/* {editIncidentLosses.visible && (
          <PopupIncidentLosses
            title={editIncidentLosses.title}
            mode={editIncidentLosses.mode}
            dataId={editIncidentLosses.dataId}
            incidentId={editIncidentLosses.incidentId}
            onClose={handleFormClose}
            onSuccess={handleFormDeleteSuccess}
          />
        )} */}
      </div>
    </>
  )
}
